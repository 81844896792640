import React, { useState, useEffect, useRef, createRef } from 'react';
import { Modal } from 'reactstrap';
import DatePicker from "react-datepicker";

import { prdtKind, prdtTerm } from '../../../lib/ConstCommand';
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";

import CustomDatePicker from '../../etc/button/CustomDatePicker';
import ko from 'date-fns/locale/ko';

const isMobile = (navigator.userAgent.toLowerCase().indexOf('android') > -1 || navigator.userAgent.toLowerCase().indexOf('iphone') > -1 || (navigator.userAgent.toLowerCase().indexOf('macintosh') > -1 && navigator.maxTouchPoints > 2));

const WebUsePass = ({ isModalOpened, toggleModalOpened, handleUsePassDate, usePchsInfo }) => {
    const { t } = useTranslation();

    /**
     * selectedInfo
     * - targetCode
     * - subDomainInfo    : { subDomainSeq, subDomainName }
     * - userInfo       : { userSeq, userID }
     * 
     * usePchsInfo
     * - pchsSeq
     * - pchsDT
     * - subDomainSeq
     * - userSeq
     * - prdtInfo       : { seq, name, kindCode, scaleCode, termCode, targetCode }
     */

    const [lPassInfo, setPassInfo] = useState(null);
    const [lTermValue, setTermValue] = useState(1);
    const [fromDT, setFromDT] = useState(new Date());
    const [toDT, setToDT] = useState(new Date());
    const fromDtRef = new createRef();
    const toDtRef = new createRef();

    useEffect(() => {
        if (usePchsInfo !== undefined && usePchsInfo !== null) {
            if (usePchsInfo.prdtInfo !== undefined && usePchsInfo.prdtInfo !== null) {
                setPassInfo(usePchsInfo.prdtInfo);
            }
        }
    }, [usePchsInfo]);

    useEffect(() => {
        if (lPassInfo !== undefined && lPassInfo !== null) {
            switch (lPassInfo.termCode) {
                case prdtTerm.D30.code:
                    setTermValue(prdtTerm.D30.value);
                    break;

                case prdtTerm.D60.code:
                    setTermValue(prdtTerm.D60.value);
                    break;

                case prdtTerm.D90.code:
                    setTermValue(prdtTerm.D90.value);
                    break;

                case prdtTerm.D180.code:
                    setTermValue(prdtTerm.D180.value);
                    break;

                case prdtTerm.D365.code:
                    setTermValue(prdtTerm.D365.value);
                    break;

                case prdtTerm.D730.code:
                    setTermValue(prdtTerm.D730.value);
                    break;

                default:
                    setTermValue(prdtTerm.D30.value);
                    break;
            }
        } else {
            setPassInfo(1);
        }
    }, [lPassInfo]);

    useEffect(() => {
        if (fromDT !== undefined && fromDT !== null) {
            var date = new Date();
            date.setDate(fromDT.getDate() + lTermValue);
            setToDT(date);
        } else {
            console.log('이런 경우가 있나...?');
        }
    }, [fromDT, lTermValue]);

    const onClickUsePass = (e) => {
        handleUsePassDate({ pchsSeq:usePchsInfo.pchsSeq, startUseDT:fromDT, endUseDT:toDT });
    }

    const onCloseModal = () => {
        setPassInfo(null);
    }
    
    return (
        <Modal isOpen={isModalOpened} centered backdrop={true} toggle={() => toggleModalOpened(false)} onClosed={onCloseModal}>
            <div className="card p-2">
                <div className="px-2">
                    <div className="d-flex flex-column w-100">
                        <div className="d-flex flex-column p-2 my-2 w-100">
                            <span className="text-center my-1 px-1" style={{fontSize:'1rem'}}>{t("이용권_안내_문구_6", { ns: "pass" })}</span>
                            {
                                lPassInfo !== undefined && lPassInfo !== null ?
                                    <div className="d-flex align-items-center justify-content-center px-1" style={{fontSize:'1.2rem', fontWeight:'bold'}}>
                                        <span className="mr-1">{'[' + (lPassInfo.kindCode === prdtKind.Purchase ? `${t("구매", { ns: "pass"})}]` : `${t("프로모션", { ns: "promotion" })}]`)}</span>
                                        <span>{lPassInfo.name}</span>
                                    </div> : 
                                    <div></div>
                            }
                        </div>
                        <div className={"d-flex justify-content-around px-1 py-2 my-2 w-100" + (isMobile ? " flex-column" : "")}>
                            <div className="d-flex align-items-center justify-content-around">
                                <span className="mr-1" style={{width:'fit-content', minWidth:'fit-content', maxWidth:'fit-content'}}>{t("시작일", { ns: "pass" })}</span>
                                <DatePicker
                                    locale={ko}
                                    ref={fromDtRef}
                                    selected={fromDT}
                                    minDate={new Date()}
                                    onChange={date => setFromDT(date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement="auto"
                                    customInput={<CustomDatePicker ref={fromDtRef} dateInfo={fromDT} />}
                                />
                                <span className="ml-1" style={{width:'fit-content', minWidth:'fit-content', maxWidth:'fit-content'}}>{t("부터", { ns: "pass" })}</span>
                            </div>
                            <div className={"d-flex align-items-center justify-content-around" + (isMobile ? " my-1" : "")}>
                                <span className="mr-1" style={{width:'fit-content', minWidth:'fit-content', maxWidth:'fit-content'}}>{t("종료일", { ns: "pass" })}</span>
                                <DatePicker
                                    locale={ko}
                                    ref={toDtRef}
                                    selected={toDT}
                                    disabled
                                    minDate={fromDT}
                                    onChange={date => setToDT(date)}
                                    popperModifiers={{ preventOverflow: { enabled: true } }}
                                    popperPlacement='auto'
                                    customInput={<CustomDatePicker ref={toDtRef} dateInfo={toDT} />}
                                />
                                <span className="ml-1" style={{width:'fit-content', minWidth:'fit-content', maxWidth:'fit-content'}}>{t("까지", { ns: "pass" })}</span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center p-2 my-2 w-100">
                            <button className="btn btn-sm btn-primary" type="button" onClick={onClickUsePass}>
                                {t("사용", { ns: "pass" })}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
export default WebUsePass;