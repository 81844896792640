import html2canvas from "html2canvas";
import SoundMeter from "./soundmeter";

import ConstCode from "./ConstCode";
import ConstData from "./ConstData";
import { enTokenCMD, enFrame, enImage, enP2PMode, stTopKind, stTimeKind } from "./classTalk/CodeEnum";
import { ctTimerLv } from "./ConstCommand";

import {
    sendP2PSoundOff,
    sendMicVolumeLevel,
    sendP2PSoundReadyLocal,
    sendHandsUpInitDone,
    sendUpdateHandsUpImage,
    xmitCmd_AndroidMessage,
    getIsAlivePenCamService,
    xmitCmd_AndroidSmallGroupInfo,
    sendStartPentalkOnScreenForAndroid,
    xmitCmd_AndroidJsonMessage,
    sendP2PSoundOn
} from "./RabbitmqClient";

import {
    produceAudio,
    produceVideo,
    sendUnpublish,
    enableAudioTrack,
    disableAudioTrack,
    setSelectedMode,
    setUnselectedMode,
    handleP2PConsume,
    initHandsUpConsumeInfo,
    subscribeHandsUpP2P,
    subscribeHandsUpAudio,
    subscribeHandsUpAudioArray,
    unsubscribeHandsUpAudio,
    unsubscribeHandsUpConsumeAll,
    controlSpeakerVolume,
    setVideoResolution
} from "./MediaSoupClient";

import {
    changeHandsUpStatusInfo, mqHandsUpOff, smallGroupStop
} from "../modules/live";

/** max video stream size ([HD+]16:9) */
/** min video stream size ([WqVGA]16:9.6) just use web-client(for mobile) by hjkim 20201126 */
/** min video stream size ([FWVGA]16:9) just use web-client by hjkim 20201126 */
/** ideal video stream size ([HD]16:9) just use web-client by hjkim 20201126 */

const cameraBand                = {
    /**
     * Display(Screen) Resolution - VGA(Video Graphics Array)
     * 
     * [HqVGA]3:2   - 240 x 160
     * [qVGA]4:3    - 320 x 240
     * [WqVGA]5:3   - 400 x 240
     * [HVGA]3:2    - 480 x 320
     * [VGA]4:3     - 640 x 480
     * [WVGA]5:3    - 800 x 480
     * [FWVGA]16:9  - 854 x 480
     * [SVGA]4:3    - 800 x 600
     * [DVGA]3:2    - 960 x 640
     * [WSVGA]16:9  - 1024 x 576
     * [WSVGA]17:10 - 1024 x 600
     */

    /** 
     * Display(Screen) Resolution - XGA(eXtended Graphics Array)
     * 
     * [XGA]4:3     - 1024 x 768
     * [WXGA]5:3    - 1280 x 768
     * [WXGA]16:10  - 1280 x 800
     * [FWXGA]16:9  - 1366 x 768
     * [XGA+]4:3    - 1152 x 864
     * [WXGA+]16:10 - 1440 x 900
     * [WSXGA]16:10 - 1680 x 1050
     * [SXGA]5:4    - 1280 x 1024
     * [SXGA+]4:3   - 1400 x 1050
     * [UXGA]4:3    - 1600 x 1200
     * [WUXGA]16:10 - 1920 x 1200
    */

    /**
     * Display(Screen) Resolution - QXGA(Quad XGA(eXtended Graphics Array))
     * 
     * [QXGA]4:3        - 2048 x 1536
     * [WQXGA]16:10     - 2560 x 1600
     * [WQXGA]16:10     - 2880 x 1800
     * [WQXGA]16:10     - 3072 x 1920
     * [QSXGA]5:4       - 2560 x 2048
     * [WQSXGA]25:16    - 3200 x 2048
     * [QUXGA]4:3       - 3200 x 2400
     * [WQUXGA]16:10    - 3840 x 2400
     */

    // min video stream size ([qqHD]16:9 - Full HD의 1/16 사이즈) by hjkim 20201228
    minWidth                    : 480,
    minHeight                   : 270,

    // ideal video stream size ([nHD]16:9 - Full HD의 1/9 사이즈) by hjkim 20201228
    idealWidth                  : 640,
    idealHeight                 : 360,

    // max video stream size ([nHD]16:9 - Full HD의 1/9 사이즈) by hjkim 20201228
    maxWidth                    : 640,
    maxHeight                   : 360,
    
    minFrameRate                : 16,   // pencam onair의 minFrameRate 참고 by hjkim 20201126
    idealFrameRate              : 16,
    maxFrameRate                : 24
};

const screenBand                = {
    /**
     * Display(Screen) Resolution - HD(High-Definition.)
     * 
     * [qqqHD]16:9  - 240 x 135             // Full HD의 1/64 사이즈
     * [qnHD]16:9   - 320 x 180             // Full HD의 1/36 사이즈
     * [qqHD]16:9   - 480 x 270             // Full HD의 1/16 사이즈
     * [nHD]16:9    - 640 x 360             // Full HD의 1/9 사이즈
     * [qHD]16:9    - 960 x 540             // Full HD의 1/4 사이즈
     * [HD]16:9     - 1280 x 720            // Full HD의 4/9 사이즈
     * [HD+]16:9    - 1600 x 900            // HD Plus
     * [FHD]16:9    - 1920 x 1080
     */

    // min video stream size ([WqVGA]16:9) by hjkim 20201228
    minWidth                    : 1280,
    minHeight                   : 720,

    // ideal video stream size ([WqVGA]16:9) by hjkim 20201228
    idealWidth                  : 1280,
    idealHeight                 : 720,
    // idealWidth                  : 1920,
    // idealHeight                 : 1080,
    // idealWidth                  : 3840,
    // idealHeight                 : 2160,

    // max video stream size ([WqVGA]16:9) by hjkim 20201228
    maxWidth                    : 1280,
    maxHeight                   : 720,
    // maxWidth                    : 1920,
    // maxHeight                   : 1080,
    // maxWidth                    : 3840,
    // maxHeight                   : 2160,

    minFrameRate                : 16,   // pencam onair의 minFrameRate 참고 by hjkim 20201126
    idealFrameRate              : 16,
    // idealFrameRate              : 24,
    maxFrameRate                : 16,
    // maxFrameRate                : 24,
};

const BandConstraints = {
    Camera: {
        width       : {
            min     : enImage.nHD.width,            // <nHD ... 640 x 360>
            ideal   : enImage.HD.width,             // <HD ... 1280 x 720>
            max     : enImage.HD.width              // <HD ... 1280 x 720>
        },
        height      : {
            min     : enImage.nHD.height,           // <nHD ... 640 x 360>
            ideal   : enImage.HD.height,            // <HD ... 1280 x 720>
            max     : enImage.HD.height             // <HD ... 1280 x 720>
        },
        frameRate   : {
            // min     : enFrame.SIXTEEN_FRAMES,       // 16 Frame
            // ideal   : enFrame.SIXTEEN_FRAMES,       // 16 Frame
            // max     : enFrame.SIXTEEN_FRAMES        // 16 Frame
            min     : enFrame.TWENTY_FOUR_FRAMES,   // 24 Frame
            ideal   : enFrame.TWENTY_FOUR_FRAMES,   // 24 Frame
            max     : enFrame.TWENTY_FOUR_FRAMES    // 24 Frame
        }
    },
    CameraForMyCam: {
        width       : {
            min     : enImage.qqHD.width,           // <qqHD ... 480 x 270>
            ideal   : enImage.nHD.width,            // <nHD ... 640 x 360>
            max     : enImage.nHD.width             // <nHD ... 640 x 360>
        },
        height      : {
            min     : enImage.qqHD.height,          // <qqHD ... 480 x 270>
            ideal   : enImage.nHD.height,           // <nHD ... 640 x 360>
            max     : enImage.nHD.height            // <nHD ... 640 x 360>
        },
        frameRate   : {
            min     : enFrame.SIXTEEN_FRAMES,       // 16 Frame
            ideal   : enFrame.SIXTEEN_FRAMES,       // 16 Frame
            max     : enFrame.SIXTEEN_FRAMES        // 16 Frame
            // min     : enFrame.TWENTY_FOUR_FRAMES,   // 24 Frame
            // ideal   : enFrame.TWENTY_FOUR_FRAMES,   // 24 Frame
            // max     : enFrame.TWENTY_FOUR_FRAMES    // 24 Frame
        }
    },
    Screen: {
        width       : {
            ideal   : enImage.FHD.width,            // <FHD ... 1920 x 1080>
            max     : enImage.FHD.width             // <FHD ... 1920 x 1080>
        },
        height      : {
            ideal   : enImage.FHD.height,           // <FHD ... 1920 x 1080>
            max     : enImage.FHD.height            // <FHD ... 1920 x 1080>
        },
        frameRate   : {
            // ideal   : enFrame.EIGHTEEN_FRAMES,      // 18 Frame
            // max     : enFrame.EIGHTEEN_FRAMES       // 18 Frame
            ideal   : enFrame.TWENTY_FOUR_FRAMES,   // 24 Frame
            max     : enFrame.TWENTY_FOUR_FRAMES    // 24 Frame
        }
    }
};

let imageSendWidth              = 128;  // 1280
let imageSendHeight             = 72;   // 720
let imageQuality                = 0.7;
let imageType                   = "image/jpeg";
// let configFPS                   = 1;

let isReconnMode                = false;

let handsUpClientInfo           = {
    liveSeq                     : -1,
    teacherSeq                  : -1,
    userSeq                     : -1,
    userKind                    : null, // 로직 추가 필요(init)

    videoEl                     : null,
    imageEl                     : null,
    myCamEl                     : null,
    // audioEl                     : null,

    sendVideoStream             : null,
    sendAudioStream             : null,

    audioContext                : null,
    audioSoundMeter             : null,

    canvasEl                    : null,
    contextEl                   : null,

    isAvailableAudio            : false,
    isMicOn                     : false,
    isMicDisable                : false,
    isSpeakingNow               : false,
    isVideoOn                   : false,
    isP2PMode                   : false,
    isSmallGroupMode            : false,
    isSelectedAudio             : false,
    isMuteMode                  : true,
    isImageProcRunning          : false,
    isMobile                    : false,    // smart phone, tablet 등 touch 가능한 device
    isAndroidDevice             : false,
    isAvailableApp              : true,
    isMixedClassMember          : false,

    localStreamingMode          : enP2PMode.Thumbnail,
    topKind                     : stTopKind.All_Client,
    configFPS                   : 1,        // default는 None_SmartTV
    configFPSKind               : stTimeKind.EIGHT_FRAMES,

    audioVolume                 : 10,
    micVolume                   : 0,

    activeYn                    : "N",
    viewKind                    : null,
    soundLevel                  : -1,   // pentalk handsup speak 이미지 갱신을 위해 초기값은 -1 by hjkim 20210104
    imageSendFileName           : null,

    imageSendTime               : ctTimerLv.LV_DEFAULT,
    imageSendProc               : null,
    micVolumeSendProc           : null,
    timerProc                   : null,

    handsUpFunc                 : null,

    videoInputInfo              : {
        isMyCamRunning          : false,
        myCamIndex              : -1,
        myCamDeviceId           : null,
        selectedIndex           : -1,
        selectedDeviceId        : null,
        myCamMediaInfo          : { deviceId: null, groupId: null, label: null },
        selectedMediaInfo       : { deviceId: null, groupId: null, label: null },
        list_videoInput         : []
    },

    audioInputInfo              : {
        defaultAudioInput       : { deviceId: null, groupId: null, label: null },
        currentAudioInput       : { deviceId: null, groupId: null, label: null }
    },

    smallGroupInfo              : [],

    list_audioConsumeUserSeq    : [],

    /** for smartTV */
    isSmartTVMode               : false,
    list_smartTVKey             : [],
    smartTVP2PMode              : enP2PMode.Thumbnail,

    /** Audio Mixer */
    audioCtx                    : null,
    micStreamSource             : null,
    systemStreamSource          : null,
    audioMerger                 : null,
    destination                 : null,

    isSysConnectFlag            : false,
    isMicConnectFlag            : false
};

/**
 * react 제어를 위한 function
 * @param {Function} imageProcess 
 */
export const initHandsUpFunc = (imageProcess) => {
    handsUpClientInfo.handsUpFunc = imageProcess;
}

/**
 * hands up 기본 정보 세팅
 * @param {Number} lSeq 
 * @param {Number} tSeq 
 * @param {Number} uSeq 
 */
const initHandsUpInfo = async (lSeq, tSeq, uSeq, activeYn) => {
    console.log(`initHandsUpInfo - liveSeq[${lSeq}], teacherSeq[${tSeq}], userSeq[${uSeq}], activeYn[${activeYn}]`);
    handsUpClientInfo.liveSeq = lSeq;
    handsUpClientInfo.teacherSeq = tSeq;
    handsUpClientInfo.userSeq = uSeq;
    handsUpClientInfo.activeYn = activeYn;
    // handsUpClientInfo.viewKind = vKind;

    handsUpClientInfo.imageSendFileName = `img_${uSeq}.jpg`;

    /** init handsup default values */
    handsUpClientInfo.isMicDisable = false;
    handsUpClientInfo.isMuteMode = true;
    handsUpClientInfo.isMicOn = false;
    handsUpClientInfo.isP2PMode = false;
    handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail;     // 0 ... Thumbnail ( 640 x 360 ) mode
    handsUpClientInfo.topKind = stTopKind.All_Client;               // "all-client"
    handsUpClientInfo.isSmartTVMode = false;
    handsUpClientInfo.isSelectedAudio = false;

    handsUpClientInfo.audioInputInfo = {
        defaultAudioInput: { deviceId: null, groupId: null, label: null },
        currentAudioInput: { deviceId: null, groupId: null, label: null }
    };

    /** Audio Mixer - Audio Context 초기화 */
    handsUpClientInfo.audioCtx = new AudioContext();
    handsUpClientInfo.audioMerger = handsUpClientInfo.audioCtx.createChannelMerger(2);
    handsUpClientInfo.destination = handsUpClientInfo.audioCtx.createMediaStreamDestination();
    handsUpClientInfo.audioMerger.connect(handsUpClientInfo.destination);

    if (navigator) {
        if (navigator.userAgent) {
            let userAgent = navigator.userAgent.toLowerCase();

            if (userAgent.indexOf("mobile") > -1 || userAgent.indexOf("iphone") > -1 || userAgent.indexOf("android") > -1
                || ((userAgent.indexOf("windows") > -1 || userAgent.indexOf("cros") > -1 || userAgent.indexOf("macintosh") > -1) && navigator.maxTouchPoints > 2)) {
                handsUpClientInfo.isMobile = true;
            }

            let isAndroid = userAgent.indexOf("android") > -1;
            if (isAndroid) {
                handsUpClientInfo.isAndroidDevice = true;
                handsUpClientInfo.isAvailableApp = true;
            }
        }

        if (navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = e => { updateMediaDevices(); /* testUpdate(); */ };
        }
    }

    await initHandsUpConsumeInfo(); // 혹시 모를... data clear...
}

/**
 * hands up audio stream 준비
 */
const initHandsUpMic = (isHandsUpInit) => {
    console.log(`initHandsUpMic - isHandsUpInit[${isHandsUpInit}], isMicOn[${handsUpClientInfo.isMicOn}], activeYn[${handsUpClientInfo.activeYn}]`);
    if (handsUpClientInfo.activeYn === "Y") {
        if (isHandsUpInit) {
            if (ConstData.IS_LOCAL_VERSION) { // local version에서는 마이크 off가 default... by hjkim 20220610
                const deviceInfo = getAudioInputDeviceInfo();
                if (deviceInfo === undefined || deviceInfo === null) {
                    console.log("can not use default audioinput. (1-1-local)");
                    console.log("local initHandsUpMic - deviceInfo => ", deviceInfo);
                    let store = window.hiclasstv.store;
                    store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
                    alert("(1) 사용 가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우저입니다. (Code.HC202.0)");
                    return;
                } else { // for local sound ready
                    sendP2PSoundReadyLocal(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);

                    let store = window.hiclasstv.store;
                    store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "off" }));
                }
            } else {
                if (!handsUpClientInfo.isMicOn) {
                    handsUpClientInfo.isMicOn = true;
                    setUpMicAudioStream(isHandsUpInit);
                }
            }
        } else {
            if (handsUpClientInfo.isMicOn) { // ondevicechange event 발생하면 mic 사용 중이었던 경우에만 처리하기 위해... by hjkim 20220315
                clearMicVolumeSendProc();

                if (handsUpClientInfo.sendAudioStream !== undefined && handsUpClientInfo.sendAudioStream !== null) {
                    handsUpClientInfo.sendAudioStream.getTracks().forEach(track => {
                        track.stop();
                    });

                    handsUpClientInfo.sendAudioStream = null;
                }

                setUpMicAudioStream(isHandsUpInit);
            } else {
                sendP2PSoundReadyLocal(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq); // handsup 창 아이콘 갱신해주기 위해.. local이 아니어도... by hjkim 20231114

                let store = window.hiclasstv.store;
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "off" }));
            }
        }
    }
}

/**
 * sound meter 와 audio stream 연결 및 volume catch process 시작
 */
const initHandsUpMicVolumeSendProc = () => {
    // console.log("initHandsUpMicVolumeSendProc() --- 000");
    if (handsUpClientInfo.sendAudioStream instanceof MediaStream) {
        var AudioContext = window.AudioContext || window.webkitAudioContext;

        handsUpClientInfo.audioContext = new AudioContext();
        handsUpClientInfo.audioSoundMeter = new SoundMeter(handsUpClientInfo.audioContext);

        handsUpClientInfo.audioSoundMeter.connectToSource(handsUpClientInfo.sendAudioStream, function (e) {
            if (e) {
                alert(e);
                return;
            }

            startHandsUpMicVolumeSendProc();
        });
    }
}

/**
 * video element src 설정 및 video element 캡처 process 시작
 */
const initVideoStreamImageSendProc = () => {
    if (handsUpClientInfo.sendVideoStream instanceof MediaStream) {
        setUpImageSendSize(handsUpClientInfo.sendVideoStream.getVideoTracks()[0].getSettings());

        handsUpClientInfo.videoEl.srcObject = handsUpClientInfo.sendVideoStream;
        handsUpClientInfo.videoEl.play();

        if (handsUpClientInfo.activeYn === "Y") {
            startVideoStreamImageSendProc();
        }
    }
}

/**
 * myCam video element src 설정 및 play 시작
 * @param {MediaStream} stream 
 */
const initMyCamDisplayProc = (stream) => {
    if (stream instanceof MediaStream) {
        // setUpImageSendSize(stream.getVideoTracks()[0].getSettings());

        handsUpClientInfo.myCamEl.srcObject = stream;
        handsUpClientInfo.myCamEl.play();
    }
}

/**
 * hands up send image size 세팅
 * @param {MediaTrackSettings} settings 
 */
const setUpImageSendSize = (settings) => {
    if (settings !== undefined && settings !== null) {
        let isPortrait = false;
        let div = document.getElementById("live-area");

        if (div !== undefined && div !== null) {
            if (div.classList.contains("ios")) {
                if (window.screen !== undefined && window.screen !== null) {
                    if (window.innerWidth < window.innerHeight) {
                        isPortrait = true;
                    }
                }
            } else {
                if (window.screen.orientation.type === "portrait-primary" || window.screen.orientation.type === "portrait-secondary") {
                    isPortrait = true;
                }
            }
        }

        if (ConstData.IS_LOCAL_VERSION) { // local version에서는 더 자주 보낸다... by hjkim 20220622
            if (isPortrait) {
                imageSendWidth = 135;
                imageSendHeight = 240;
            } else {
                imageSendWidth = 240;
                imageSendHeight = 135;
            }

            handsUpClientInfo.imageSendTime = ctTimerLv.LV_HIGH_MID;
        } else {
            if (isPortrait) {
                imageSendWidth = 135;
                imageSendHeight = 240;
            } else {
                imageSendWidth = 240;
                imageSendHeight = 135;
            }

            handsUpClientInfo.imageSendTime = ctTimerLv.LV_DEFAULT;
        }

        // console.log("setting info => ", `width[${settings.width}], height[${settings.height}], frameRate[${settings.frameRate}]`);
        console.log("settings info => ", settings);
        console.log(`setUpImageSendSize - imageSendWidth[${imageSendWidth}], imageSendHeight[${imageSendHeight}], imageSendTime[${handsUpClientInfo.imageSendTime}]`);

        if (handsUpClientInfo.canvasEl === null) {
            handsUpClientInfo.canvasEl = window.canvas = document.createElement("canvas");
            handsUpClientInfo.canvasEl.width = imageSendWidth;
            handsUpClientInfo.canvasEl.height = imageSendHeight;
            handsUpClientInfo.contextEl = handsUpClientInfo.canvasEl.getContext("2d");
        } else {
            handsUpClientInfo.canvasEl.width = imageSendWidth;
            handsUpClientInfo.canvasEl.height = imageSendHeight;
            handsUpClientInfo.contextEl = handsUpClientInfo.canvasEl.getContext("2d");
        }
    }
}

/**
 * change hands up send image size
 * @param {Boolean} isVerticalMode 
 */
export const changeImageSize = (isVerticalMode) => {
    // alert(isVerticalMode);
    if (isVerticalMode) {
        if (ConstData.IS_LOCAL_VERSION) {
            imageSendWidth = 135;
            imageSendHeight = 240;
        } else {
            imageSendWidth = 135;
            imageSendHeight = 240;
        }
    } else {
        if (ConstData.IS_LOCAL_VERSION) {
            imageSendWidth = 240;
            imageSendHeight = 135;
        } else {
            imageSendWidth = 240;
            imageSendHeight = 135;
        }
    }

    if (handsUpClientInfo.canvasEl === null) {
        handsUpClientInfo.canvasEl = window.canvas = document.createElement("canvas");
        handsUpClientInfo.canvasEl.width = imageSendWidth;
        handsUpClientInfo.canvasEl.height = imageSendHeight;
        handsUpClientInfo.contextEl = handsUpClientInfo.canvasEl.getContext("2d");
    } else {
        handsUpClientInfo.canvasEl.width = imageSendWidth;
        handsUpClientInfo.canvasEl.height = imageSendHeight;
        handsUpClientInfo.contextEl = handsUpClientInfo.canvasEl.getContext("2d");
    }
}

/**
 * 500ms 마다 mic sound level 체크
 */
const startHandsUpMicVolumeSendProc = () => {
    if (handsUpClientInfo.micVolumeSendProc && (handsUpClientInfo.micVolumeSendProc.current === undefined || handsUpClientInfo.micVolumeSendProc.current === null)) {
        handsUpClientInfo.micVolumeSendProc.current = setInterval(() => {
            if (handsUpClientInfo.audioSoundMeter) {
                let curLevel = handsUpClientInfo.audioSoundMeter.instant.toFixed(2);
                // console.log(`micVolumeSendProc - curLevel[${curLevel}], soundLevel[${handsUpClientInfo.soundLevel}]`);
                if (curLevel !== handsUpClientInfo.soundLevel) {
                    if (0 <= curLevel && curLevel <= 0.05) { // speak 이미지 갱신 문제로 case 수정 by hjkim 20210104
                        sendMicVolumeLevel(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq, ConstCode.LIVE_SOUND_LEVEL_1);
                    } else if (0.05 < curLevel && curLevel <= 0.1) {
                        sendMicVolumeLevel(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq, ConstCode.LIVE_SOUND_LEVEL_2);
                    } else if (0.1 < curLevel && curLevel <= 0.15) {
                        sendMicVolumeLevel(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq, ConstCode.LIVE_SOUND_LEVEL_3);
                    } else if (0.15 < curLevel && curLevel <= 0.2) {
                        sendMicVolumeLevel(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq, ConstCode.LIVE_SOUND_LEVEL_4);
                    } else if (0.25 < curLevel && curLevel <= 0.3) {
                        sendMicVolumeLevel(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq, ConstCode.LIVE_SOUND_LEVEL_5);
                    }

                    handsUpClientInfo.soundLevel = curLevel;

                    // 학생이 말하는 동안 학생 스피커에 선생님 목소리 작게 들리게 하기 위해 ... by hjkim 20231114
                    // 우선은 주석처리.. 나중에 의견나오면 다시 살리기 ... by hjkim 20231114
                    /* if (handsUpClientInfo.soundLevel > 0) {
                        controlSpeakerVolume(0.3);
                    } else {
                        controlSpeakerVolume(1.0);
                    } */
                }
            }
        }, 500);
    }
}

/**
 * handsUpClientInfo.imageSendTime 마다 video element 캡처
 */
const startVideoStreamImageSendProc = () => {
    clearImgSendProc(); // test용 clear... 

    imageSendWidth = 240;
    imageSendHeight = 135;

    let div = document.getElementById("live-area");
    if (div !== undefined && div !== null) {
        if (div.classList.contains("ios")) {
            if (window.screen !== undefined && window.screen !== null) {
                if (window.innerWidth < window.innerHeight) {
                    imageSendWidth = 135;
                    imageSendHeight = 240;
                }
            }
        } else {
            if (window.screen.orientation.type === "portrait-primary" || window.screen.orientation.type === "portrait-secondary") {
                imageSendWidth = 135;
                imageSendHeight = 240;
            }
        }
    }

    if (handsUpClientInfo.videoEl !== undefined && handsUpClientInfo.videoEl !== null) {
        if (handsUpClientInfo.imageSendProc !== undefined && (handsUpClientInfo.imageSendProc.current === undefined || handsUpClientInfo.imageSendProc.current === null)) {
            handsUpClientInfo.imageSendProc.current = setInterval(() => { sendVideoStreamImage(); }, handsUpClientInfo.imageSendTime);
        }
    }
}

/**
 * handsUpClientInfo.imageSendTime 마다 image element 캡처
 */
const startCanvasImageSendProc = () => {
    clearImgSendProc(); // test용 clear... 

    imageSendWidth = 240;
    imageSendHeight = 135;

    let div = document.getElementById("live-area");
    if (div !== undefined && div !== null) {
        if (div.classList.contains("ios")) {
            if (window.screen !== undefined && window.screen !== null) {
                if (window.innerWidth < window.innerHeight) {
                    imageSendWidth = 135;
                    imageSendHeight = 240;
                }
            }
        } else {
            if (window.screen.orientation.type === "portrait-primary" || window.screen.orientation.type === "portrait-secondary") {
                imageSendWidth = 135;
                imageSendHeight = 240;
            }
        }
    }

    handsUpClientInfo.imageSendTime = ctTimerLv.LV_DEFAULT;

    if (handsUpClientInfo.canvasEl === null) {
        handsUpClientInfo.canvasEl = window.canvas = document.createElement("canvas");
        handsUpClientInfo.canvasEl.width = imageSendWidth;
        handsUpClientInfo.canvasEl.height = imageSendHeight;
        handsUpClientInfo.contextEl = handsUpClientInfo.canvasEl.getContext("2d");
    } else {
        handsUpClientInfo.canvasEl.width = imageSendWidth;
        handsUpClientInfo.canvasEl.height = imageSendHeight;
        handsUpClientInfo.contextEl = handsUpClientInfo.canvasEl.getContext("2d");
    }

    if (handsUpClientInfo.imageSendProc !== undefined && (handsUpClientInfo.imageSendProc.current === undefined || handsUpClientInfo.imageSendProc.current === null)) {
        handsUpClientInfo.imageSendProc.current = setInterval(() => { sendCanvasImage(); }, handsUpClientInfo.imageSendTime);
    }
}

/**
 * video element를 캡처하여 전송
 */
const sendVideoStreamImage = () => {
    if (handsUpClientInfo.videoEl !== undefined && handsUpClientInfo.videoEl !== null) {
        handsUpClientInfo.contextEl.drawImage(handsUpClientInfo.videoEl, 0, 0, imageSendWidth, imageSendHeight);
        handsUpClientInfo.canvasEl.toBlob((blob) => {
            sendUpdateHandsUpImage(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq, blob);
        }, imageType, imageQuality);
    }
}

/**
 * image element를 캡처하여 전송
 */
const sendCanvasImage = () => {
    html2canvas(document.body)
    .then((canvasEl) => {
        handsUpClientInfo.contextEl.drawImage(canvasEl, 0, 0, canvasEl.width, canvasEl.height, 0, 0, imageSendWidth, imageSendHeight);
        handsUpClientInfo.canvasEl.toBlob((blob) => {
            sendUpdateHandsUpImage(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq, blob);
            if (handsUpClientInfo.imageEl) {
                let url = URL.createObjectURL(blob);
                handsUpClientInfo.imageEl.src = url;
                handsUpClientInfo.imageEl.onload = function () {
                    URL.revokeObjectURL(url);   //cleanup.
                }
            }
        }, imageType, imageQuality);
    })
    .catch((err) => {
        handleDOMException("sendCanvasImage()", err);
    });
}

const convertDataURIToBinary = (dataURI) => {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    var bb = new Blob([ab], { "type": mimeString });

    return bb;
}

/**
 * hands up 시작
 * @param {Number} lSeq 
 * @param {Number} tSeq 
 * @param {Number} uSeq 
 * @param {String} vKind 
 * @param {Element} vElm 
 * @param {Element} iElm 
 * @param {Element} myCamElm 
 * @param {Object} interval 
 * @param {Object} volumeInterval 
 */
export const startHandsUpMode = (lSeq, tSeq, uSeq, activeYn, vElm, iElm, myCamElm, interval, volumeInterval) => {
    console.log(`startHandsUpMode - lSeq[${lSeq}], uSeq[${uSeq}], activeYn[${activeYn}]`);
    initHandsUpInfo(lSeq, tSeq, uSeq, activeYn);

    handsUpClientInfo.videoEl = vElm;
    handsUpClientInfo.imageEl = iElm;
    handsUpClientInfo.myCamEl = myCamElm;

    handsUpClientInfo.imageSendProc = interval;
    handsUpClientInfo.micVolumeSendProc = volumeInterval;

    updateMediaDevices(true);

    let store = window.hiclasstv.store;
    store.dispatch(changeHandsUpStatusInfo({ kind: "connect" }));
}

/**
 * hands up 종료
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 * @param {Boolean} isClickBtn 
 * @param {Boolean} isNeedMuteAudio
 */
export const stopHandsUpMode = async (lSeq, uSeq, isClickBtn, isNeedMuteAudio) => {
    // console.log(`stopHandsUpMode - lSeq[${lSeq}], uSeq[${uSeq}], isClickBtn[${isClickBtn}], isNeedMuteAudio[${isNeedMuteAudio}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq) {
        clearImgSendProc();
        clearMicVolumeSendProc();

        if (handsUpClientInfo.isAndroidDevice) {
            xmitCmd_AndroidMessage("ct_StopHandsUp", enTokenCMD.NULL);
        }

        sendUnpublish("ALL");

        if (handsUpClientInfo.isMicOn) handsUpClientInfo.isMicOn = false;
        if (!handsUpClientInfo.isMuteMode) handsUpClientInfo.isMuteMode = true; // handsup 종료된 경우 mute 값 초기화.. by hjkim 20220315

        if (handsUpClientInfo.isP2PMode || handsUpClientInfo.isSelectedAudio) {
            await unsubscribeHandsUpConsumeAll(false);       // audio, p2p consume 모두 unsubscribe by hjkim 20220225
            await setUnselectedMode(isNeedMuteAudio);   // 선생님 audio listen, mic mute ** 중요 by hjkim 20220215

            handsUpClientInfo.isP2PMode = false;
            handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail;     // 0 ... Thumbnail ( 640 x 360 ) mode
            handsUpClientInfo.topKind = stTopKind.All_Client;               // "all-client"
            handsUpClientInfo.isSelectedAudio = false;
        }

        if (handsUpClientInfo.imageEl && handsUpClientInfo.imageEl.src) {
            handsUpClientInfo.imageEl.src = null;
        }

        if (handsUpClientInfo.videoInputInfo.list_videoInput.length > 0) {
            handsUpClientInfo.videoInputInfo.list_videoInput = [];
            handsUpClientInfo.videoInputInfo.selectedIndex = -1;
            handsUpClientInfo.videoInputInfo.myCamIndex = -1;
        }

        handsUpClientInfo.liveSeq = -1;
        handsUpClientInfo.userSeq = -1;

        if (ConstData.IS_LOCAL_VERSION || handsUpClientInfo.isMixedClassMember) {
            handsUpClientInfo.viewKind = "SCREEN";
        } else {
            handsUpClientInfo.viewKind = "CAMERA";
        }

        handsUpClientInfo.list_audioConsumeUserSeq = [];
        handsUpClientInfo.smallGroupInfo = [];

        handsUpClientInfo.audioInputInfo = {
            defaultAudioInput: { deviceId: null, groupId: null, label: null },
            currentAudioInput: { deviceId: null, groupId: null, label: null }
        };

        if (navigator && navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = null;
        }

        if (isClickBtn) {
            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "disconnect" }));
        } else {
            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "disable" }));
        }

        isReconnMode = false;
    }
}

/**
 * 클래스 중에 "끝내기"로 나가는 경우 hands up clear
 */
export const clearHandsUpMode = async () => {
    console.log(`clearHandsUpMode - liveSeq[${handsUpClientInfo.liveSeq}], userSeq[${handsUpClientInfo.userSeq}]`);
    if (handsUpClientInfo.liveSeq > -1 && handsUpClientInfo.userSeq > -1) {
        clearImgSendProc();
        clearMicVolumeSendProc();
        await clearStreamSource();

        if (handsUpClientInfo.audioCtx) {
            if (handsUpClientInfo.systemStreamSource !== null && handsUpClientInfo.audioMerger) {
                if (handsUpClientInfo.systemStreamSource.numberOfOutputs > 0) {
                    handsUpClientInfo.systemStreamSource.disconnect(handsUpClientInfo.audioMerger, 0, 0);
                    handsUpClientInfo.isSysConnectFlag = false;
                }
            }

            handsUpClientInfo.micStreamSource = null;
            handsUpClientInfo.systemStreamSource = null;

            if (handsUpClientInfo.audioMerger) {
                handsUpClientInfo.audioMerger.disconnect(handsUpClientInfo.destination);
            }
            handsUpClientInfo.audioMerger = null;

            handsUpClientInfo.destination.stream.getTracks().forEach(track => {
                track.stop();
            });
            handsUpClientInfo.destination = null;

            await handsUpClientInfo.audioCtx.close();
            handsUpClientInfo.audioCtx = null;
        }

        if (handsUpClientInfo.isAndroidDevice) {
            xmitCmd_AndroidMessage("ct_StopHandsUp", enTokenCMD.NULL);
        }

        sendUnpublish("ALL");

        if (handsUpClientInfo.isMicOn) handsUpClientInfo.isMicOn = false;
        if (!handsUpClientInfo.isMuteMode) handsUpClientInfo.isMuteMode = true; // handsup 종료된 경우 mute 값 초기화.. by hjkim 20220315

        if (handsUpClientInfo.isP2PMode || handsUpClientInfo.isSelectedAudio) {
            await unsubscribeHandsUpConsumeAll(false);       // audio, p2p consume 모두 unsubscribe by hjkim 20220225
            await setUnselectedMode(true);   // 선생님 audio listen, mic mute ** 중요 by hjkim 20220215

            handsUpClientInfo.isP2PMode = false;
            handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail;     // 0 ... Thumbnail ( 640 x 360 ) mode
            handsUpClientInfo.topKind = stTopKind.All_Client;               // "all-client"
            handsUpClientInfo.isSelectedAudio = false;
        }

        if (handsUpClientInfo.imageEl && handsUpClientInfo.imageEl.src) {
            handsUpClientInfo.imageEl.src = null;
        }

        if (handsUpClientInfo.videoInputInfo.list_videoInput.length > 0) {
            handsUpClientInfo.videoInputInfo.list_videoInput = [];
            handsUpClientInfo.videoInputInfo.selectedIndex = -1;
            handsUpClientInfo.videoInputInfo.myCamIndex = -1;
        }

        handsUpClientInfo.liveSeq = -1;
        handsUpClientInfo.userSeq = -1;

        if (ConstData.IS_LOCAL_VERSION || handsUpClientInfo.isMixedClassMember) {
            handsUpClientInfo.viewKind = "SCREEN";
        } else {
            handsUpClientInfo.viewKind = "CAMERA";
        }

        handsUpClientInfo.list_audioConsumeUserSeq = [];
        handsUpClientInfo.smallGroupInfo = [];

        handsUpClientInfo.audioInputInfo = {
            defaultAudioInput: { deviceId: null, groupId: null, label: null },
            currentAudioInput: { deviceId: null, groupId: null, label: null }
        };

        if (navigator && navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = null;
        }

        let store = window.hiclasstv.store;
        store.dispatch(changeHandsUpStatusInfo({ kind: "disable" }));
    }
}

/**
 * hands up active 설정 (active 일 경우 image / mic level 전송)
 * active 일 경우 unpublish 했던 audio 를 publish 해야함 (선생님에게)
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const setHandsUpActive = async (lSeq, uSeq) => {
    // console.log(`setHandsUpActive(1) - lSeq[${lSeq}], uSeq[${uSeq}], activeYn[${handsUpClientInfo.activeYn}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq) {
        if (handsUpClientInfo.activeYn === "N") {
            handsUpClientInfo.activeYn = "Y";

            if (handsUpClientInfo.sendVideoStream) {
                // startVideoStreamImageSendProc();
                if (handsUpClientInfo.viewKind === "SCREEN") {
                    gotVideoStream(false, false);
                } else {
                    gotVideoStream(false, true);
                }
            } else {
                if (checkAndroidValue()) {
                    sendStartPentalkOnScreenForAndroid(false, isReconnMode, handsUpClientInfo.isP2PMode, false, handsUpClientInfo.configFPSKind);
                } else {
                    startCanvasImageSendProc();
                }
            }

            // if (!handsUpClientInfo.isMicOn) handsUpClientInfo.isMicOn = true; // local 버전과 cloud 버전 구별 위해 주석 처리 ... by hjkim 20231011
            if (!handsUpClientInfo.isMuteMode) handsUpClientInfo.isMuteMode = true;

            // setUpMicAudioStream(false);
            // initHandsUpMic(false);

            if (handsUpClientInfo.isMicDisable) {
                console.log("setHandsUpActive case 1");
                sendP2PSoundOff(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);

                let store = window.hiclasstv.store;
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "muteDisable" }));
            } else {
                if (ConstData.IS_LOCAL_VERSION) {
                    console.log("setHandsUpActive case 2");
                    sendP2PSoundReadyLocal(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);

                    let store = window.hiclasstv.store;
                    store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "off" }));
                } else {
                    console.log("setHandsUpActive case 3");
                    initHandsUpMic(false);
                    // let store = window.hiclasstv.store;
                    // store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "mute" }));
                }
            }
        }
    }
}

/**
 * hands up inactive 설정 (inactive 일 경우 image / mic level 전송 안 함)
 * inactive 일 경우 publish 하던 audio 를 unpublish, consume audio, p2p 모두 clear
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const setHandsUpInactive = async (lSeq, uSeq) => {
    // console.log(`setHandsUpInactive(1) - lSeq[${lSeq}], uSeq[${uSeq}], activeYn[${handsUpClientInfo.activeYn}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq) {
        if (handsUpClientInfo.activeYn === "Y") {
            handsUpClientInfo.activeYn = "N";
            clearImgSendProc();
            clearMicVolumeSendProc();

            if (checkAndroidValue()) {
                xmitCmd_AndroidMessage("ct_StopScreenCapture", enTokenCMD.NULL);
            }

            if (handsUpClientInfo.isMicOn || handsUpClientInfo.audioStream) {
                sendUnpublish("audio");
            }

            // if (handsUpClientInfo.isMicOn) handsUpClientInfo.isMicOn = false;
            if (!handsUpClientInfo.isMuteMode) handsUpClientInfo.isMuteMode = true; // handsup inactive된 경우 mute 값 초기화 ... by hjkim 20220315

            sendUnpublish("video");

            if (handsUpClientInfo.isP2PMode || handsUpClientInfo.isSelectedAudio || handsUpClientInfo.isSmallGroupMode) {
                // if (handsUpClientInfo.isP2PMode) {
                // sendUnpublish("video");
                // }

                await unsubscribeHandsUpConsumeAll(false);   // audio, p2p consume 모두 unsubscribe by hjkim 20220225
                await setUnselectedMode();              // 선생님 audio listen, mic mute ** 중요 by hjkim 20220215

                handsUpClientInfo.isP2PMode = false;
                handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail;     // 0 ... Thumbnail ( 640 x 360 ) mode
                handsUpClientInfo.topKind = stTopKind.All_Client;               // "all-client"
                handsUpClientInfo.isSelectedAudio = false;
                handsUpClientInfo.isSmallGroupMode = false;
            }

            handsUpClientInfo.list_audioConsumeUserSeq = [];
            handsUpClientInfo.smallGroupInfo = [];

            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
            store.dispatch(smallGroupStop());
        }
    }
}

/**
 * P2P 모드 컨트롤
 * @param {String} topKind // all-client, 1-top-client, 2-top-client, 4-top-client, 6-top-client, 8-top-client, 9-top-client, present-client
 * @param {Number} lSeq 
 * @param {Array} list_user 
 */
export const handleHandsUpP2PMode_new = async (topKind, lSeq, list_user) => {
    console.log(`handleHandsUpP2PMode_new topKind[${topKind}], lSeq[${lSeq}], list_user => `, list_user);
    if (lSeq === handsUpClientInfo.liveSeq) {
        if (list_user && list_user.length > 0) { // list_user 가 있는 경우 p2p 대상들이 있는 case by hjkim 20220224
            const user_info = list_user.find(uSeq => uSeq === handsUpClientInfo.userSeq);
            const members_info = list_user.filter(uSeq => uSeq !== handsUpClientInfo.userSeq);

            if (user_info !== undefined && user_info !== null) { // list_user 에 내가 포함된 case by hjkim 20220224
                console.log("handleHandsUpP2PMode_new case 1 [list_user에 내가 포함된 case]");
                try {
                    clearImgSendProc(); // 1초에 4번 이미지 보내던 process 종료

                    if (handsUpClientInfo.isSelectedAudio || handsUpClientInfo.isP2PMode) { // 기존에 듣고 있던 audio, p2p consume check by hjkim 20220224
                        await handleP2PConsume(list_user, false);
                    }

                    // uSeq가 일치하는 경우 선생님 mic를 들어야한다 by hjkim 20210315
                    await setSelectedMode();    // 선생님 audio mute, mic listen ** 중요 by hjkim 20220215
                } catch (err) {
                    handleDOMException("handleHandsUpP2PMode_new(case-1)", err);
                } finally {
                    if (handsUpClientInfo.isP2PMode === false) { // p2p가 시작될 경우 video stream을 publish 해야함 by hjkim 20210316
                        handsUpClientInfo.isP2PMode = true;
                        handsUpClientInfo.topKind = topKind;

                        if (topKind === stTopKind.All_Client) {                         // "all-client"
                            console.log("handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail 여기를 타는 경우가 있나..? ");
                            handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail; // 0 ... Thumbnail ( 640 x 360 ) mode
                        } else if (topKind === stTopKind.P2P_Top_Client) {              // "2-top-client"
                            handsUpClientInfo.localStreamingMode = enP2PMode.P2P;       // 2 ... 2명 보기 mode ( 1280 x 720 )
                        } else if (topKind === stTopKind.P1P_Top_Client 
                            || topKind === stTopKind.Presentation_Client) {             // "1-top-client" or "present-client"
                            handsUpClientInfo.localStreamingMode = enP2PMode.P1P;       // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                        } else {                                                        // "4-top-client" or "6-top-client" or "8-top-client" or "9-top-client"
                            handsUpClientInfo.localStreamingMode = enP2PMode.P4P;       // 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                        }

                        /** android 용 screen capture 프로그램 실행을 위해서 따로 처리.. by hjkim 20211108 */
                        if (checkAndroidValue()) {
                            // xmitCmd_AndroidMessage("ct_StartP2P", enTokenCMD.NULL);
                            if (handsUpClientInfo.localStreamingMode === enP2PMode.Thumbnail) {     // 0 ... Thumbnail ( 640 x 360 ) mode
                                console.log("localStreamingMode === enP2PMode.Thumbnail 여기를 타는 경우가 있나..? ");
                            } else if (handsUpClientInfo.localStreamingMode === enP2PMode.P1P) {    // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                                xmitCmd_AndroidMessage("ct_StartP1P", enTokenCMD.NULL);
                            } else if (handsUpClientInfo.localStreamingMode === enP2PMode.P2P) {    // 2 ... 2명 보기 mode ( 1280 x 720 )
                                xmitCmd_AndroidMessage("ct_StartP2P", enTokenCMD.NULL);
                            } else {                                                                // 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                                xmitCmd_AndroidMessage("ct_StartP4P", enTokenCMD.NULL);
                            }
                        } else {
                            try {
                                if (handsUpClientInfo.sendVideoStream === null) {
                                    setUpVideoStream(false);
                                } else {
                                    // const vTrack = handsUpClientInfo.sendVideoStream.getVideoTracks()[0];
                                    // await produceVideo(vTrack.clone());
                                    console.log("handsUpClientInfo.videoInputInfo.selectedIndex - ", handsUpClientInfo.videoInputInfo.selectedIndex);
                                    console.log("handsUpClientInfo.viewKind - ", handsUpClientInfo.viewKind);

                                    let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
                                    let isCameraStream = handsUpClientInfo.viewKind === "CAMERA";
                                    console.log(`handleHandsUpP2PMode_new(start-1) - isHighQualityMode[${isHighQualityMode}], isCameraStream[${isCameraStream}]`);
                                    let localStreamingMode = currentP2PMode(handsUpClientInfo.localStreamingMode, handsUpClientInfo.smartTVP2PMode);

                                    await setVideoResolution(isHighQualityMode, isCameraStream, localStreamingMode);

                                    /* if (handsUpClientInfo.viewKind === "SCREEN") {
                                        await produceVideo(vTrack.clone(), handsUpClientInfo.isP2PMode, false);
                                    } else {
                                        await produceVideo(vTrack.clone(), handsUpClientInfo.isP2PMode, true);
                                    } */
                                    /* if (handsUpClientInfo.viewKind === "SCREEN") {
                                        await setVideoResolution(handsUpClientInfo.isP2PMode, false);
                                    } else {
                                        await setVideoResolution(handsUpClientInfo.isP2PMode, true);
                                    } */
                                }
                            } catch (err) {
                                handleDOMException("handleHandsUpP2PMode_new(start-1-1)", err);
                            }
                        }
                    } else {
                        // 이미 isP2PMode 인 경우 android에서만 p2pMode 종류에 따라서 이미지 해상도 다르게 처리! ... by hjkim 20240710
                        if (handsUpClientInfo.topKind !== topKind) {
                            handsUpClientInfo.topKind = topKind;

                            if (topKind === stTopKind.All_Client) {                         // "all-client"
                                console.log("handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail 여기를 타는 경우가 있나..? ");
                                handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail; // 0 ... Thumbnail ( 640 x 360 ) mode
                            } else if (topKind === stTopKind.P2P_Top_Client) {              // "2-top-client"
                                handsUpClientInfo.localStreamingMode = enP2PMode.P2P;       // 2 ... 2명 보기 mode ( 1280 x 720 )
                            } else if (topKind === stTopKind.P1P_Top_Client 
                                || topKind === stTopKind.Presentation_Client) {             // "1-top-client" or "present-client"
                                handsUpClientInfo.localStreamingMode = enP2PMode.P1P;       // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                            } else {                                                        // "4-top-client" or "6-top-client" or "8-top-client" or "9-top-client"
                                handsUpClientInfo.localStreamingMode = enP2PMode.P4P;       // 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                            }

                            if (checkAndroidValue()) {
                                // xmitCmd_AndroidMessage("ct_StartP2P", enTokenCMD.NULL);
                                if (handsUpClientInfo.localStreamingMode === enP2PMode.Thumbnail) {     // 0 ... Thumbnail ( 640 x 360 ) mode
                                    console.log("localStreamingMode === enP2PMode.Thumbnail 여기를 타는 경우가 있나..? ");
                                } else if (handsUpClientInfo.localStreamingMode === enP2PMode.P1P) {    // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                                    xmitCmd_AndroidMessage("ct_StartP1P", enTokenCMD.NULL);
                                } else if (handsUpClientInfo.localStreamingMode === enP2PMode.P2P) {    // 2 ... 2명 보기 mode ( 1280 x 720 )
                                    xmitCmd_AndroidMessage("ct_StartP2P", enTokenCMD.NULL);
                                } else {                                                                // 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                                    xmitCmd_AndroidMessage("ct_StartP4P", enTokenCMD.NULL);
                                }
                            } else {
                                console.log("handsUpClientInfo.videoInputInfo.selectedIndex - ", handsUpClientInfo.videoInputInfo.selectedIndex);
                                console.log("handsUpClientInfo.viewKind - ", handsUpClientInfo.viewKind);

                                let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
                                let isCameraStream = handsUpClientInfo.viewKind === "CAMERA";
                                console.log(`handleHandsUpP2PMode_new(start-1-3) - isHighQualityMode[${isHighQualityMode}], isCameraStream[${isCameraStream}]`);
                                let localStreamingMode = currentP2PMode(handsUpClientInfo.localStreamingMode, handsUpClientInfo.smartTVP2PMode);

                                await setVideoResolution(isHighQualityMode, isCameraStream, localStreamingMode);
                            }
                        }
                    }

                    if (members_info !== undefined && members_info !== null && members_info.length > 0) {
                        try {
                            const promises = members_info.map((uSeq, idx) => subscribeHandsUpP2P(uSeq, idx));
                            await Promise.all(promises);
                        } catch (err) {
                            handleDOMException("handleHandsUpP2PMode_new(start-1-2)", err);
                        }
                    }
                }
            } else { // list_user 에 내가 포함되지 않은 case by hjkim 20220224
                console.log("handleHandsUpP2PMode_new case 2 [list_user에 내가 포함되지 않은 case]");
                try {
                    /* if (handsUpClientInfo.isP2PMode) {
                        sendUnpublish("video");
                    } */

                    /* if (handsUpClientInfo.viewKind === "SCREEN") {
                        await setVideoResolution(handsUpClientInfo.isP2PMode, false);
                    } else {
                        await setVideoResolution(handsUpClientInfo.isP2PMode, true);
                    } */

                    if (handsUpClientInfo.isSelectedAudio || handsUpClientInfo.isP2PMode) { // 기존에 듣고 있던 audio, p2p consume check by hjkim 20220224
                        await handleP2PConsume([], false);
                    }
                } catch (err) {
                    handleDOMException("handleHandsUpP2PMode_new(stop-2)", err);
                } finally {
                    handsUpClientInfo.isP2PMode = false;
                    handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail;     // 0 ... Thumbnail ( 640 x 360 ) mode
                    handsUpClientInfo.topKind = stTopKind.All_Client;               // "all-client"

                    /** android 용 screen capture 프로그램 실행을 위해서 따로 처리.. by hjkim 20211108 */
                    if (checkAndroidValue()) {
                        xmitCmd_AndroidMessage("ct_StopP2P", enTokenCMD.NULL);      // 0 ... Thumbnail ( 640 x 360 ) mode
                    } else {
                        // startVideoStreamImageSendProc();
                        /* if (handsUpClientInfo.viewKind === "SCREEN") {
                            gotVideoStream(false);
                        } else {
                            gotVideoStream(true);
                        } */

                        if (handsUpClientInfo.sendVideoStream === null) {
                            console.log(`handleHandsUpP2PMode_new(stop-2-1) - sendVideoStream is null.`);
                            setUpVideoStream(false);
                        } else {
                            let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
                            let isCameraStream = handsUpClientInfo.viewKind === "CAMERA";
                            console.log(`handleHandsUpP2PMode_new(stop-2-2) - isHighQualityMode[${isHighQualityMode}], isCameraStream[${isCameraStream}]`);
                            let localStreamingMode = currentP2PMode(handsUpClientInfo.localStreamingMode, handsUpClientInfo.smartTVP2PMode);

                            await setVideoResolution(isHighQualityMode, isCameraStream, localStreamingMode);
                        }
                    }

                    // 내가 p2p list에 포함되지 않은 경우여도 다른 p2p 학생들의 소리를 들어야하므로 선생님 audio를 listen 해야한다 by hjkim 20210315
                    try {
                        await setUnselectedMode();          // 선생님 audio listen, mic mute ** 중요 by hjkim 20220215
                    } catch (err) {
                        handleDOMException("handleHandsUpP2PMode_new(stop-2-3)", err);
                    }
                }
            }
        } else { // list_user 가 없는 경우 p2p 종료 case by hjkim 20220224
            console.log("handleHandsUpP2PMode_new case 3 [list_user가 없는 case (=p2p 종료)]");
            try {
                /* if (handsUpClientInfo.isP2PMode) {
                    sendUnpublish("video");
                } */

                /* if (handsUpClientInfo.viewKind === "SCREEN") {
                    await setVideoResolution(handsUpClientInfo.isP2PMode, false);
                } else {
                    await setVideoResolution(handsUpClientInfo.isP2PMode, true);
                } */

                if (handsUpClientInfo.isSelectedAudio || handsUpClientInfo.isP2PMode) {
                    await handleP2PConsume(list_user, true);
                }
            } catch (err) {
                handleDOMException("handleHandsUpP2PMode_new(stop-3-1)", err);
            } finally {
                handsUpClientInfo.isP2PMode = false;
                handsUpClientInfo.localStreamingMode = enP2PMode.Thumbnail;     // 0 ... Thumbnail ( 640 x 360 ) mode
                handsUpClientInfo.topKind = stTopKind.All_Client;

                /** android 용 screen capture 프로그램 실행을 위해서 따로 처리.. by hjkim 20211108 */
                if (checkAndroidValue()) {
                    xmitCmd_AndroidMessage("ct_StopP2P", enTokenCMD.NULL);
                } else {
                    // startVideoStreamImageSendProc();
                    /* if (handsUpClientInfo.viewKind === "SCREEN") {
                        gotVideoStream(false);
                    } else {
                        gotVideoStream(true);
                    } */

                    if (handsUpClientInfo.sendVideoStream === null) {
                        console.log(`handleHandsUpP2PMode_new(stop-3-2) - sendVideoStream is null.`);
                        setUpVideoStream(false);
                    } else {
                        let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
                        let isCameraStream = handsUpClientInfo.viewKind === "CAMERA";
                        console.log(`handleHandsUpP2PMode_new(stop-3-3) - isHighQualityMode[${isHighQualityMode}], isCameraStream[${isCameraStream}]`);
                        let localStreamingMode = currentP2PMode(handsUpClientInfo.localStreamingMode, handsUpClientInfo.smartTVP2PMode);

                        await setVideoResolution(isHighQualityMode, isCameraStream, localStreamingMode);
                    }
                }

                if (handsUpClientInfo.isSelectedAudio) { // audio가 select된 경우 다시 선생님 mic를 들어야한다 by hjkim 20210315
                    await setSelectedMode();            // 선생님 audio mute, mic listen ** 중요 by hjkim 20220215
                } else { // p2p가 종료된 경우 audio select가 아니면 선생님 audio를 들어야한다 by hjkim 20210315
                    await setUnselectedMode();          // 선생님 audio listen, mic mute ** 중요 by hjkim 20220215
                }
            }
        }
    }
}

/**
 * P2P 모드 재시작
 * @param {Number} lSeq 
 * @param {Array} list_user 
 */
export const restartHandsUpP2PMode = async (lSeq, list_user) => {
    // handleHandsUpP2PMode(lSeq, list_user);
    handleHandsUpP2PMode_new(getTopKind(), lSeq, list_user);
}

/**
 * SmartTV 연결 컨트롤
 */
export const handleHandsUpSmartTVMode = async (lSeq, uSeq, isSmartTVMode, localStreamingMode, smartTVTempKey) => {
    console.log(`handleHandsUpSmartTVMode - lSeq[${lSeq}], uSeq[${uSeq}], isSmartTVMode[${isSmartTVMode}], localStreamingMode[${localStreamingMode}], smartTVTempKey[${smartTVTempKey}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq) {
        try {
            console.log("list_smartTVKey => ", handsUpClientInfo.list_smartTVKey, ", indexOf => ", handsUpClientInfo.list_smartTVKey.indexOf(smartTVTempKey));
            if (isSmartTVMode) {
                if (handsUpClientInfo.list_smartTVKey.indexOf(smartTVTempKey) === -1) { // list에 없는 경우 추가해야 함
                    handsUpClientInfo.list_smartTVKey.push(smartTVTempKey);
                } else { // list에 있는 경우 무시
                    console.log("already pushed in list. - smartTVTempKey => ", smartTVTempKey);
                }
            } else {
                if (handsUpClientInfo.list_smartTVKey.indexOf(smartTVTempKey) === -1) { // list에 없는 경우 무시
                    console.log("already poped in list. - smartTVTempKey => ", smartTVTempKey);
                } else { // list에 있는 경우 list에서 빼야 함
                    handsUpClientInfo.list_smartTVKey = handsUpClientInfo.list_smartTVKey.filter(key => key !== smartTVTempKey);
                }
            }

            // handsUpClientInfo.isSmartTVMode = isSmartTVMode;
            handsUpClientInfo.isSmartTVMode = handsUpClientInfo.list_smartTVKey.length > 0;
            console.log(`isSmartTVMode[${handsUpClientInfo.isSmartTVMode}], list_smartTVKey => `, handsUpClientInfo.list_smartTVKey);
            handsUpClientInfo.smartTVP2PMode = localStreamingMode;
            if (!handsUpClientInfo.isSmartTVMode) {
                handsUpClientInfo.smartTVP2PMode = enP2PMode.Thumbnail;
            }

            if (handsUpClientInfo.isP2PMode) { // p2p 모드일 경우 이미 고화질로 보내기 때문에 smartv mode 값 세팅 후 아무것도 하지 않는다 ... by hjkim 20231109
                console.log("handleHandsUpSmartTVMode - already high quality video.");
                let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
                let isCameraStream = handsUpClientInfo.viewKind === "CAMERA";
                console.log(`handleHandsUpSmartTVMode - isHighQualityMode[${isHighQualityMode}], isCameraStream[${isCameraStream}]`);
                let localStreamingMode = currentP2PMode(handsUpClientInfo.localStreamingMode, handsUpClientInfo.smartTVP2PMode);

                await setVideoResolution(isHighQualityMode, isCameraStream, localStreamingMode);
            } else { // p2p 모드가 아닌 경우 저화질로 보내고 있기 때문에 smartv mode 값 세팅 후 고화질로 변경해준다 ... by hjkim 20231109 
                console.log("handleHandsUpSmartTVMode - setting high quality video.");
                if (checkAndroidValue()) {
                    /* if (handsUpClientInfo.isSmartTVMode) {
                        xmitCmd_AndroidMessage("ct_StartP2P", enTokenCMD.NULL);
                    } else {
                        xmitCmd_AndroidMessage("ct_StopP2P", enTokenCMD.NULL);
                    } */
                } else {
                    if (handsUpClientInfo.sendVideoStream === null) {
                        setUpVideoStream(false);
                    } else {
                        let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
                        let isCameraStream = handsUpClientInfo.viewKind === "CAMERA";
                        console.log(`handleHandsUpSmartTVMode - isHighQualityMode[${isHighQualityMode}], isCameraStream[${isCameraStream}]`);
                        let localStreamingMode = currentP2PMode(handsUpClientInfo.localStreamingMode, handsUpClientInfo.smartTVP2PMode);

                        await setVideoResolution(isHighQualityMode, isCameraStream, localStreamingMode);

                        /* if (handsUpClientInfo.viewKind === "SCREEN") {
                            await setVideoResolution(true, false);
                        } else {
                            await setVideoResolution(true, true);
                        } */
                    }
                }
            }
        } catch (err) {
            handleDOMException("handleHandsUpSmartTVMode()", err);
        }
    }
}

/**
 * 소그룹 멤버 별 imgEl 세팅해주기 (?)
 * @param {Number} uSeq 
 * @param {Element} imgEl 
 * @param {Function} imageProcess 
 */
export const initSmallGroupFunc = (uSeq, imgEl, imageProcess) => {
    if (handsUpClientInfo.smallGroupInfo && handsUpClientInfo.smallGroupInfo.length > 0) {
        handsUpClientInfo.smallGroupInfo.forEach(mem => {
            if (mem.userSeq === uSeq) {
                mem.el = imgEl;
                mem.func = imageProcess;
            }
        });
    }
}

/**
 * 소그룹 멤버 별 videoEl 세팅해주기 (?)
 * @param {Number} uSeq 
 * @param {Element} videoEl 
 * @param {Element} audioEl 
 */
/* export const setElementForSmallGroup = (uSeq, videoEl, audioEl) => {
    if (handsUpClientInfo.smallGroupInfo && handsUpClientInfo.smallGroupInfo.length > 0) {
        handsUpClientInfo.smallGroupInfo.forEach(mem => {
            if (mem.userSeq === uSeq) {
                mem.videoEl = videoEl;
                mem.audioEl = audioEl;
            }
        });
    }
} */

/**
 * 소그룹 멤버 화면 업데이트
 * @param {String} status 
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 * @param {ArrayBuffer} imageData 
 */
export const updateSmallGroupImage = (status, lSeq, uSeq, imageData) => {
    // console.log(`updateSmallGroupImage - lSeq[${lSeq}], handsUpClientInfo.liveSeq[${handsUpClientInfo.liveSeq}], handsUpClientInfo.smallGroupInfo => `, handsUpClientInfo.smallGroupInfo);
    if (lSeq === handsUpClientInfo.liveSeq && handsUpClientInfo.smallGroupInfo && handsUpClientInfo.smallGroupInfo.length > 0) {
        let member = handsUpClientInfo.smallGroupInfo.find(info => info.userSeq === uSeq);
        if (member && member.func) {
            member.func(status, imageData);
        }
    }
}

/**
 * HiclassTV Pencam 창에서 Group Study (모둠학습) 시작 시, 
 * [내 얼굴 / 내 화면] -> 펜톡 모니터창으로 전송 (restAPI) 
 * [내 화면] -> Pencam창으로 전송 (MQ)
 * @param {Boolean} status 
 * @param {Array} list_user 
 * @param {String | undefined | null} sendKey 
 */
export const handleGroupStudyMode = async (status, list_user, sendKey) => {
    let members_info = [];

    if (status) {
        if (list_user && list_user.length > 0) {
            list_user.forEach(info => {
                if (info.userSeq !== handsUpClientInfo.userSeq) {
                    members_info.push({
                        userSeq: info.userSeq,
                        isMixedClassMember: info.isMixedClassMember,
                        el: null,
                        func: null
                    });
                }
            });

            startSmallGroupAudioCosume(list_user);
        }

        if (handsUpClientInfo.isAndroidDevice) {
            xmitCmd_AndroidSmallGroupInfo("android", "ct_SmallGroupInfo", sendKey);
        }
    } else {
        stopSmallGroupAudioConsume();

        if (handsUpClientInfo.isAndroidDevice) {
            xmitCmd_AndroidSmallGroupInfo("android", "ct_SmallGroupInfo", null);
        }
    }

    handsUpClientInfo.isSmallGroupMode = status;
    handsUpClientInfo.smallGroupInfo = members_info;
}

/**
 * HiclassTV Pentalk - HandsUp 창에서 Small Group (소그룹활동) 시작 시,
 * [내 얼굴/화면] -> 펜톡 모니터창으로 전송, 멤버들에게 전송 (restAPI)
 * [멤버 음성] consume 
 * @param {Array} list_user 
 */
export const startSmallGroupAudioCosume = async (list_user) => {
    // console.log("startSmallGroupAudioCosume - isP2PMode[${handsUpClientInfo.isP2PMode}], isSelectedAudio[${handsUpClientInfo.isSelectedAudio}], list_user => ", list_user);
    if (list_user && list_user.length > 0) {
        const user_info = list_user.find(info => info.userSeq === handsUpClientInfo.userSeq);
        console.log("user_info => ", user_info);
        const audioUser_info = list_user.filter(info => info.userSeq !== handsUpClientInfo.userSeq);
        console.log("audioUser_info => ", audioUser_info);
        handsUpClientInfo.list_audioConsumeUserSeq = audioUser_info === undefined || audioUser_info === null ? [] : audioUser_info;
        console.log("handsUpClientInfo.list_audioConsumeUserSeq => ", handsUpClientInfo.list_audioConsumeUserSeq);
        if (user_info) {
            try {
                await setSelectedMode();        // 선생님 audio mute, mic listen ** 중요 by hjkim 20220215
            } catch (err) {
                handleDOMException("startSmallGroupAudioCosume(start-1)", err);
            } finally {
                if (audioUser_info && audioUser_info.length > 0) {
                    try {
                        const promises = audioUser_info.map(info => subscribeHandsUpAudio(info.userSeq, info.isMixedClassMember, "video"));
                        await Promise.all(promises);
                    } catch (err) {
                        handleDOMException("startSmallGroupAudioCosume(start-2)", err);
                    }
                }
            }
        }
    }
}

/**
 * HiclassTV Pentalk - HandsUp 창에서 Small Group (소그룹활동) 종료 시,
 * [내 얼굴/화면] 멤버들에게 전송 취소
 * [멤버 음성] unsubsribe 
 */
export const stopSmallGroupAudioConsume = async () => {
    // console.log(`stopSmallGroupAudioConsume - isP2PMode[${handsUpClientInfo.isP2PMode}], isSelectedAudio[${handsUpClientInfo.isSelectedAudio}]`);
    try {
        await unsubscribeHandsUpConsumeAll(false);
        await setUnselectedMode();      // 선생님 audio listen, mic mute ** 중요 by hjkim 20220215
    } catch (err) {
        handleDOMException("stopSmallGroupAudioConsume()", err);
    } finally {
        handsUpClientInfo.list_audioConsumeUserSeq = [];
    }
}

/**
 * list_audioConsumeUserSeq에 list_user 저장 &  
 * me = uSeq일 경우 isSelectedAudio 설정 및 list_audioConsumeUserSeq에 저장된 모든 audio consume 시작
 * @param {Number} lSeq 
 * @param {Array} list_user 
 */
export const initHandsUpAudioConsume = async (lSeq, list_user) => {
    console.log(`initHandsUpAudioConsume - handsUpClientInfo.liveSeq[${handsUpClientInfo.liveSeq}], lSeq[${lSeq}], list_user => `, list_user);
    if (lSeq === handsUpClientInfo.liveSeq) {
        if (list_user && list_user.length > 0) {
            const user_info = list_user.find(uSeq => uSeq === handsUpClientInfo.userSeq);
            const audioUser_info = list_user.filter(uSeq => uSeq !== handsUpClientInfo.userSeq);
            handsUpClientInfo.list_audioConsumeUserSeq = audioUser_info === undefined || audioUser_info === null ? [] : audioUser_info;
            if (user_info) {
                try {
                    await setSelectedMode();        // 선생님 audio mute, mic listen ** 중요 by hjkim 20220215
                } catch (err) {
                    handleDOMException("initHandsUpAudioConsume()", err);
                } finally {
                    handsUpClientInfo.isSelectedAudio = true;

                    let store = window.hiclasstv.store;
                    store.dispatch(mqHandsUpOff({ liveSeq: lSeq, userSeq: user_info }));

                    if (audioUser_info && audioUser_info.length > 0) {
                        try {
                            const promises = audioUser_info.map(uSeq => subscribeHandsUpAudio(uSeq, false));
                            await Promise.all(promises);
                        } catch (err) {
                            handleDOMException("initHandsUpAudioConsume()", err);
                        }
                    }
                }
            }
        }
    }
}

/**
 * 해당 user에 대해 audio consume 시작 또는 
 * me = uSeq일 경우 isSelectedAudio 설정 및 list_audioConsumeUserSeq에 저장된 모든 audio consume 시작
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const startHandsUpAudioConsume = async (lSeq, uSeq) => {
    console.log(`startHandsUpAudioConsume - lSeq[${lSeq}], uSeq[${uSeq}]`);
    if (lSeq === handsUpClientInfo.liveSeq) {
        if (uSeq === handsUpClientInfo.userSeq) {
            try {
                await subscribeHandsUpAudioArray(handsUpClientInfo.list_audioConsumeUserSeq);   // audio select list에 저장된 user들 모두 subscribe by hjkim 20210325
                await setSelectedMode();        // 선생님 audio mute, mic listen ** 중요 by hjkim 20220215
            } catch (err) {
                handleDOMException("startHandsUpAudioConsume()", err);
            } finally {
                handsUpClientInfo.isSelectedAudio = true;

                let store = window.hiclasstv.store;
                store.dispatch(mqHandsUpOff({ liveSeq: lSeq, userSeq: uSeq }));
            }
        } else {
            if (uSeq !== undefined && uSeq !== null && uSeq >= 0) {
                handsUpClientInfo.list_audioConsumeUserSeq.push(uSeq);  // audio select user list 에 저장하기 by hjkim 20210325
                if (handsUpClientInfo.isSelectedAudio) { // 내가 이미 audio select 되었을 경우에만 subscribe ... by hjkim 20210315
                    try {
                        await subscribeHandsUpAudio(uSeq, false);
                    } catch (err) {
                        handleDOMException("startHandsUpAudioConsume()", err);
                    }
                }
            }
        }
    }
}

/**
 * 해당 user에 대해 audio consume 종료 또는 
 * me = uSeq일 경우 isSelectedAudio 해제 및 모든 audio consume 종료
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const stopHandsUpAudioConsume = async (lSeq, uSeq) => {
    // console.log(`stopHandsUpAudioConsume - lSeq[${lSeq}], uSeq[${uSeq}]`);
    if (lSeq === handsUpClientInfo.liveSeq) {
        if (uSeq === handsUpClientInfo.userSeq) {
            try {
                await unsubscribeHandsUpAudio();    // audio select 해제인 경우이기 때문에 모두 unsubscribe한다 by hjkim 20210315
                if (!handsUpClientInfo.isP2PMode) {
                    await setUnselectedMode();      // 선생님 audio listen, mic mute ** 중요 by hjkim 20220215
                }
            } catch (err) {
                handleDOMException("stopHandsUpAudioConsume()", err);
            } finally {
                handsUpClientInfo.isSelectedAudio = false;
            }
        } else {
            if (uSeq !== undefined && uSeq !== null && uSeq >= 0) {
                handsUpClientInfo.list_audioConsumeUserSeq = handsUpClientInfo.list_audioConsumeUserSeq.filter(userSeq => userSeq !== uSeq); // audio select user list 에서 빼기 by hjkim 20210325
                if (handsUpClientInfo.isSelectedAudio) {    // audio가 이미 select되었을 경우에만... by hjkim 20210315
                    try {
                        await unsubscribeHandsUpAudio(uSeq, false);
                    } catch (err) {
                        handleDOMException("stopHandsUpAudioConsume()", err);
                    }
                }
            }
        }
    }
}

/**
 * myCam video input change
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 * @param {Boolean} status 
 */
export const changeMyCamViewInput = (lSeq, uSeq, status) => {
    // console.log(`changeMyCamViewInput - lSeq[${lSeq}], uSeq[${uSeq}], status[${status}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq) {
        if (status) {
            if (handsUpClientInfo.videoInputInfo.list_videoInput.length <= 0) return;

            handsUpClientInfo.videoInputInfo.isMyCamRunning = true;
            setUpMyCamStream();
        } else {
            if (handsUpClientInfo.videoInputInfo.isMyCamRunning) handsUpClientInfo.videoInputInfo.isMyCamRunning = false;

            if (handsUpClientInfo.myCamEl !== undefined && handsUpClientInfo.myCamEl !== null) {
                if (handsUpClientInfo.myCamEl.srcObject !== undefined && handsUpClientInfo.myCamEl.srcObject !== null) {
                    handsUpClientInfo.myCamEl.srcObject.getTracks().forEach(track => {
                        track.stop();
                    });

                    handsUpClientInfo.myCamEl.srcObject = null;
                }
            }

            handsUpClientInfo.videoInputInfo.myCamIndex = -1;
            handsUpClientInfo.videoInputInfo.myCamMediaInfo = { deviceId: null, groupId: null, label: null };
            handsUpClientInfo.handsUpFunc("myCam_invisible");
        }
    }
}

/**
 * send video stream 바꿈
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const changeHandsUpVideoInput = async (lSeq, uSeq) => {
    // console.log(`changeHandsUpVideoInput - lSeq[${lSeq}], uSeq[${uSeq}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq) {
        const changeButton = document.getElementById("btnChangeViewKind");
        if (changeButton && changeButton.disabled) {
            console.log("disabled........ button..");
            return;
        }

        if (changeButton) changeButton.disabled = true;

        if (handsUpClientInfo.videoInputInfo.isMyCamRunning) {
            handsUpClientInfo.videoInputInfo.isMyCamRunning = false;
            if (handsUpClientInfo.myCamEl.srcObject !== undefined && handsUpClientInfo.myCamEl.srcObject !== null) {
                handsUpClientInfo.myCamEl.srcObject.getTracks().forEach(track => {
                    track.stop();
                });

                handsUpClientInfo.myCamEl.srcObject = null;
            }
        }

        if (checkAndroidValue()) xmitCmd_AndroidMessage("ct_StopScreenCapture", enTokenCMD.NULL);

        await clearImgSendProc();

        // p2p 중이었으면... track만 바꾸고 싶다...
        /* if (handsUpClientInfo.isP2PMode) {
            sendUnpublish("video");
        } */

        let idx = handsUpClientInfo.videoInputInfo.selectedIndex;
        idx++;

        isReconnMode = false;

        // 기존 코드---------여기부터
        /** android 용 screen capture 프로그램 실행을 위해서 따로 처리.. by hjkim 20211108 */
        /* if (handsUpClientInfo.viewKind === "SCREEN" && handsUpClientInfo.isAndroidDevice) {
            xmitCmd_AndroidMessage("ct_StopScreenCapture", enTokenCMD.NULL);
        } */

        if (idx < handsUpClientInfo.videoInputInfo.list_videoInput.length) {
            handsUpClientInfo.videoInputInfo.selectedIndex = idx;
            handsUpClientInfo.viewKind = "CAMERA";
        } else {
            handsUpClientInfo.videoInputInfo.selectedIndex = -1;
            handsUpClientInfo.viewKind = "SCREEN";
        }

        if (checkAndroidValue()) sendUnpublish("video");    // viewKind 값을 바꿨는데 만족한다는 건 기존에 camera stream 사용 중이었단 뜻이므로 unpublish 한다 ... by hjkim 20231113

        if (handsUpClientInfo.videoEl !== undefined && handsUpClientInfo.videoEl !== null) {
            if (handsUpClientInfo.videoEl.srcObject !== undefined && handsUpClientInfo.videoEl.srcObject !== null) {
                handsUpClientInfo.videoEl.srcObject.getTracks().forEach(track => {
                    track.stop();
                });

                handsUpClientInfo.videoEl.srcObject = null;
            }
        }

        if (handsUpClientInfo.sendVideoStream !== undefined && handsUpClientInfo.sendVideoStream !== null) {
            handsUpClientInfo.sendVideoStream.getTracks().forEach(track => {
                if (track.kind === "audio") {
                    console.log("sendVideoStream에 audio 있음 ");
                } else {
                    track.stop();
                }
            });

            handsUpClientInfo.sendVideoStream = null;
        }

        console.log(`changeHandsUpVideoInput - viewKind[${handsUpClientInfo.viewKind}], selectedIndex[${handsUpClientInfo.videoInputInfo.selectedIndex}], list_videoInput => `, handsUpClientInfo.videoInputInfo.list_videoInput);
        setUpVideoStream(false);
        // 기존 코드---------여기까지

        // isReconnMode = false;

        /* if (handsUpClientInfo.isAndroidDevice && handsUpClientInfo.isAvailableApp) {
            if (handsUpClientInfo.videoInputInfo.list_videoInput.length > 0) { // android 사용 가능한 카메라가 있는지 체크... by hjkim 20220722
                // android 사용 가능한 카메라가 있는 case ... by hjkim 20220725
                if (handsUpClientInfo.viewKind === "SCREEN") {
                    //xmitCmd_AndroidMessage("ct_StopScreenCapture", enTokenCMD.NULL);
                }

                let beforeViewKind = handsUpClientInfo.viewKind;

                if (idx < handsUpClientInfo.videoInputInfo.list_videoInput.length) {
                    handsUpClientInfo.videoInputInfo.selectedIndex = idx;
                    handsUpClientInfo.viewKind = "CAMERA";
                } else {
                    handsUpClientInfo.videoInputInfo.selectedIndex = -1;
                    handsUpClientInfo.viewKind = "SCREEN";
                }

                if (beforeViewKind === "CAMERA" && handsUpClientInfo.viewKind === "SCREEN") {
                    // p2p 중이었으면... screen capture앱 이용을 위해 기존 p2p를 clear해줘야한다.. by hjkim 20220725
                    if (handsUpClientInfo.isP2PMode) {
                        sendUnpublish("video");
                    }
                }

                setUpVideoStream(false);
            } else { // android 사용 가능한 카메라 없는 case ... by hjkim 20220722
                if (handsUpClientInfo.isP2PMode) {
                    xmitCmd_AndroidMessage("ct_StartP2P", enTokenCMD.NULL);
                } else {
                    xmitCmd_AndroidMessage("ct_StopP2P", enTokenCMD.NULL);
                }
                //sendStartPentalkOnScreenForAndroid(false, isReconnMode, handsUpClientInfo.isP2PMode, false);
                
                const changeButton = document.getElementById("btnChangeViewKind");
                if (changeButton) changeButton.disabled = true;
            }
        } else {
            if (idx < handsUpClientInfo.videoInputInfo.list_videoInput.length) {
                handsUpClientInfo.videoInputInfo.selectedIndex = idx;
                handsUpClientInfo.viewKind = "CAMERA";
            } else {
                handsUpClientInfo.videoInputInfo.selectedIndex = -1;
                handsUpClientInfo.viewKind = "SCREEN";
            }
    
            setUpVideoStream(false);
        } */
    }
}

/**
 * send video viewKind 바꿈
 * @param {String} viewKind 
 */
export const changeViewKind = (viewKind) => {
    // console.log(`changeViewKind - viewKind[${viewKind}], handsUpClientInfo.viewKind[${handsUpClientInfo.viewKind}]`);
    handsUpClientInfo.viewKind = viewKind;
}

/**
 * handsup mic on / off 모드 업데이트
 * @param {Boolean} isMicOn 
 */
export const changeHandsUpMicMode = (isMicOn) => {
    console.log(`changeHandsUpMicMode - isMicOn[${isMicOn}], handsUpClientInfo.isMicOn[${handsUpClientInfo.isMicOn}]`);
    handsUpClientInfo.isMicOn = isMicOn;
}

/**
 * reconnect 모드 업데이트
 * @param {Boolean} isReconn
 */
export const updateReconnMode = (isReconn) => {
    // console.log(`updateReconnMode - isReconnMode[${isReconnMode}], isReconnMode[${isReconn}]`);
    isReconnMode = isReconn;
}

/**
 * Turn on microphone
 * Enable microphone audio stream
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const setHandsUpMicOn = (lSeq, uSeq) => {
    // console.log(`setHandsUpMicOn() - lSeq[${lSeq}], uSeq[${uSeq}], isMicOn[${handsUpClientInfo.isMicOn}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq && !handsUpClientInfo.isMicOn) {
        if (handsUpClientInfo.isMicDisable) return;

        // 임시 주석 처리 --- 이건 media soup 완성 시 사용 예정 --- //
        // if (checkAndroidValue()) { // 20250221 앱과 연결 & 스크린 모드일 경우 따로 처리 ... by hjkim
        //     handsUpClientInfo.handsUpFunc("mic_disable"); // 20250221 여러번 클릭되는 경우 오류가 없도록 우선 disable 처리 ... by hjkim

        //     xmitCmd_AndroidMessage("ct_MicOn", enTokenCMD.NULL);
        // } else {
            handsUpClientInfo.isMicOn = true;
            if (!handsUpClientInfo.isMuteMode) handsUpClientInfo.isMuteMode = true;

            setUpMicAudioStream(false);

            if (handsUpClientInfo.isMicDisable) {
                let store = window.hiclasstv.store;
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "muteDisable" }));
            } else {
                let store = window.hiclasstv.store;
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "mute" }));
            }
        // }
    }
}

/**
 * Turn off microphone
 * Disable microphone audio stream
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const setHandsUpMicOff = (lSeq, uSeq) => {
    // console.log(`setHandsUpMicOff() - lSeq[${lSeq}], uSeq[${uSeq}], isMicOn[${handsUpClientInfo.isMicOn}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq && handsUpClientInfo.isMicOn) {
        if (handsUpClientInfo.isMicDisable) return;

        // 임시 주석 처리 --- 이건 media soup 완성 시 사용 예정 --- //
        // if (checkAndroidValue()) { // 20250221 앱과 연결 & 스크린 모드일 경우 따로 처리 ... by hjkim
        //     handsUpClientInfo.handsUpFunc("mic_disable"); // 20250221 여러번 클릭되는 경우 오류가 없도록 우선 disable 처리 ... by hjkim

        //     xmitCmd_AndroidMessage("ct_MicOff", enTokenCMD.NULL);
        // } else {
            handsUpClientInfo.isMicOn = false;
            handsUpClientInfo.isMuteMode = true; // mic를 끄는 경우 mute값 초기화

            clearMicVolumeSendProc();

            sendUnpublish("audio");
            sendP2PSoundOff(lSeq, uSeq);

            if (handsUpClientInfo.isMicDisable) {
                let store = window.hiclasstv.store;
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
            } else {
                let store = window.hiclasstv.store;
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "off" }));
            }
        // }
    }
}

/**
 * Set microphone button state
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 * @param {Boolean} status 
 */
export const setHandsUpMicStatus = (lSeq, uSeq, status) => {
    console.log(`setHandsUpMicStatus() - lSeq[${lSeq}], uSeq[${uSeq}], status[${status}], isMicOn[${handsUpClientInfo.isMicOn}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq) {
        if (handsUpClientInfo.isMicDisable) return;

        clearMicVolumeSendProc();   // 20250220 혹시 모르니 mic volume 보내는 process 멈춤 ... by hjkim

        // 20250220 
        // 앱에서 마이크 ON/OFF 설정했는데 이 값을 바꿔줘야 할지 
        // 이미지만 변경해줄지 고민 ... by hjkim
        handsUpClientInfo.isMicOn = status;
        if (status) {
            if (!handsUpClientInfo.isMuteMode) handsUpClientInfo.isMuteMode = true;

            sendP2PSoundOn(lSeq, uSeq); // 선생님께 앱의 마이크가 켜졌다고 알림
        } else {
            handsUpClientInfo.isMuteMode = true; // mic를 끄는 경우 mute값 초기화

            sendP2PSoundOff(lSeq, uSeq); // 선생님께 앱의 마이크가 꺼졌다고 알림
        }

        let store = window.hiclasstv.store;

        if (handsUpClientInfo.isMicDisable) {
            if (status) {
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "muteDisable" }));
            } else {
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
            }
        } else {
            if (status) {
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "mute" }));
            } else {
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "off" }));
            }
        }
    }
}

/**
 * Microphone button disable setting
 * @param {Number} lSeq 
 */
export const setHandsUpMicDisable = (lSeq) => {
    console.log(`setHandsUpMicDisable() - lSeq[${lSeq}], isMicOn[${handsUpClientInfo.isMicOn}]`);
    if (lSeq === handsUpClientInfo.liveSeq) {
        handsUpClientInfo.isMicDisable = true;
        handsUpClientInfo.isMicOn = false;
        handsUpClientInfo.isMuteMode = true; // mic를 끄는 경우 mute값 초기화

        clearMicVolumeSendProc();

        sendUnpublish("audio");
        sendP2PSoundOff(lSeq, handsUpClientInfo.userSeq);

        let store = window.hiclasstv.store;
        store.dispatch(changeHandsUpStatusInfo({ kind: "mic-mode", status: "disable" }));
    }
}

/**
 * Microphone button enable setting
 * @param {Number} lSeq 
 */
export const setHandsUpMicEnable = (lSeq) => {
    console.log(`setHandsUpMicEnable() - lSeq[${lSeq}], isMicOn[${handsUpClientInfo.isMicOn}]`);
    if (lSeq === handsUpClientInfo.liveSeq) {
        handsUpClientInfo.isMicDisable = false;
        if (handsUpClientInfo.isMicOn) {
            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "mic-mode", status: "mute" }));
        } else {
            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "mic-mode", status: "off" }));
        }
    }
}

/**
 * 내가 보내는 mic audio stream을 
 * 선생님이 mute 하고 있음을 나에게 알려준 case
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const setHandsUpSoundMuteMode = (lSeq, uSeq) => {
    console.log(`setHandsUpSoundMuteMode() - lSeq[${lSeq}], uSeq[${uSeq}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq && !handsUpClientInfo.isMuteMode) {
        handsUpClientInfo.isMuteMode = true;

        disableAudioTrack();

        let store = window.hiclasstv.store;
        store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "mute" }));
    }
}

/**
 * 내가 보내는 mic audio stream을
 * 선생님이 listen 하고 있음을 나에게 알려준 case
 * @param {Number} lSeq 
 * @param {Number} uSeq 
 */
export const setHandsUpSoundListenMode = (lSeq, uSeq) => {
    // console.log(`setHandsUpSoundListenMode() - lSeq[${lSeq}], uSeq[${uSeq}]`);
    if (lSeq === handsUpClientInfo.liveSeq && uSeq === handsUpClientInfo.userSeq && handsUpClientInfo.isMuteMode) {
        handsUpClientInfo.isMuteMode = false;

        enableAudioTrack();

        let store = window.hiclasstv.store;
        store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "listen" }));
    }
}

/** media source */
/**
 * 사용 가능한 media device info list를 요청한다.
 * @param {Boolean} isHandsUpInit 초기 세팅 완료 teacher에게 알리기 위해 사용 
 */
const updateMediaDevices = async (isHandsUpInit) => {
    // console.log(`updateMediaDevices - isHandsUpInit[${isHandsUpInit}]`, isHandsUpInit);
    if (navigator !== undefined && navigator !== null) {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            alert("enumerateDevices()를 지원하지 않습니다.");
            handsUpClientInfo.videoInputInfo.list_videoInput = [];
            handsUpClientInfo.videoInputInfo.selectedIndex = -1;
            handsUpClientInfo.viewKind = "SCREEN";
            // return;

            initHandsUpMic(isHandsUpInit);
            setUpVideoStream(isHandsUpInit);
        } else {
            let mediaDevices = null;

            try {
                // getSupportedConstraints();
                mediaDevices = await navigator.mediaDevices.enumerateDevices();
            } catch (err) {
                handleDOMException("updateMediaDevices()", err);
            } finally {
                setMediaDevicesInfo(mediaDevices, isHandsUpInit);
            }
        }
    } else {
        handleLogMessage("updateMediaDevices()", "navigator", navigator);
        alert("비대면 기능을 지원하지 않는 브라우저입니다. 관리자에게 문의 바랍니다. (Code.HC001.1)");
    }
}

/**
 * 사용 가능한 media device info list를 기억
 * @param {MediaDeviceInfo} mediaDevices 
 * @param {Boolean} isHandsUpInit 초기 세팅 완료 teacher에게 알리기 위해 사용
 */
const setMediaDevicesInfo = (mediaDevices, isHandsUpInit) => {
    if (handsUpClientInfo.videoInputInfo.list_videoInput.length > 0) {
        if (isHandsUpInit === undefined || isHandsUpInit === null) { // ondevicechange event 발생한 case ... by hjkim 20220311
            if (handsUpClientInfo.viewKind === "SCREEN" && handsUpClientInfo.videoInputInfo.selectedIndex === -1) { // screen 보내는 case ... by hjkim 20220311
                if (handsUpClientInfo.videoInputInfo.isMyCamRunning && handsUpClientInfo.videoInputInfo.myCamIndex > -1) { // myCam 실행 중에 ondevicechange event 발생한 case ... by hjkim 20220311
                    // 이 경우에는 초기화하기 전에 myCam index 기억하고 있어야 한다...
                    handsUpClientInfo.videoInputInfo.myCamMediaInfo = handsUpClientInfo.videoInputInfo.list_videoInput[handsUpClientInfo.videoInputInfo.myCamIndex];
                } else {
                    handsUpClientInfo.videoInputInfo.selectedMediaInfo = { deviceId: null, groupId: null, label: null };
                    handsUpClientInfo.videoInputInfo.myCamMediaInfo = { deviceId: null, groupId: null, label: null };
                }
            } else {
                if (handsUpClientInfo.viewKind === "CAMERA" && handsUpClientInfo.videoInputInfo.selectedIndex > -1) { // camera 보내는 case ... by hjkim 20220311
                    // 이 경우에는 초기화하기 전에 selectedIndex 기억하고 있어야 한다...
                    handsUpClientInfo.videoInputInfo.selectedMediaInfo = handsUpClientInfo.videoInputInfo.list_videoInput[handsUpClientInfo.videoInputInfo.selectedIndex];
                } else {
                    handsUpClientInfo.videoInputInfo.selectedMediaInfo = { deviceId: null, groupId: null, label: null };
                    handsUpClientInfo.videoInputInfo.myCamMediaInfo = { deviceId: null, groupId: null, label: null };
                }
            }
        }
    }

    // 값 초기화...
    handsUpClientInfo.videoInputInfo.list_videoInput = [];
    handsUpClientInfo.videoInputInfo.selectedIndex = -1;
    handsUpClientInfo.videoInputInfo.myCamIndex = -1;
    handsUpClientInfo.audioInputInfo.currentAudioInput = { deviceId: null, groupId: null, label: null };

    let isChangedDefaultAudioInput = false;

    if (mediaDevices && mediaDevices.length > 0) {
        // console.log(mediaDevices);
        for (let index = 0; index < mediaDevices.length; index++) {
            const device = mediaDevices[index];
            if (device.kind === "audioinput") {
                if (device.deviceId === "default") { // for chrome(pc, android)
                    if (!checkDefaultAudioInput(device)) { // default 시스템 오디오 변경 체크 by hjkim 20220315
                        isChangedDefaultAudioInput = true;
                        let option = { deviceId: device.deviceId, groupId: device.groupId, label: device.label };
                        handsUpClientInfo.audioInputInfo.defaultAudioInput = option;
                    }
                } else {
                    if (index === 0 && !isChangedDefaultAudioInput) { // audio input 0번째에 있지만 deviceId가 default가 아닌 경우... (ex.ios) by hjkim 20220315
                        // alert(`deviceId[${device.deviceId}], label[${device.label}], groupId[${device.groupId}]`);
                        if (!checkDefaultAudioInput(device)) { // default 시스템 오디오 변경 체크 by hjkim 20220316
                            isChangedDefaultAudioInput = true;
                            let option = { deviceId: device.deviceId, groupId: device.groupId, label: device.label };
                            handsUpClientInfo.audioInputInfo.defaultAudioInput = option;
                        }
                    } else {
                        if (isChangedDefaultAudioInput) { // default 시스템 오디오가 변경되었으므로 currentAudioInput 값도 변경하기 위해... by hjkim 20220315
                            if (checkDefaultAudioInput(device)) { // defaultAudioInput 값과 비교 by hjkim 20220315
                                let option = { deviceId: device.deviceId, groupId: device.groupId, label: device.label };
                                handsUpClientInfo.audioInputInfo.currentAudioInput = option;
                            }
                        }
                    }
                }
            } else if (device.kind === "videoinput") {
                // console.log("device.getCapabilities => ", device.getCapabilities());
                if (checkEnabledVideoInput(device)) {
                    let option = { deviceId: device.deviceId, groupId: device.groupId, label: device.label };
                    handsUpClientInfo.videoInputInfo.list_videoInput.push(option);
                }
            }
        }
    } else {
        handsUpClientInfo.videoInputInfo.list_videoInput = [];
    }

    console.log(`setMediaDevicesInfo - `, handsUpClientInfo.videoInputInfo.list_videoInput);

    if (isHandsUpInit === true) {
        if (handsUpClientInfo.viewKind === "CAMERA") {
            let idx = handsUpClientInfo.videoInputInfo.selectedIndex;
            idx++;

            if (idx < handsUpClientInfo.videoInputInfo.list_videoInput.length) {
                handsUpClientInfo.videoInputInfo.selectedIndex = idx;
                handsUpClientInfo.viewKind = "CAMERA";
            } else {
                handsUpClientInfo.videoInputInfo.selectedIndex = -1;
                handsUpClientInfo.viewKind = "SCREEN";
            }
        } else {
            handsUpClientInfo.videoInputInfo.selectedIndex = -1;
            handsUpClientInfo.viewKind = "SCREEN";
        }

        initHandsUpMic(isHandsUpInit);
        setUpVideoStream(isHandsUpInit);
    } else {
        if (isChangedDefaultAudioInput) {
            initHandsUpMic(isHandsUpInit); // default 시스템 오디오가 바뀐 경우......by hjkim 20220314
        }

        if (handsUpClientInfo.videoInputInfo.list_videoInput && handsUpClientInfo.videoInputInfo.list_videoInput.length > 0) {
            // console.log("(1) setMediaDevicesInfo - list_videoInput => ", handsUpClientInfo.videoInputInfo.list_videoInput);
            if (handsUpClientInfo.viewKind === "SCREEN" && handsUpClientInfo.videoInputInfo.isMyCamRunning) { // myCam 실행중이던 case ... by hjkim 20220311
                if (handsUpClientInfo.videoInputInfo.myCamMediaInfo.deviceId !== null) {
                    handsUpClientInfo.videoInputInfo.myCamIndex = handsUpClientInfo.videoInputInfo.list_videoInput.findIndex(info => info.deviceId === handsUpClientInfo.videoInputInfo.myCamMediaInfo.deviceId);
                    if (handsUpClientInfo.videoInputInfo.myCamIndex === -1) {
                        console.log("setMediaDevicesInfo - case 1 (change myCam)");
                        // 마이 캠 사용하던 카메라가 달라졌다는걸 알려주고 싶다.....
                        // 사용 가능한 0번 카메라를 사용
                        if (handsUpClientInfo.myCamEl) {
                            if (handsUpClientInfo.myCamEl.srcObject !== undefined && handsUpClientInfo.myCamEl.srcObject !== null) {
                                handsUpClientInfo.myCamEl.srcObject.getTracks().forEach(track => {
                                    track.stop();
                                });

                                handsUpClientInfo.myCamEl.srcObject = null;
                            }
                        }

                        handsUpClientInfo.videoInputInfo.myCamIndex++;
                        setUpMyCamStream();
                    } else {
                        // 마이 캠 사용하던 카메라 그대로 있음... 현상태 유지...
                        console.log("setMediaDevicesInfo - case 2 (not doing)");
                    }
                } else {
                    // 이런 경우가 있을 수 있으려나..?ㅁ?...
                    console.log("setMediaDevicesInfo - case 3 => ", handsUpClientInfo.videoInputInfo.myCamMediaInfo);
                }
            } else {
                if (handsUpClientInfo.viewKind === "CAMERA") { // camera 보내던 case ... by hjkim 20220311
                    if (handsUpClientInfo.videoInputInfo.selectedMediaInfo.deviceId !== null) {
                        handsUpClientInfo.videoInputInfo.selectedIndex = handsUpClientInfo.videoInputInfo.list_videoInput.findIndex(info => info.deviceId === handsUpClientInfo.videoInputInfo.selectedMediaInfo.deviceId);
                        if (handsUpClientInfo.videoInputInfo.selectedIndex === -1) {
                            console.log("setMediaDevicesInfo - case 4 (change camera)");
                            // 사용하던 카메라가 없어진 경우... 사용 가능한 카메라가 있으면 0번 카메라를 사용
                            // 사용하던 카메라가 없어진 경우... 사용 가능한 카메라가 없으면 screen 으로 변경...  => screen or canvas
                            clearImgSendProc();

                            if (handsUpClientInfo.videoEl !== undefined && handsUpClientInfo.videoEl !== null) {
                                if (handsUpClientInfo.videoEl.srcObject !== undefined && handsUpClientInfo.videoEl.srcObject !== null) {
                                    handsUpClientInfo.videoEl.srcObject.getTracks().forEach(track => {
                                        track.stop();
                                    });

                                    handsUpClientInfo.videoEl.srcObject = null;
                                }
                            }

                            if (handsUpClientInfo.sendVideoStream !== undefined && handsUpClientInfo.sendVideoStream !== null) {
                                handsUpClientInfo.sendVideoStream.getTracks().forEach(track => {
                                    track.stop();
                                });

                                handsUpClientInfo.sendVideoStream = null;
                            }

                            handsUpClientInfo.videoInputInfo.selectedIndex++;
                            setUpVideoStream(isHandsUpInit);
                        } else {
                            // 사용하던 카메라 그대로 있음... 현상태 유지...
                            console.log("setMediaDevicesInfo - case 5 (not doing)");
                        }
                    } else {
                        // 이런 경우가 있을 수 있으려나...?ㅁ?...
                        console.log("setMediaDevicesInfo - case 6 => ", handsUpClientInfo.videoInputInfo.selectedMediaInfo);
                    }
                }
            }
        } else { // ondevicechange event 후 사용 가능한 mediaDevices(videoinput)가 없어진 경우... by hjkim 20220311
            // console.log("(2) setMediaDevicesInfo - list_videoInput => ", handsUpClientInfo.videoInputInfo.list_videoInput);
            if (handsUpClientInfo.viewKind === "SCREEN" && handsUpClientInfo.videoInputInfo.isMyCamRunning) { // myCam 실행중이던 case ... by hjkim 20220311
                // 마이 캠 사용 안되는걸 알려주자..... handsUpClientInfo.videoInputInfo.isMyCamRunning false 해야함...
                console.log("setMediaDevicesInfo - case 7");
                handsUpClientInfo.handsUpFunc("myCam_noLongerAvailable", handsUpClientInfo.videoInputInfo.myCamMediaInfo);
            } else {
                if (handsUpClientInfo.viewKind === "CAMERA") {
                    // 카메라 사용 안되는걸 알려주고 싶다....
                    console.log("setMediaDevicesInfo - case 8");
                    handsUpClientInfo.handsUpFunc("camera_noLongerAvailable", handsUpClientInfo.videoInputInfo.selectedMediaInfo);
                }
            }
        }
    }
}

/**
 * check video kind and set video stream
 * @param {Boolean} isHandsUpInit 초기 세팅 완료 teacher에게 알리기 위해 사용
 */
const setUpVideoStream = (isHandsUpInit) => {
    console.log(`setUpVideoStream[${isHandsUpInit}]`);
    if (navigator && navigator.mediaDevices) {
        if (handsUpClientInfo.viewKind === "CAMERA") {
            if (handsUpClientInfo.videoInputInfo.list_videoInput.length > 0) {
                setUpCameraVideoStream(isHandsUpInit);
            } else {
                console.log("No cameras available... try screen mode");
                setUpScreenDisplayStream(isHandsUpInit);
            }
        } else {
            setUpScreenDisplayStream(isHandsUpInit);
        }
    } else { // camera, screen을 시도할 수 없는 경우 by hjkim 20220913
        if (handsUpClientInfo.isAndroidDevice) { // android 기기일 경우 app을 호출하는 process를 타게 한다.. by hjkim 20220824
            setUpScreenDisplayStream(isHandsUpInit);
        } else { // android 기기가 아닐 경우 canvas 웹페이지 이미지를 사용한다.. by hjkim 20220913
            if (isHandsUpInit !== true) { // ondevicechange event 발생 후에 불리는데... 혹시나.. 전에 보내고 있었을수도... clear... by hjkim 20220311
                sendUnpublish("video");
            }

            startCanvasImageSendProc();

            if (isHandsUpInit === true) {
                sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
            }

            handsUpClientInfo.handsUpFunc("viewKind_screen");
            handsUpClientInfo.handsUpFunc("hImg_visible");
        }
    }
}

const getSupportedConstraints = () => {
    if (navigator && navigator.mediaDevices) {
        let constraints = navigator.mediaDevices.getSupportedConstraints();
        console.log(constraints);
    }
}

/**
 * 카메라 video stream 권한 요청
 * @param {Boolean} isHandsUpInit 초기 세팅 완료 teacher에게 알리기 위해 사용
 */
const setUpCameraVideoStream = async (isHandsUpInit) => {
    if (handsUpClientInfo.videoInputInfo.list_videoInput.length <= 0 || handsUpClientInfo.videoInputInfo.selectedIndex === -1) {
        console.log("can not use camera...! try screen mode (1)");
        return;
    }

    console.log(`setUpCameraVideoStream [${isHandsUpInit}]`);

    let currSelectedIndex = handsUpClientInfo.videoInputInfo.selectedIndex;
    console.log(`setUpCameraVideoStream - currSelectedIndex[${currSelectedIndex}], list_videoInput => `, handsUpClientInfo.videoInputInfo.list_videoInput);
    console.log(`setUpCameraVideoStream - list_videoInput[currSelectedIndex(${currSelectedIndex})] => `, handsUpClientInfo.videoInputInfo.list_videoInput[currSelectedIndex]);
    const deviceId = handsUpClientInfo.videoInputInfo.list_videoInput[currSelectedIndex].deviceId;
    console.log(`setUpCameraVideoStream - deviceId[${deviceId}]`);
    if (deviceId === undefined || deviceId === null) {
        console.log("can not use camera...! try screen mode (2-2)");
        return;
    }

    /* let constraints = {
        audio: false,
        video: { deviceId: deviceId ? { exact: deviceId } : undefined }
    }; */
    let constraints = {
        audio: false,
        video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
            ...BandConstraints.Camera
        }
    };

    /* let constraints = {
        audio: false,
        video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
            width: { min: cameraBand.minWidth, ideal: cameraBand.idealWidth, max: cameraBand.maxWidth },
            height: { min: cameraBand.minHeight, ideal: cameraBand.idealHeight, max: cameraBand.maxHeight },
            frameRate: { min: cameraBand.minFrameRate, ideal: cameraBand.idealFrameRate, max: cameraBand.maxFrameRate }
        }
    }; */

    if (navigator.mediaDevices === undefined || navigator.mediaDevices === null) {
        // console.log("older browser version... can not use new API for video");
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

        if (navigator.getUserMedia) {
            navigator.getUserMedia(constraints, (videoStream) => {
                if (videoStream !== undefined && videoStream !== null) {
                    handsUpClientInfo.sendVideoStream = videoStream;

                    /* if (handsUpClientInfo.isP2PMode) {
                        gotVideoStream();
                    } else {
                        initVideoStreamImageSendProc();
                    } */

                    gotVideoStream(isHandsUpInit, true);

                    /* if (isHandsUpInit === true) {
                        sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                    } */

                    handsUpClientInfo.handsUpFunc("viewKind_camera");
                } else {
                    // 이런 경우가 있을 수 있나..?ㅁ?....
                    startCanvasImageSendProc();

                    if (isHandsUpInit === true) {
                        sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                    }

                    handsUpClientInfo.handsUpFunc("viewKind_screen");
                    handsUpClientInfo.handsUpFunc("hImg_visible");

                    if (handsUpClientInfo.isP2PMode) {
                        if (isHandsUpInit !== true) {
                            sendUnpublish("video");
                        }
                    }

                    let str = "카메라와 스크린 캡처를 지원하지 않는 브라우저이기에\n";
                    if (handsUpClientInfo.localStreamingMode !== 0) {
                        if (handsUpClientInfo.localStreamingMode === 1) {     // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                            str += "WebRTC 모드를 실행할 수 없습니다.\n";
                        } else {                            // 2 ... 2명 보기 mode ( 1280 x 720 ) & 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                            str += "P2P 모드를 실행할 수 없습니다.\n";
                        }
                    } else {                                // 0 ... Thumbnail ( 640 x 360 ) mode
                        str += "카메라 전송 모드를 실행할 수 없습니다.\n";
                    }

                    str += "카메라와 스크린 캡처 모드가 지원되기 위해서는\n";
                    str += "최신 웹 브라우저로 접속한 후에 해당 기능을 사용할 수 있습니다. (Code.HC300.1)";
                    console.log("(1) str => ", str);
                    alert(str);

                    /* if (handsUpClientInfo.isP2PMode) {
                        if (isHandsUpInit !== true) {
                            sendUnpublish("video");
                        }

                        alert("카메라와 스크린 캡처를 지원하지 않아 P2P 모드를 실행할 수 없습니다. (Code.HC300.1)");
                    } */
                }
            }, (err) => {
                handleLogMessage("setUpCameraVideoStream(old)", "selectedIndex", handsUpClientInfo.videoInputInfo.selectedIndex);
                handleDOMException("setUpCameraVideoStream(old)", err);

                startCanvasImageSendProc();

                if (isHandsUpInit === true) {
                    sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                }

                handsUpClientInfo.handsUpFunc("viewKind_screen");
                handsUpClientInfo.handsUpFunc("hImg_visible");

                if (handsUpClientInfo.isP2PMode) {
                    if (isHandsUpInit !== true) {
                        sendUnpublish("video");
                    }
                }

                let str = "카메라와 스크린 캡처를 지원하지 않는 브라우저이기에\n";
                if (handsUpClientInfo.localStreamingMode !== 0) {
                    if (handsUpClientInfo.localStreamingMode === 1) {     // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                        str += "WebRTC 모드를 실행할 수 없습니다.\n";
                    } else {                            // 2 ... 2명 보기 mode ( 1280 x 720 ) & 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                        str += "P2P 모드를 실행할 수 없습니다.\n";
                    }
                } else {                                // 0 ... Thumbnail ( 640 x 360 ) mode
                    str += "카메라 전송 모드를 실행할 수 없습니다.\n";
                }

                str += "카메라와 스크린 캡처 모드가 지원되기 위해서는\n";
                str += "최신 웹 브라우저로 접속한 후에 해당 기능을 사용할 수 있습니다. (Code.HC300.2)";
                console.log("(2) str => ", str);
                alert(str);

                /* if (handsUpClientInfo.isP2PMode) {
                    if (isHandsUpInit !== true) {
                        sendUnpublish("video");
                    }

                    alert("카메라와 스크린 캡처를 지원하지 않아 P2P 모드를 실행할 수 없습니다. (Code.HC300.2)");
                } */
            });
        } else {
            startCanvasImageSendProc();

            if (isHandsUpInit === true) {
                sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
            }

            handsUpClientInfo.handsUpFunc("viewKind_screen");
            handsUpClientInfo.handsUpFunc("hImg_visible");

            if (handsUpClientInfo.isP2PMode) {
                if (isHandsUpInit !== true) {
                    sendUnpublish("video");
                }
            }

            let str = "카메라와 스크린 캡처를 지원하지 않는 브라우저이기에\n";
            if (handsUpClientInfo.localStreamingMode !== 0) {
                if (handsUpClientInfo.localStreamingMode === 1) {     // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                    str += "WebRTC 모드를 실행할 수 없습니다.\n";
                } else {                            // 2 ... 2명 보기 mode ( 1280 x 720 ) & 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                    str += "P2P 모드를 실행할 수 없습니다.\n";
                }
            } else {                                // 0 ... Thumbnail ( 640 x 360 ) mode
                str += "카메라 전송 모드를 실행할 수 없습니다.\n";
            }

            str += "카메라와 스크린 캡처 모드가 지원되기 위해서는\n";
            str += "최신 웹 브라우저로 접속한 후에 해당 기능을 사용할 수 있습니다. (Code.HC300.3)";
            console.log("(3) str => ", str);
            alert(str);

            /* if (handsUpClientInfo.isP2PMode) {
                if (isHandsUpInit !== true) {
                    sendUnpublish("video");
                }

                alert("카메라와 스크린 캡처를 지원하지 않아 P2P 모드를 실행할 수 없습니다. (Code.HC300.3)");
            } */
        }
    } else {
        // console.log("can use new API browser version... for video");
        let videoStream = null;

        try {
            // getSupportedConstraints();
            videoStream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (err) {
            handleLogMessage("setUpCameraVideoStream(new)", "selectedIndex", handsUpClientInfo.videoInputInfo.selectedIndex);
            handleDOMException("setUpCameraVideoStream(new)", err);
        } finally {
            if (videoStream !== null) {
                handsUpClientInfo.sendVideoStream = videoStream;

                /* if (handsUpClientInfo.isP2PMode) {
                    gotVideoStream();
                } else {
                    initVideoStreamImageSendProc();
                } */

                gotVideoStream(isHandsUpInit, true);

                /* if (isHandsUpInit === true) {
                    sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                } */

                handsUpClientInfo.handsUpFunc("viewKind_camera");
            } else {
                let idx = handsUpClientInfo.videoInputInfo.selectedIndex;
                idx++;

                if (idx < handsUpClientInfo.videoInputInfo.list_videoInput.length) {
                    handsUpClientInfo.videoInputInfo.selectedIndex = idx;
                    handsUpClientInfo.viewKind = "CAMERA";
                } else {
                    handsUpClientInfo.videoInputInfo.selectedIndex = -1;
                    handsUpClientInfo.viewKind = "SCREEN";
                }

                if (handsUpClientInfo.viewKind === "SCREEN") {
                    console.log("can not use camera.. try screen (3)");
                    if (checkAndroidValue()) sendUnpublish("video");    // viewKind 값을 바꿨는데 만족한다는 건 기존에 camera stream 사용 중이었단 뜻이므로 unpublish 한다 ... by hjkim 20231113
                    setUpScreenDisplayStream(isHandsUpInit);
                } else {
                    console.log(`can not use currSelectedIndex[${currSelectedIndex}] camera.. try next selectedIndex[${handsUpClientInfo.videoInputInfo.selectedIndex}] camera`);
                    setUpCameraVideoStream(isHandsUpInit);
                    /* const deviceId = handsUpClientInfo.videoInputInfo.list_videoInput[handsUpClientInfo.videoInputInfo.selectedIndex].deviceId;
                    if (deviceId === undefined || deviceId === null) {
                        console.log("can not use camera...! try screen mode (2-3)");
                        return;
                    }

                    // let constraints = {
                    //     audio: false,
                    //     video: { deviceId: deviceId ? { exact: deviceId } : undefined }
                    // };

                    constraints = {
                        audio: false,
                        video: {
                            deviceId: deviceId ? { exact: deviceId } : undefined,
                            width: { min: cameraBand.minWidth, ideal: cameraBand.idealWidth, max: cameraBand.maxWidth },
                            height: { min: cameraBand.minHeight, ideal: cameraBand.idealHeight, max: cameraBand.maxHeight },
                            frameRate: { min: cameraBand.minFrameRate, ideal: cameraBand.idealFrameRate, max: cameraBand.maxFrameRate }
                        }
                    };

                    try {
                        // getSupportedConstraints();
                        videoStream = await navigator.mediaDevices.getUserMedia(constraints);
                    } catch (err) {
                        handleLogMessage("setUpCameraVideoStream(new)", "selectedIndex", handsUpClientInfo.videoInputInfo.selectedIndex);
                        handleDOMException("setUpCameraVideoStream(new)", err);
                    } finally {
                        if (videoStream !== null) {
                            handsUpClientInfo.sendVideoStream = videoStream;

                            // if (handsUpClientInfo.isP2PMode) {
                            //     gotVideoStream();
                            // } else {
                            //     initVideoStreamImageSendProc();
                            // }

                            gotVideoStream(isHandsUpInit, true);

                            // if (isHandsUpInit === true) {
                            //     sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                            // }

                            handsUpClientInfo.handsUpFunc("viewKind_camera");
                        } else {
                            console.log("can not use camera.. try screen (4)");
                            setUpScreenDisplayStream(isHandsUpInit);
                        }
                    } */
                }
            }
        }
    }
}

/**
 * 화면 video stream 권한 요청
 * @param {Boolean} isHandsUpInit 초기 세팅 완료 teacher에게 알리기 위해 사용
 */
const setUpScreenDisplayStream = async (isHandsUpInit) => {
    /** android 용 screen capture 프로그램 실행을 위해서 따로 처리.. by hjkim 20211108 */
    if (handsUpClientInfo.isAndroidDevice) {
        if (handsUpClientInfo.isAvailableApp) {
            if (getIsAlivePenCamService()) {
                sendStartPentalkOnScreenForAndroid(false, isReconnMode, handsUpClientInfo.isP2PMode, isHandsUpInit);

                handsUpClientInfo.handsUpFunc("viewKind_screen");
                handsUpClientInfo.handsUpFunc("android_appConnect");
                // handsUpClientInfo.handsUpFunc("mic_disable");
            } else {
                sendStartPentalkOnScreenForAndroid(false, isReconnMode, handsUpClientInfo.isP2PMode, isHandsUpInit);

                handsUpClientInfo.timerProc = setTimeout((isHandsUpInit) => {
                    if (document.webkitHidden || document.hidden) {
                        console.log("앱 설치되지 않은 케이스! (1)");
                        handsUpClientInfo.isAvailableApp = false; // 앱 설치되지 않은 케이스

                        startCanvasImageSendProc();

                        if (isHandsUpInit === true) {
                            sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                        }

                        handsUpClientInfo.handsUpFunc("viewKind_screen");
                        handsUpClientInfo.handsUpFunc("android_appNotConnect"); // 보이는 이미지 수정 ... by hjkim 20241128
                        // handsUpClientInfo.handsUpFunc("mic_disable");
                        // handsUpClientInfo.handsUpFunc("hImg_visible");

                        let str = "스크린 캡처를 지원하지 않는 브라우저이기에\n";
                        if (handsUpClientInfo.localStreamingMode !== 0) {
                            if (handsUpClientInfo.localStreamingMode === 1) {     // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                                str += "WebRTC 모드를 실행할 수 없습니다.\n";
                            } else {                            // 2 ... 2명 보기 mode ( 1280 x 720 ) & 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                                str += "P2P 모드를 실행할 수 없습니다.\n";
                            }
                        } else {                                // 0 ... Thumbnail ( 640 x 360 ) mode
                            str += "화면 전송 모드를 실행할 수 없습니다.\n";
                        }

                        str += "스크린 캡처 모드가 지원되기 위해서는\n";
                        // str += "반드시 [Pentalk onScreen] 앱이 설치되어야 합니다.\n";
                        str += "[Pentalk onScreen] 앱을 설치한 후에 해당 기능을 사용할 수 있습니다. (Code.HC301.1)";
                        console.log("(1) str => ", str);
                        alert(str);

                        /* if (handsUpClientInfo.isP2PMode) {
                            if (isHandsUpInit !== true) {
                                // mediaSoup server 에 알려주고... sendUnpublish("video") .....
                            }

                            alert("스크린 캡처를 지원하지 않는 브라우저이기에 P2P 모드를 실행할 수 없습니다. (Code.HC301.1)");
                        } */
                    } else {
                        console.log("앱 설치된 케이스! 앱 정상적으로 실행됨!");
                        handsUpClientInfo.handsUpFunc("viewKind_screen");
                        handsUpClientInfo.handsUpFunc("android_appNotConnect"); // 초기에는 연결되지 않은 이미지로 수정 ... by hjkim 20241128
                        // handsUpClientInfo.handsUpFunc("mic_disable");
                        // handsUpClientInfo.handsUpFunc("android_appConnect");
                    }

                    clearTimeout(handsUpClientInfo.timerProc);
                    handsUpClientInfo.timerProc = null;
                }, 2000, isHandsUpInit);
            }
        } else {
            console.log("앱 설치되지 않은 케이스! (2)");
            startCanvasImageSendProc();

            if (isHandsUpInit === true) {
                sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
            }

            handsUpClientInfo.handsUpFunc("viewKind_screen");
            handsUpClientInfo.handsUpFunc("android_appNotConnect"); // 보이는 이미지 수정 ... by hjkim 20241128
            // handsUpClientInfo.handsUpFunc("mic_disable");
            // handsUpClientInfo.handsUpFunc("hImg_visible");

            let str = "스크린 캡처를 지원하지 않는 브라우저이기에\n";
            if (handsUpClientInfo.localStreamingMode !== 0) {
                if (handsUpClientInfo.localStreamingMode === 1) {     // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                    str += "WebRTC 모드를 실행할 수 없습니다.\n";
                } else {                            // 2 ... 2명 보기 mode ( 1280 x 720 ) & 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                    str += "P2P 모드를 실행할 수 없습니다.\n";
                }
            } else {                                // 0 ... Thumbnail ( 640 x 360 ) mode
                str += "화면 전송 모드를 실행할 수 없습니다.\n";
            }

            str += "스크린 캡처 모드가 지원되기 위해서는\n";
            // str += "반드시 [Pentalk onScreen] 앱이 설치되어야 합니다.\n";
            str += "[Pentalk onScreen] 앱을 설치한 후에 해당 기능을 사용할 수 있습니다. (Code.HC301.2)";
            console.log("(2) str => ", str);
            alert(str);

            /* if (handsUpClientInfo.isP2PMode) {
                if (isHandsUpInit !== true) {
                    // sendUnpublish("video");
                }

                alert("스크린 캡처를 지원하지 않는 브라우저이기에 P2P 모드를 실행할 수 없습니다. (Code.HC301.2)");
            } */
        }
    } else {
        if (navigator.mediaDevices === undefined || navigator.mediaDevices === null) {
            // alert("can not use screen stream mode....");
            let str = "스크린 캡처를 지원하지 않는 브라우저이기에\n";
            if (handsUpClientInfo.localStreamingMode !== 0) {
                if (handsUpClientInfo.localStreamingMode === 1) {     // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                    str += "WebRTC 모드를 실행할 수 없습니다.\n";
                } else {                            // 2 ... 2명 보기 mode ( 1280 x 720 ) & 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                    str += "P2P 모드를 실행할 수 없습니다.\n";
                }
            } else {                                // 0 ... Thumbnail ( 640 x 360 ) mode
                str += "화면 전송 모드를 실행할 수 없습니다.\n";
            }

            str += "스크린 캡처 모드가 지원되기 위해서는\n";
            str += "최신 웹 브라우저로 접속한 후에 해당 기능을 사용할 수 있습니다. (Code.HC302.1)";
            console.log("(3) str => ", str);
            alert(str);
            return;
        }

        let constraints = {
            // audio: true,
            // video: BandConstraints.Screen
            audio: {
                noiseSuppression: true,
                echoCancellation: true,
            },
            video: {
                cursor: "always",           // 마우스 커서 포함 여부
                displaySurface: "monitor",  // 전체 화면 선택
                ...BandConstraints.Screen
            }
        };
        /* let constraints = {
            audio: false,
            video: {
                width: { ideal: screenBand.idealWidth, max: screenBand.maxWidth },
                height: { ideal: screenBand.idealHeight, max: screenBand.maxHeight },
                frameRate: { ideal: screenBand.idealFrameRate, max: screenBand.maxFrameRate }
            }
        }; */

        let videoStream = null;

        console.log("(★) setUpScreenDisplayStream----", constraints);
        try {
            videoStream = await navigator.mediaDevices.getDisplayMedia(constraints);
        } catch (err) {
            handleDOMException("setUpScreenDisplayStream()", err);
        } finally {
            if (videoStream !== null) {
                // audio mixer 사용 ...... by ykhan 241122
                handsUpClientInfo.sendVideoStream = videoStream;

                if (handsUpClientInfo.systemStreamSource !== null && handsUpClientInfo.isSysConnectFlag) {
                    console.log("handsUpClientInfo - ", handsUpClientInfo.systemStreamSource, handsUpClientInfo.audioMerger);
                    if (handsUpClientInfo.systemStreamSource.context === handsUpClientInfo.audioCtx) {
                        handsUpClientInfo.systemStreamSource.disconnect(handsUpClientInfo.audioMerger, 0, 0);
                        handsUpClientInfo.isSysConnectFlag = false;
                    }
                    handsUpClientInfo.systemStreamSource = null;
                }

                videoStream.getTracks().forEach((track) => {
                    console.log("videoStream track => ", track);
                    if (track.kind === "audio") {
                        handsUpClientInfo.systemStreamSource = handsUpClientInfo.audioCtx.createMediaStreamSource(videoStream.clone());
                        handsUpClientInfo.systemStreamSource.connect(handsUpClientInfo.audioMerger, 0, 0);
                        handsUpClientInfo.isSysConnectFlag = true;

                        handsUpClientInfo.sendAudioStream = handsUpClientInfo.destination.stream;

                        console.log("isMicOn - ", handsUpClientInfo.isMicOn);
                        if (handsUpClientInfo.isMicOn) gotAudioStream();
                        //gotAudioStream();
                    }
                });

                /* if (handsUpClientInfo.isP2PMode) {
                    gotVideoStream();
                } else {
                    initVideoStreamImageSendProc();
                } */

                gotVideoStream(isHandsUpInit, false);

                /* if (isHandsUpInit === true) {
                    sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                } */

                handsUpClientInfo.handsUpFunc("viewKind_screen");
            } else {
                startCanvasImageSendProc();

                if (isHandsUpInit === true) {
                    sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                }

                handsUpClientInfo.handsUpFunc("viewKind_screen");
                handsUpClientInfo.handsUpFunc("hImg_visible");

                if (handsUpClientInfo.isP2PMode) {
                    if (isHandsUpInit !== true) {
                        sendUnpublish("video");
                    }
                }

                let str = "스크린 캡처를 지원하지 않는 브라우저이기에\n";
                if (handsUpClientInfo.localStreamingMode !== 0) {
                    if (handsUpClientInfo.localStreamingMode === 1) {     // 1 ... 1명 보기 / PT / SmartTV Group mode ( 1920 x 1080 )
                        str += "WebRTC 모드를 실행할 수 없습니다.\n";
                    } else {                            // 2 ... 2명 보기 mode ( 1280 x 720 ) & 4 ... 4명 보기 / 6명 보기 / 8명 보기 / 9명 보기 mode ( 960 x 540 )
                        str += "P2P 모드를 실행할 수 없습니다.\n";
                    }
                } else {                                // 0 ... Thumbnail ( 640 x 360 ) mode
                    str += "화면 전송 모드를 실행할 수 없습니다.\n";
                }

                str += "스크린 캡처 모드가 지원되기 위해서는\n";
                str += "최신 웹 브라우저로 접속한 후에 해당 기능을 사용할 수 있습니다. (Code.HC302.2)";
                console.log("(4) str => ", str);
                alert(str);

                /* if (handsUpClientInfo.isP2PMode) {
                    if (isHandsUpInit !== true) {
                        sendUnpublish("video");
                    }

                    alert("스크린 캡처를 지원하지 않는 브라우저이기에 P2P 모드를 실행할 수 없습니다. (Code.HC302.2)");
                } */
            }
        }
    }
}

/**
 * 마이 캠용 카메라 video stream 권한 요청
 */
const setUpMyCamStream = async () => {
    if (navigator === undefined || navigator === null) return;

    let idx = handsUpClientInfo.videoInputInfo.myCamIndex;
    idx++;

    if (idx < handsUpClientInfo.videoInputInfo.list_videoInput.length) {
        handsUpClientInfo.videoInputInfo.myCamIndex = idx;
    } else {
        idx = 0;
        handsUpClientInfo.videoInputInfo.myCamIndex = idx;
    }

    const deviceId = handsUpClientInfo.videoInputInfo.list_videoInput[idx].deviceId;
    if (deviceId === undefined || deviceId === null) {
        console.log("can not use camera...! try screen mode (3)");
        return;
    }

    let constraints = {
        audio: false,
        video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
            ...BandConstraints.CameraForMyCam
        }
    };

    /* let constraints = {
        audio: false,
        video: {
            deviceId: deviceId ? { exact: deviceId } : undefined,
            width: { min: cameraBand.minWidth, ideal: cameraBand.idealWidth, max: cameraBand.maxWidth },
            height: { min: cameraBand.minHeight, ideal: cameraBand.idealHeight, max: cameraBand.maxHeight },
            frameRate: { min: cameraBand.minFrameRate, ideal: cameraBand.idealFrameRate, max: cameraBand.maxFrameRate }
        }
    }; */

    if (navigator.mediaDevices === undefined || navigator.mediaDevices === null) {
        // console.log("older browser version... can not use new API for video");
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

        if (navigator.getUserMedia) {
            navigator.getUserMedia(constraints, (videoStream) => {
                if (videoStream !== undefined && videoStream !== null) {
                    initMyCamDisplayProc(videoStream);
                    handsUpClientInfo.handsUpFunc("myCam_visible");
                } else {
                    // 이런 경우가 있을 수 있나...?ㅁ?....
                    handsUpClientInfo.videoInputInfo.isMyCamRunning = false;
                    handsUpClientInfo.videoInputInfo.myCamIndex = -1;
                    handsUpClientInfo.handsUpFunc("myCam_invisible");
                    alert("카메라를 사용할 수 없어 마이 캠을 실행할 수 없습니다. (Code.HC303.1)");
                }
            }, (err) => {
                handleLogMessage("setUpMyCamView(old)", "myCamIndex", handsUpClientInfo.videoInputInfo.myCamIndex);
                handleDOMException("setUpMyCamView(old)", err);
                handsUpClientInfo.videoInputInfo.isMyCamRunning = false;
                handsUpClientInfo.videoInputInfo.myCamIndex = -1;
                handsUpClientInfo.handsUpFunc("myCam_invisible");
                alert("카메라를 사용할 수 없어 마이 캠을 실행할 수 없습니다. (Code.HC303.2)");
            });
        } else {
            // console.log("navigator.getUserMedia...? 이런 경우가 있나요..?");
            handsUpClientInfo.videoInputInfo.isMyCamRunning = false;
            handsUpClientInfo.videoInputInfo.myCamIndex = -1;
            handsUpClientInfo.handsUpFunc("myCam_invisible");
            alert("카메라를 사용할 수 없어 마이 캠을 실행할 수 없습니다. (Code.HC303.3)");
        }
    } else {
        // console.log("can use new API browser version... for video");
        let videoStream = null;

        try {
            // getSupportedConstraints();
            videoStream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (err) {
            handleLogMessage("setUpCameraVideoStream(new)", "myCamIndex", handsUpClientInfo.videoInputInfo.myCamIndex);
            handleDOMException("setUpCameraVideoStream(new)", err);
        } finally {
            if (videoStream !== null) {
                initMyCamDisplayProc(videoStream);
                handsUpClientInfo.handsUpFunc("myCam_visible");
            } else {
                handsUpClientInfo.videoInputInfo.isMyCamRunning = false;
                handsUpClientInfo.videoInputInfo.myCamIndex = -1;
                handsUpClientInfo.handsUpFunc("myCam_invisible");
                alert("카메라를 사용할 수 없어 마이 캠을 실행할 수 없습니다. (Code.HC303.4)");
            }
        }
    }
}

/**
 * audio stream 권한 요청
 * @param {Boolean} isHandsUpInit 초기 세팅 완료 teacher에게 알리기 위해 사용
 */
const setUpMicAudioStream = async (isHandsUpInit) => {
    console.log(`setUpMicAudioStream - isHandsUpInit[${isHandsUpInit}]`);

    const deviceInfo = getAudioInputDeviceInfo();
    if (isHandsUpInit && (deviceInfo === undefined || deviceInfo === null)) {
        console.log("can not use default audioinput. (1)");
        console.log("setUpMicAudioStream - deviceInfo => ", deviceInfo);
        let store = window.hiclasstv.store;
        store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
        alert("(2) 사용 가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우저입니다. (Code.HC202.1)");
        return;
    }

    // console.log("setUpMicAudioStream - deviceInfo => ", deviceInfo);
    const deviceId = deviceInfo.deviceId;
    if (!checkEnabledAudioInput(deviceInfo.label)) {
        console.log("can not use default audioinput. (2)");
        if (isHandsUpInit !== true) {
            sendUnpublish("audio");
        }

        let store = window.hiclasstv.store;
        store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
        alert("지원하지 않는 시스템 마이크를 사용하고 있습니다. 시스템 마이크를 다시 선택하세요. (Code.HC202.2)");
        return;
    }

    // let constraints = { audio: true, video: false };  // use default audio input
    let constraints = {};
    if (deviceId === "default" || deviceId === undefined || deviceId === null) {
        constraints = {
            audio: true,
            video: false
        };  // use default audio input
    } else {
        constraints = {
            audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
            video: false
        };
    }

    if (navigator.mediaDevices === undefined || navigator.mediaDevices === null) {
        // console.log("older browser version... can not use new API for audio");
        navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;

        if (navigator.getUserMedia) {
            navigator.getUserMedia(constraints, (audioStream) => {
                if (audioStream !== undefined && audioStream !== null) {
                    // audio mixer 사용 ...... by ykhan 241122
                    handsUpClientInfo.micStreamSource = handsUpClientInfo.audioCtx.createMediaStreamSource(audioStream);
                    handsUpClientInfo.micStreamSource.connect(handsUpClientInfo.audioMerger, 0, 0);
                    handsUpClientInfo.isMicConnectFlag = true;

                    //handsUpClientInfo.sendAudioStream = audioStream;
                    handsUpClientInfo.sendAudioStream = handsUpClientInfo.destination.stream.clone();
                    gotAudioStream();
                } else {
                    // 이런 경우가 있을 수 있나..?ㅁ?....
                    if (isHandsUpInit !== true) {
                        sendUnpublish("audio");
                    }

                    let store = window.hiclasstv.store;
                    store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
                    alert("(3) 사용 가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우저입니다. (Code.HC200.1)");
                    return;
                }
            }, (err) => {
                handleDOMException("setUpMicAudioStream(old)", err);
                let store = window.hiclasstv.store;
                store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
                alert("(4) 사용 가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우저입니다. (Code.HC200.2)");
            });
        } else {
            if (isHandsUpInit !== true) {
                sendUnpublish("audio");
            }

            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
            alert("(5) 사용 가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우저입니다. (Code.HC200.3)");
            return;
        }
    } else {
        // console.log("can use new API browser version... for audio");
        let audioStream = null;

        try {
            audioStream = await navigator.mediaDevices.getUserMedia(constraints);
        } catch (err) {
            handleDOMException("setUpMicAudioStream(new)", err);
            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
        } finally {
            if (audioStream !== null) {
                // audio mixer 사용 ...... by ykhan 241122
                handsUpClientInfo.micStreamSource = handsUpClientInfo.audioCtx.createMediaStreamSource(audioStream);
                handsUpClientInfo.micStreamSource.connect(handsUpClientInfo.audioMerger, 0, 0);
                handsUpClientInfo.isMicConnectFlag = true;

                //handsUpClientInfo.sendAudioStream = audioStream;
                handsUpClientInfo.sendAudioStream = handsUpClientInfo.destination.stream.clone();
                gotAudioStream();
            } else {
                console.log("handsupClientInfo - ", handsUpClientInfo);
                if (handsUpClientInfo.destination && handsUpClientInfo.systemStreamSource) {
                    gotAudioStream();
                } else {
                    if (isHandsUpInit !== true) {
                        sendUnpublish("audio");
                    }

                    let store = window.hiclasstv.store;
                    store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "disable" }));
                    alert("(6) 사용 가능한 마이크가 없거나 마이크 기능을 제공하지 않는 브라우저입니다.  (Code.HC201.1)");
                    return;
                }
            }
        }
    }
}

/**
 * 획득한 video stream을 media server에 produce한다.
 */
const gotVideoStream = async (isHandsUpInit, isCameraStream) => {
    // console.log("gotVideoStream() --- 000");
    if (handsUpClientInfo.sendVideoStream instanceof MediaStream) {
        try {
            if (handsUpClientInfo.videoEl) {
                handsUpClientInfo.videoEl.srcObject = handsUpClientInfo.sendVideoStream;
                handsUpClientInfo.videoEl.play();

                const vTrack = handsUpClientInfo.sendVideoStream.getVideoTracks()[0];
                setUpImageSendSize(vTrack.getSettings());

                let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
                console.log(`gotVideoStream() - isHighQualityMode[${isHighQualityMode}], isCameraStream[${isCameraStream}]`);

                await produceVideo(vTrack.clone(), isHighQualityMode, isCameraStream);

                if (isHandsUpInit === true) {
                    sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
                }
            }
        } catch (err) {
            handleDOMException("gotVideoStream()", err);
        }
    }
}

/**
 * 획득한 audio stream을 media server에 produce한다.
 */
const gotAudioStream = async () => {
    // console.log("gotAudioStream() --- 000");
    //if (handsUpClientInfo.sendAudioStream instanceof MediaStream) {
    if (handsUpClientInfo.destination.stream instanceof MediaStream) {
        try {
            if (!handsUpClientInfo.isMicOn) handsUpClientInfo.isMicOn = true;

            // audio mixer 사용 .... by ykhan 241125
            if (handsUpClientInfo.systemStreamSource !== null && !handsUpClientInfo.isSysConnectFlag) {
                handsUpClientInfo.isSysConnectFlag = true;
                handsUpClientInfo.systemStreamSource.connect(handsUpClientInfo.audioMerger, 0, 0);
            }
            if (handsUpClientInfo.micStreamSource !== null && !handsUpClientInfo.isMicConnectFlag) {
                handsUpClientInfo.isMicConnectFlag = true;
                handsUpClientInfo.micStreamSource.connect(handsUpClientInfo.audioMerger, 0, 0);
            }

            handsUpClientInfo.sendAudioStream = handsUpClientInfo.destination.stream.clone(); //clone 하지 않으면.. 스트림 클리어할 때 영향받아서 안된다.. by ykhan 241212

            const track = handsUpClientInfo.sendAudioStream.getAudioTracks()[0];
            await produceAudio(track.clone());

            initHandsUpMicVolumeSendProc(handsUpClientInfo.sendAudioStream);

            let store = window.hiclasstv.store;
            store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "mute" }));
        } catch (err) {
            handleDOMException("gotAudioStream()", err);
        }
    }
}

/** clear media source */
export const clearAudio = async () => {
    // console.log("clearAudio() --- 000");
    clearMicVolumeSendProc();
    await clearStreamSource();

    handsUpClientInfo.isMuteMode = true; // mic를 끄는 경우 mute값 초기화

    if (handsUpClientInfo.sendAudioStream instanceof MediaStream) {
        console.log("clear Audio");
        handsUpClientInfo.sendAudioStream.getTracks().forEach(track => {
            track.stop();
        });

        handsUpClientInfo.sendAudioStream = null;
    }
}

export const clearVideo = () => {
    // console.log("clearVideo()---000");
    clearImgSendProc();

    if (handsUpClientInfo.videoEl !== undefined && handsUpClientInfo.videoEl !== null) {
        /* if (handsUpClientInfo.videoEl.srcObject instanceof MediaStream) {
            handsUpClientInfo.videoEl.srcObject.getTracks().forEach(track => {
                track.stop();
            });

            handsUpClientInfo.videoEl.srcObject = null;
        } */

        if (handsUpClientInfo.videoEl.srcObject !== undefined && handsUpClientInfo.videoEl.srcObject !== null) {
            handsUpClientInfo.videoEl.srcObject.getTracks().forEach(track => {
                track.stop();
            });

            handsUpClientInfo.videoEl.srcObject = null;
        }

        // handsUpClientInfo.videoEl = null;     // live page에 그대로 있는 경우가 있기 때문에 null을 해선 안된다.... by hjkim 20210215
    }

    if (handsUpClientInfo.sendVideoStream instanceof MediaStream) {
        handsUpClientInfo.sendVideoStream.getTracks().forEach(track => {
            track.stop();
        });

        handsUpClientInfo.sendVideoStream = null;
    }

    /* if (handsUpClientInfo.videoEl !== undefined && handsUpClientInfo.videoEl !== null) {
        if (handsUpClientInfo.videoEl.srcObject !== undefined && handsUpClientInfo.videoEl.srcObject !== null) {
            handsUpClientInfo.videoEl.srcObject.getTracks().forEach(track => {
                track.stop();
            });

            handsUpClientInfo.videoEl.srcObject = null;
        }

        // handsUpClientInfo.videoEl = null;     // live page에 그대로 있는 경우가 있기 때문에 null을 해선 안된다.... by hjkim 20210215
    }

    if (handsUpClientInfo.sendVideoStream !== undefined && handsUpClientInfo.sendVideoStream !== null) {
        handsUpClientInfo.sendVideoStream.getTracks().forEach(track => {
            track.stop();
        });

        handsUpClientInfo.sendVideoStream = null;
    } */
}

/**
 * 1초에 이미지 4번 보내는 process 멈춤
 */
const clearImgSendProc = () => {
    return new Promise((resolve, reject) => {
        if (handsUpClientInfo.imageSendProc !== undefined && handsUpClientInfo.imageSendProc !== null
            && handsUpClientInfo.imageSendProc.current !== undefined && handsUpClientInfo.imageSendProc.current !== null) {
            clearInterval(handsUpClientInfo.imageSendProc.current);
            handsUpClientInfo.imageSendProc.current = null;
            handsUpClientInfo.isImageProcRunning = false;
        }

        resolve();
    });
}

/**
 * mic volume 보내는 process 멈춤
 */
const clearMicVolumeSendProc = () => {
    if (handsUpClientInfo.micVolumeSendProc && handsUpClientInfo.micVolumeSendProc.current) {
        clearInterval(handsUpClientInfo.micVolumeSendProc.current);
        handsUpClientInfo.micVolumeSendProc.current = null;
    }

    if (handsUpClientInfo.audioSoundMeter !== undefined && handsUpClientInfo.audioSoundMeter !== null) {
        handsUpClientInfo.audioSoundMeter.stop();
        handsUpClientInfo.audioSoundMeter = null;
    }

    if (handsUpClientInfo.audioContext && handsUpClientInfo.audioContext.state !== "closed") {
        handsUpClientInfo.audioContext.close();
        handsUpClientInfo.audioContext = null;
    }

    handsUpClientInfo.soundLevel = -1; // mic를 끄고 다시 켤 때 soundLevel를 보내지 않는 문제로 인해 추가 by hjkim 20210104
}

/**
 * mic / stream 오디오 믹서 disconnect
 */
const clearStreamSource = async () => {
    if (handsUpClientInfo.audioCtx) {
        const audioCtx = handsUpClientInfo.audioCtx;

        if (handsUpClientInfo.micStreamSource !== null && handsUpClientInfo.audioMerger) {
            console.log("======> mic stream source = ", handsUpClientInfo.micStreamSource);
            if (handsUpClientInfo.isMicConnectFlag && handsUpClientInfo.micStreamSource.context === audioCtx) {
                if (handsUpClientInfo.micStreamSource.numberOfOutpus > 0) {
                    handsUpClientInfo.micStreamSource.disconnect(handsUpClientInfo.audioMerger, 0, 0);
                    handsUpClientInfo.isMicConnectFlag = false;
                }
            }
            //handsUpClientInfo.micStreamSource = null;
        }

        // if (handsUpClientInfo.systemStreamSource !== null && handsUpClientInfo.audioMerger) {
        //     console.log("======> sys stream source = ", handsUpClientInfo.systemStreamSource);
        //     if (handsUpClientInfo.isSysConnectFlag && handsUpClientInfo.systemStreamSource.context === audioCtx) {
        //         if (handsUpClientInfo.systemStreamSource.numberOfOutputs > 0) {
        //             handsUpClientInfo.systemStreamSource.disconnect(handsUpClientInfo.audioMerger, 0, 0);
        //             handsUpClientInfo.isSysConnectFlag = false;
        //         }
        //     }
        //     //handsUpClientInfo.systemStreamSource = null;
        // }
    }
}

/** etc */
const handleDOMException = (func, err) => {
    if (err instanceof DOMException) {
        console.log(`handleDOMException [${func}] - DOMException name[${err.name}], message: ${err.message}`);
    } else if (err instanceof Error) {
        console.log(`handleDOMException [${func}] - Error name[${err.name}], message: ${err.message}`);
    } else {
        console.log("handleDOMException err => ", err);
    }
}

const handleLogMessage = (func, name, value) => {
    console.log(`[${func}] ${name} => ${value}`);
}

const checkEnabledVideoInput = (device) => {
    if (device.label.indexOf("3D Camera") !== -1 && device.label.indexOf("Virtual Driver") !== -1) {
        return false;
    }

    /* if (device.label.indexOf("CyberLink") !== -1 && device.label.indexOf("Splitter") !== -1) {
       return false;
    } */

    /* if (device.label.indexOf("Splitter") !== -1) {
       return false;
    } */

    if (handsUpClientInfo.videoInputInfo.list_videoInput.length > 0) {
        const check_info = handsUpClientInfo.videoInputInfo.list_videoInput.find(info => info.deviceId === device.deviceId);
        if (check_info !== undefined && check_info !== null) {
            console.log("checkEnabledVideoInput() - already has this video input");
            return false;
        }
    }

    return true;
}

const checkEnabledAudioInput = (deviceLabel) => {
    if (deviceLabel !== undefined && deviceLabel !== null) {
        if (deviceLabel.indexOf("CABLE Output") !== -1 && deviceLabel.indexOf("VB-Audio") !== -1 && deviceLabel.indexOf("Virtual Cable")) {
            return false;
        }
    }

    return true;
}

const checkDefaultAudioInput = (device) => {
    if (handsUpClientInfo.audioInputInfo.defaultAudioInput.deviceId === null || handsUpClientInfo.audioInputInfo.defaultAudioInput.groupId === null) {
        return false;
    }

    if (device === undefined || device === null) {
        return false;
    }

    if (handsUpClientInfo.audioInputInfo.defaultAudioInput.deviceId === "default") {
        if (handsUpClientInfo.audioInputInfo.defaultAudioInput.groupId !== device.groupId) {
            return false;
        }
    } else { // dafult deviceId가 default가 아닌 경우... by hjkim 20220316
        if (handsUpClientInfo.audioInputInfo.defaultAudioInput.deviceId !== device.deviceId) {
            return false;
        }
    }

    if (handsUpClientInfo.audioInputInfo.defaultAudioInput.label.indexOf(device.label) === -1) {
        return false;
    }

    return true;
}

const getAudioInputDeviceInfo = () => {
    console.log("getAudioInputDeviceInfo - audioInputInfo.currentAudioInput.deviceId => ", handsUpClientInfo.audioInputInfo.currentAudioInput.deviceId);
    const currentDeviceId = handsUpClientInfo.audioInputInfo.currentAudioInput.deviceId;
    console.log("getAudioInputDeviceInfo - currentDeviceId => ", currentDeviceId);
    if (currentDeviceId === undefined || currentDeviceId === null) {
        const defaultDeviceId = handsUpClientInfo.audioInputInfo.defaultAudioInput.deviceId;
        if (defaultDeviceId === undefined || defaultDeviceId === null) {
            return { deviceId: null, groupId: null, label: null };
        } else {
            return handsUpClientInfo.audioInputInfo.defaultAudioInput;
        }
    } else {
        return handsUpClientInfo.audioInputInfo.currentAudioInput;
    }
}

/**
 * 현재 p2p mode 인지 체크하기 위해...
 * @returns {Boolean}
 */
const getIsP2PMode = () => {
    return handsUpClientInfo.isP2PMode;
}

/**
 * android 용 handsUp init done 알림
 */
export const androidHandsUpInitDone = () => {
    if (handsUpClientInfo.isAndroidDevice) {
        sendHandsUpInitDone(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);

        // 혹시라도 앱 설치 후 첫 실행이거나 권한 제거 등으로 인해 앱은 설치되어 있으나 앱 권한 재부여 이슈로 인해 timer 설정 시간이 지나서 img send process가 실행 중일 수도 있으므로 clear function 호출 ... by hjkim 20230519
        clearImgSendProc();
    }
}

/**
 * 실행중이던 timer를 clear한다.
 */
export const clearTimerProc = () => {
    if (handsUpClientInfo.timerProc !== undefined && handsUpClientInfo.timerProc !== null) {
        clearTimeout(handsUpClientInfo.timerProc);
        handsUpClientInfo.timerProc = null;
    }
}

/**
 * isAvailableAndroid 값 초기화
 */
export const initAvailableApp = () => {
    handsUpClientInfo.isAvailableApp = true;
}

/**
 * isAvailableApp 값을 리턴한다.
 * Pentalk OnScreen 앱이 현재 사용 가능한 상태인지 체크.
 */
export const getIsAvailableApp = () => {
    return handsUpClientInfo.isAvailableApp;
}

/**
 * android app 호출/사용 모드인지 확인하기 위해
 */
const checkAndroidValue = () => {
    return handsUpClientInfo.viewKind === "SCREEN" && handsUpClientInfo.isAndroidDevice && handsUpClientInfo.isAvailableApp;
}

/**
 * android device 인지 체크
 */
export const getIsAndroidDevice = () => {
    return handsUpClientInfo.isAndroidDevice;
}

export const sendMicStatus = () => {
    if (handsUpClientInfo.isMicDisable) {
        console.log("sendMicStatus case 1");
        // let store = window.hiclasstv.store;
        // store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "muteDisable" }));
        sendP2PSoundOff(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);
    } else {
        if (ConstData.IS_LOCAL_VERSION) {
            console.log("sendMicStatus case 2");
            sendP2PSoundReadyLocal(handsUpClientInfo.liveSeq, handsUpClientInfo.userSeq);

            // let store = window.hiclasstv.store;
            // store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "off" }));
        } else {
            console.log("sendMicStatus case 3");
            // let store = window.hiclasstv.store;
            // store.dispatch(changeHandsUpStatusInfo({ kind: "mic", status: "mute" }));
        }
    }
}

/**
 * android 용 app 호출시 user gesture가 필요하기에
 * handsUp이 시작되지 않았더라도 pentalk on screen app을 호출
 */
export const initialize = () => {
    // console.log(`initialize - handsUpClientInfo.viewKind[${handsUpClientInfo.viewKind}]`);
    // handsUpClientInfo.viewKind = "SCREEN"; // 임시
    if (navigator && navigator.userAgent) {
        let userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.indexOf("mobile") > -1 || userAgent.indexOf("iphone") > -1 || userAgent.indexOf("android") > -1
            || ((userAgent.indexOf("windows") > -1 || userAgent.indexOf("cros") > -1 || userAgent.indexOf("macintosh") > -1) && navigator.maxTouchPoints > 2)) {
            handsUpClientInfo.isMobile = true;
        }

        let isAndroid = userAgent.indexOf("android") > -1;
        if (isAndroid) {
            handsUpClientInfo.isAndroidDevice = true;
            handsUpClientInfo.isAvailableApp = true;
        }
    }

    if (checkAndroidValue()) {
        // console.log("initialize - call connect pentalk on screen app");
        sendStartPentalkOnScreenForAndroid(false, false, false, false);

        handsUpClientInfo.timerProc = setTimeout(() => {
            if (document.webkitHidden || document.hidden) {
                handsUpClientInfo.isAvailableApp = false;
            }

            clearTimeout(handsUpClientInfo.timerProc);
            handsUpClientInfo.timerProc = null;
        }, 2000);
    }
}

/**
 * mixed class 멤버 설정
 * @param {Boolean} isMixedClassMember 
 */
export const setIsMixedClassMember = (isMixedClassMember) => {
    console.log(`setIsMixedClassMember - isMixedClassMember[${isMixedClassMember}], handsUpClientInfo.isMixedClassMember[${handsUpClientInfo.isMixedClassMember}]`);
    handsUpClientInfo.isMixedClassMember = isMixedClassMember;
}

export const setScreenVideoResolution = (width, height) => {
    console.log(`setScreenVideoResolution - width[${width}], height[${height}]`);
    let isHighQualityMode = handsUpClientInfo.isP2PMode || handsUpClientInfo.isSmartTVMode;
    let isCameraStream = handsUpClientInfo.viewKind === "CAMERA";
}

export const getTopKind = () => {
    return handsUpClientInfo.topKind;
}

export const setConfigFPS = (lSeq, configFPS) => {
    console.log("setConfigFPS - configFPS => ", handsUpClientInfo.configFPS, ", new_configFPS => ", configFPS);
    if (handsUpClientInfo.liveSeq === lSeq) {
        if (handsUpClientInfo.configFPS !== configFPS) {
            handsUpClientInfo.configFPS = configFPS;

            if (configFPS === 0) {          // None SmartTVs + LAN
                handsUpClientInfo.configFPSKind = stTimeKind.TWELVE_FRAMES;
            } else if (configFPS === 1) {   // None SmartTVs + WiFi
                handsUpClientInfo.configFPSKind = stTimeKind.TEN_FRAMES;
            } else if (configFPS === 2) {   // Used SmartTVs + LAN
                handsUpClientInfo.configFPSKind = stTimeKind.SEVEN_FRAMES;
            } else {                        // Used SmartTVs + WiFi
                handsUpClientInfo.configFPSKind = stTimeKind.FOUR_FRAMES;
            }

            /* if (handsUpClientInfo.isAndroidDevice) {
                if (handsUpClientInfo.isAvailableApp) {
                    xmitCmd_AndroidJsonMessage("");
                }
            } */
        }
    }
}

const currentP2PMode = (p2pMode1, p2pMode2) => {
    if (p2pMode1 === enP2PMode.P1P) {
        return p2pMode1;
    } else {
        if (p2pMode1 == enP2PMode.Thumbnail) {
            if (p2pMode2 !== enP2PMode.Thumbnail) {
                return p2pMode2;
            }
        } else if (p2pMode1 === enP2PMode.P2P) {
            if (p2pMode2 === enP2PMode.P1P) {
                return p2pMode2;
            } else {
                return p2pMode1;
            }
        } else { // enP2PMode.P4P
            if (p2pMode2 === enP2PMode.P1P) {
                return p2pMode2;
            } else {
                return p2pMode1;
            }
        }
    }

    return enP2PMode.Thumbnail;
}

/**
 * Pentalk onScreen 앱 연결 이미지 설정 및 viewKind를 screen으로 설정
 * for android
 */
export const setScreenModeImage = () => {
    if (handsUpClientInfo.handsUpFunc) {
        handsUpClientInfo.handsUpFunc("viewKind_screen");
        handsUpClientInfo.handsUpFunc("android_appConnect");
        // handsUpClientInfo.handsUpFunc("mic_disable");
    }
}