import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FaExpandArrowsAlt, FaCompressArrowsAlt, FaComment, FaCamera, FaDesktop, FaPortrait } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";

import ConstData from "../../../lib/ConstData";

const LiveControlTool = ({ userNickname, performLiveInfo, performLiveStatusInfo, performHandsUpStatusInfo, classTalkVisibility, teacherViewVisibility, myCamViewVisibility, optionVisibility, publicClassVisibility, isFullScreenMode, viewKind, handleSelectedLiveFunc }) => {
    const { t } = useTranslation();

    const [teacherInfo, setTeacherInfo] = useState(performLiveInfo.teacher_info ? performLiveInfo.teacher_info : { userSeq: -1, userNickname: "" });
    // const [optionMode, setOptionMode] = useState(false);
    // const [liveSeq, setLiveSeq] = useState(performLiveInfo.liveSeq ? performLiveInfo.liveSeq : -1);
    // const [handsUpYN, setHandsUpYN] = useState(performLiveInfo.handsUpYN ? performLiveInfo.handsUpYN : "N");

    // const [isConnect, setIsConnect] = useState(performLiveStatusInfo.isConnect !== undefined && performLiveStatusInfo.isConnect !== null ? performLiveStatusInfo.isConnect : false);
    // const [myHandsUpYN, setMyHandsUpYN] = useState(performHandsUpStatusInfo.myHandsUpYN ? performHandsUpStatusInfo.myHandsUpYN : "N");
    // const [connectStatus, setConnectStatus] = useState(performHandsUpStatusInfo.connectStatus ? performHandsUpStatusInfo.connectStatus : "disable");
    // const [micStatus, setMicStatus] = useState(performHandsUpStatusInfo.micStatus ? performHandsUpStatusInfo.micStatus : "disable");

    useEffect(() => {
        if (performLiveInfo.teacher_info && performLiveInfo.teacher_info.userSeq > 0) {
            setTeacherInfo({
                userSeq: performLiveInfo.teacher_info.userSeq,
                userNickname: performLiveInfo.teacher_info.userNickname
            });
        }
    }, [performLiveInfo.teacher_info]);

    const toggleFullScreenMode = () => {
        if (!teacherViewVisibility) return;

        if (performLiveStatusInfo.isConnect) {
            handleSelectedLiveFunc({ selectedFunc: "CHANGE_VIEW_SIZE" });
        }
    }

    const toggleOptionMode = () => {

    }

    return (
        <div className="live-toolbar-area bottom small">
            <div className="toolbar-landscape">
                <div className="d-flex"
                    data-toggle="tooltip"
                    title={classTalkVisibility === "hidden" ? `${t("클래스톡", { ns: "classTalk" })} ${t("열기", { ns: "common" })}` : `${t("클래스톡", { ns: "classTalk" })} ${t("닫기", { ns: "common" })}`}
                >
                    <button type="button" className="hiclasstv-button" style={{ minWidth: '44px' }}
                            onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_CLASSTALK" })}>
                        <img
                            className="hiclasstv-live-icon"
                            src={classTalkVisibility === "hidden" ? "/images/liveTool/hideClasstalk.png" : "/images/liveTool/showClasstalk.png"}
                            alt={classTalkVisibility === "hidden" ? `${t("클래스톡", { ns: "classTalk" })} ${t("열기", { ns: "common" })}` : `${t("클래스톡", { ns: "classTalk" })} ${t("닫기", { ns: "common" })}`}
                            aria-hidden="true"
                        />
                        <span className="hiclasstv-live-text">{t("채팅방", { ns: "chat" })}</span>
                    </button>
                </div>
                <div className="d-flex"
                    data-toggle="tooltip"
                    title={teacherViewVisibility === "hidden" ? t("화면_보기", { ns: "live" }) : t("화면_닫기", { ns: "live" })}
                >
                    <button type="button" className="hiclasstv-button"
                        onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_TEACHER_VIEW" })}>
                        <img
                            className="hiclasstv-live-icon"
                            src={teacherViewVisibility === "hidden" ? "/images/liveTool/hideTeacherScreen.png" : "/images/liveTool/showTeacherScreen.png"}
                            alt={teacherViewVisibility === "hidden" ? t("화면_보기", { ns: "live" }) : t("화면_닫기", { ns: "live" })}
                            aria-hidden="true"
                        />
                        <span className="hiclasstv-live-text">{t("화면숨김", { ns: "live" })}</span>
                    </button>
                </div>
                <div className="d-flex"
                    data-toggle="tooltip"
                    title={isFullScreenMode ? t("전체화면_종료", { ns: "live" }) : t("전체화면", { ns: "live" })}
                >
                    <button type="button" className="hiclasstv-button" style={{ minWidth: '44px' }}
                        /* data-toggle="tooltip" */
                        /* title={isFullScreenMode ? "전체화면 종료" : "전체화면"} */
                        disabled={!performLiveStatusInfo.isConnect || teacherViewVisibility === "hidden"}
                        onClick={() => toggleFullScreenMode()}>
                        {
                            /* (!performLiveStatusInfo.isConnect || teacherViewVisibility === "hidden") ?
                                <FaExpandArrowsAlt className="font-ml" style={{ color: 'rgb(158, 158, 158)' }} aria-hidden="true" title="전체화면" /> :
                                isFullScreenMode ?
                                    <FaCompressArrowsAlt className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="전체화면 종료" /> :
                                    <FaExpandArrowsAlt className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="전체화면" /> */
                            performLiveStatusInfo.isConnect && isFullScreenMode && teacherViewVisibility !== "hidden" ?
                                <img
                                    className="hiclasstv-live-icon"
                                    src="/images/liveTool/minScreen.png"
                                    alt={t("전체화면_종료", { ns: "live" })}
                                    aria-hidden="true"
                                /> :
                                <img
                                    className="hiclasstv-live-icon"
                                    src={(!performLiveStatusInfo.isConnect || teacherViewVisibility === "hidden") ? "/images/liveTool/maxScreenDisable.png" : "/images/liveTool/maxScreen.png"}
                                    alt={t("전체화면", { ns: "live" })}
                                    aria-hidden="true"
                                /> 
                        }
                        <span className="hiclasstv-live-text">{t("화면전환", { ns: "live" })}</span>
                    </button>
                </div>
                <div className="d-flex"
                    data-toggle="tooltip"
                    title={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? t("음성_켜기", { ns: "live" }) : t("음성_끄기", { ns: "live" })}
                >
                    <button type="button" className="hiclasstv-button" disabled={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "muteDisable"} onClick={() => handleSelectedLiveFunc({ selectedFunc: "PROC_MIC" })}>
                        {
                            /* performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ?
                                <img src="/images/liveTool/micOffDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 켜기" alt="음성 켜기" /> :
                                // performHandsUpStatusInfo.micStatus === "off" ?
                                //     <img src="/images/liveTool/micOffDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 켜기" alt="음성 켜기" /> :
                                performHandsUpStatusInfo.micStatus === "mute" ?
                                    <img src="/images/liveTool/micOn.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 끄기" alt="음성 끄기" /> :
                                    <img src="/images/liveTool/micOnDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="음성 끄기" alt="음성 끄기" /> */
                        }
                        <img
                            className="hiclasstv-live-icon"
                            src={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? "/images/liveTool/micOffDisable.png" : performHandsUpStatusInfo.micStatus === "mute" ? "/images/liveTool/micOn.png" : "/images/liveTool/micOnDisable.png"}
                            /* title={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? "음성 켜기" : "음성 끄기"} */
                            alt={performHandsUpStatusInfo.micStatus === "disable" || performHandsUpStatusInfo.micStatus === "off" ? t("음성_켜기", { ns: "live" }) : t("음성_끄기", { ns: "live" })}
                            aria-hidden="true"
                        />
                        <span className="hiclasstv-live-text">{t("학생소리", { ns: "live" })}</span>
                    </button>
                </div>
                <div className="d-flex"
                    data-toggle="tooltip"
                    title={(performHandsUpStatusInfo.connectStatus === "disable" || performHandsUpStatusInfo.connectStatus === "disconnect") ? t("연결하기", { ns: "live" }) : t("연결중", { ns: "live" })}
                >
                    <button type="button" className="hiclasstv-button" disabled={(performLiveInfo.handsUpYN !== "Y" && performHandsUpStatusInfo.connectStatus === "disable")} onClick={() => handleSelectedLiveFunc({ selectedFunc: "PROC_CONN" })}>
                        {
                            /* performHandsUpStatusInfo.connectStatus === "disable" ?
                                <img src="/images/liveTool/disconnectDisable.png" aria-hidden="true" className="hiclasstv-live-icon" title="연결하기" alt="연결하기" /> :
                                performHandsUpStatusInfo.connectStatus === "disconnect" ?
                                    <img src="/images/liveTool/disconnect.png" aria-hidden="true" className="hiclasstv-live-icon" title="연결하기" alt="연결하기" /> :
                                    <img src="/images/liveTool/connectAble.png" aria-hidden="true" className="hiclasstv-live-icon" title="연결중" alt="연결중" /> */
                        }
                        <img
                            className="hiclasstv-live-icon"
                            src={performHandsUpStatusInfo.connectStatus === "disable" ? "/images/liveTool/disconnectDisable.png" : performHandsUpStatusInfo.connectStatus === "disconnect" ? "/images/liveTool/disconnect.png" : "/images/liveTool/connectAble.png"}
                            /* title={(performHandsUpStatusInfo.connectStatus === "disable" || performHandsUpStatusInfo.connectStatus === "disconnect") ? "연결하기" : "연결중"} */
                            alt={(performHandsUpStatusInfo.connectStatus === "disable" || performHandsUpStatusInfo.connectStatus === "disconnect") ? t("연결하기", { ns: "live" }) : t("연결중", { ns: "live" })}
                            aria-hidden="true"
                        />
                        <span className="hiclasstv-live-text">{t("연결상태", { ns: "live" })}</span>
                    </button>
                </div>
                <div className="d-flex"
                    data-toggle="tooltip"
                    title={(performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") || viewKind === "CAMERA" ? t("스크린으로_전환", { ns: "live" }) : t("카메라로_전환", { ns: "live" })}
                >
                    <button id="btnChangeMicStatus" type="button" className="hiclasstv-button" style={{ minWidth: '44px' }} disabled={performLiveInfo.handsUpYN !== "Y"} onClick={() => handleSelectedLiveFunc({ selectedFunc: "CHANGE_VIEW_KIND" })}>
                        {
                            /* (performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") ?
                                <FaCamera className="font-ml" style={{ color: 'rgb(158, 158, 158)' }} aria-hidden="true" title="스크린으로 전환" /> :
                                viewKind === "CAMERA" ?
                                    <FaCamera className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="스크린으로 전환" /> :
                                    <FaDesktop className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="카메라로 전환" /> */
                            (performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") || viewKind === "CAMERA" ?
                                <img
                                    className="hiclasstv-live-icon"
                                    src={(performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") ? "/images/liveTool/transCamDisable.png" : "/images/liveTool/transCam.png"}
                                    alt={t("스크린으로_전환", { ns: "live" })}
                                    aria-hidden="true"
                                /> : 
                                <img
                                    className="hiclasstv-live-icon"
                                    src="/images/liveTool/screenMode.png"
                                    alt={t("스크린으로_전환", { ns: "live" })}
                                    aria-hidden="true"
                                />
                                /*<FaCamera className="font-ml" style={{ color: (performLiveInfo.liveSeq === -1 || performLiveInfo.handsUpYN === "N") ? 'rgb(158, 158, 158)' : 'rgb(55, 230, 71)' }} alt={t("스크린으로_전환", { ns: "live" })} aria-hidden="true" /> :
                                    <FaDesktop className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} alt={t("카메라로_전환", { ns: "live" })} aria-hidden="true" />*/
                        }
                        <span className="hiclasstv-live-text">{t("공유선택", { ns: "live" })}</span>
                    </button>
                </div>
                {
                    /* (performLiveInfo.handsUpYN === "Y" && lHandsUpVisibility === "visible") &&
                    <div className="d-flex"
                        data-toggle="tooltip"
                        title="저요저요"
                    >
                        <button type="button" className="d-flex hiclasstv-button hiclasstv-grey rounded ml-1 p-2" onClick={() => handleSelectedLiveFunc({ selectedFunc: "HANDS_UP" })}>
                            <FaHandPaper className={"font-md" + (performHandsUpStatusInfo.myHandsUpYN === "Y" ? " hiclasstv-yellow-text icon-gradation" : " white-text")} alt="저요저요" aria-hidden="true" />
                        </button>
                    </div> */
                }
                {
                    performLiveInfo.liveSeq > 0 && performLiveInfo.handsUpYN === "Y" &&
                    <div className="d-flex"
                        data-toggle="tooltip"
                        title={(viewKind === "CAMERA" || myCamViewVisibility === "none") ? t("마이_캠_켜기", { ns: "live" }) : t("마이_캠_끄기", { ns: "live" })}
                    >
                        <button id="btnChangeViewKind" type="button" className="hiclasstv-button" style={{ minWidth: '44px' }} disabled={viewKind !== "SCREEN"} onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_MYCAM_VIEW" })}>
                            {
                                /* viewKind !== "SCREEN" ?
                                    <FaPortrait className="font-ml" style={{ color: 'rgb(158, 158, 158)' }} aria-hidden="true" title="마이 캠 켜기" /> :
                                    myCamViewVisibility === "none" ?
                                        <FaPortrait className="font-ml" style={{ color: 'rgb(255, 0, 0)' }} aria-hidden="true" title="마이 캠 켜기" /> :
                                        <FaPortrait className="font-ml" style={{ color: 'rgb(55, 230, 71)' }} aria-hidden="true" title="마이 캠 끄기" /> */
                            }
                            <img
                                className="hiclasstv-live-icon"
                                src={(viewKind === "CAMERA") ? "/images/liveTool/myCamDisable.png" : (myCamViewVisibility === "none") ? "/images/liveTool/myCamDisable.png" : "/images/liveTool/myCam.png"}
                                alt={(viewKind === "CAMERA" || myCamViewVisibility === "none") ? t("마이_캠_켜기", { ns: "live" }) : t("마이_캠_끄기", { ns: "live" })}
                                aria-hidden="true"
                            />
                            <span className="hiclasstv-live-text">{t("마이캠", { ns: "live" })}</span>
                        </button>
                    </div>
                }
                <div className="d-flex align-items-center w-100">
                    {
                        performLiveInfo.liveSeq !== -1 &&
                        <div className="d-flex align-items-center" style={{ minWidth: '1%', width: '100%', maxWidth: 'max-content' }}>
                            <span className="web-list-text w-100 px-1" title={`[${t("진행자", { ns: "live" })}] ${teacherInfo.userNickname}`}><b>{teacherInfo.userNickname}&nbsp;&nbsp;</b></span>|
                        </div>
                    }
                    <div className="d-flex" style={{ minWidth: '10%', width: '100%', maxWidth: 'max-content', visibility: 'visible' }}>
                        <span className="web-list-text w-100 px-1" title={`[${t("나", { ns: "common" })}] ${userNickname}`}>{userNickname}</span>
                    </div>
                </div>
                {
                    ConstData.IS_LOCAL_VERSION &&
                    <div className="d-flex"
                        data-toggle="tooltip"
                        style={{ position: 'absolute', right: optionVisibility === "flex" ? 60 : 0 }}>
                        <button type="button" className="hiclasstv-button" style={{ minWidth: '44px', minHeight: '44px' }}
                            onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_OPTION_VIEW" })}>
                            <BsThreeDots className="font-md plus" />
                            <span className="hiclasstv-live-text">{t("더보기", { ns: "common" })}</span>
                        </button>
                    </div>
                }
                {
                    optionVisibility === "flex" &&
                    <div className="d-flex"
                        data-toggle="tooltip"
                        style={{ position: 'absolute', right: 0 }}
                        title={publicClassVisibility === "hidden" ? `${t("수업_음성", { ns: "live" })} ${t("수업_음성_끄기", { ns: "live" })}` : `${t("수업_음성", { ns: "live" })} ${t("수업_음성_켜기", { ns: "live" })}`}
                    >
                        <button type="button" className="hiclasstv-button" style={{ minWidth: '44px' }}
                            onClick={() => handleSelectedLiveFunc({ selectedFunc: "TOGGLE_PUBLIC_CLASS" })}>
                            <img
                                className="hiclasstv-live-icon"
                                src={publicClassVisibility === "hidden" ? "/images/liveTool/green_soundOn.png" : "/images/liveTool/disable_soundOn.png"}
                                alt={publicClassVisibility === "hidden" ? `${t("수업_음성", { ns: "live" })} ${t("수업_음성_끄기", { ns: "live" })}` : `${t("수업_음성", { ns: "live" })} ${t("수업_음성_켜기", { ns: "live" })}`}
                                aria-hidden="true"
                            />
                            <span className="hiclasstv-live-text">{t("수업_음성", { ns: "live" })}</span>
                        </button>
                    </div>
                }
            </div>

            {/* 가로로 화면 전환하라는 안내문구 띄우기 */}
            <div className="toolbar-portrait">
                <div className="d-flex red justify-content-center w-100 px-1">
                    <span className="white-text">※ {t("채팅", { ns: "chat" })}{performLiveStatusInfo.isConnect ? t("세로모드_안내_문구_1", { ns: "live" }) : ""}{t("세로모드_안내_문구_2", { ns: "live" })}</span>
                </div>
                <div className="d-flex align-items-center">
                    {
                        performLiveInfo.liveSeq !== -1 &&
                        <div className="d-flex align-items-center" style={{ minWidth: '1%', width: '100%', maxWidth: 'max-content' }}>
                            <span className="web-list-text w-100 px-1" title={`[${t("진행자", { ns: "live" })}] ${teacherInfo.userNickname}`}><b>{teacherInfo.userNickname}&nbsp;&nbsp;</b></span>|
                        </div>
                    }
                    <div className="d-flex" style={{ minWidth: '10%', width: '100%', maxWidth: 'max-content', visibility: 'visible' }}>
                        <span className="web-list-text w-100 px-1" title={`[${t("나", { ns: "common" })} ] ${userNickname}`}>{userNickname}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LiveControlTool;