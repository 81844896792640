import axiosClient from "./axiosClient";
import ConstData from '../ConstData';
import https from 'https';

let options = {};

if (ConstData.IS_LOCAL_VERSION) {
    options = {
        httpsAgent: new https.Agent({
            rejectUnauthorized: false, //허가되지 않은 인증을 reject하지 않겠다!
        })
    }
}

export const get_all_pass_list = ({ kind }) => {
    if (kind === 'b2c') {
        return axiosClient.get(`/pass/prdt?kind=pass-list`, options);
    } else {
        return axiosClient.get(`/pass/prdt?kind=subDomain-pass-list`, options);
    }
};

export const get_prdt_pass_list = () => {
    return axiosClient.get(`/pass/prdt?kind=pass-src`, options);
};

export const get_pchs_pass_list = ({ term, kindCode, subDomainSeq, userSeq, selUserSeq }) => {
    if (selUserSeq === undefined || selUserSeq === null) { // B2B case
        return axiosClient.get(`/pass/pchs/${subDomainSeq}?kind=pchs&term=${term}&kindType=${kindCode}`, options);
    } else {    // B2C case
        return axiosClient.get(`/pass/pchs/1?kind=pchs&term=${term}&kindType=${kindCode}&selUserSEQ=${selUserSeq}`, options);
    }
};

export const get_pchs_pass_list_with_manage_info = ({ term, kindCode, subDomainSeq, userSeq, selUserSeq }) => {
    return axiosClient.get(`/pass/pchs/${subDomainSeq}?kind=manage-pchs&term=${term}&kindType=${kindCode}&userSEQ=${userSeq}`, options);
};

export const add_prdt_pass = ({ prdt_info, prdtName, prdtKind, prdtTarget, prdtScale, prdtTerm, prdtDesc, prdtSvcSEQ, prdtPrice }) => {
    console.log("call api add pass product - ", prdt_info);
    const data = {};
    data.prdtName = prdt_info.passName;
    data.prdtKind = prdt_info.kindCode;
    data.prdtTarget = prdt_info.targetCode;
    data.prdtScale = prdt_info.scaleCode;
    data.prdtTerm = prdt_info.termCode;

    if (prdt_info.prdtSvcSEQ >= 0) data.prdtSvcSEQ = prdt_info.prdtSvcSEQ;
    if (prdt_info.prdtDesc && prdt_info.prdtDesc !== "") data.prdtDesc = prdt_info.prdtDesc;
    if (prdt_info.prdtPrice && prdt_info.prdtPrice !== "") data.prdtPrice = prdt_info.prdtPrice;

    return axiosClient.post(`/pass/prdt`, data, options);
};

export const regist_b2b_pass = ({ productSeq, subDomainSeq, registUserSeq, pchsOfficeType }) => {
    return axiosClient.post(`/pass/pchs`, {
        prdtSEQ: productSeq,
        subDomainSEQ: subDomainSeq,
        registUserSEQ: registUserSeq,
        pchsOfficeType
    }, options);
};

export const regist_b2c_pass = ({ productSeq, subDomainSeq, registUserSeq, passUserSeq, pchsOfficeType }) => {
    return axiosClient.post(`/pass/pchs`, {
        prdtSEQ: productSeq,
        subDomainSEQ: subDomainSeq,
        pchsUserSEQ: passUserSeq,
        registUserSEQ: registUserSeq,
        pchsOfficeType
    }, options);
};

export const use_pass = ({ pchsSeq, useDateInfo }) => {
    console.log("use_pass - pchsSeq => ", pchsSeq, ", useDateInfo => ", useDateInfo);
    let startUseDt = useDateInfo.startUseDT.replace(/[^0-9]/g, '');
    let endUseDt = useDateInfo.endUseDT.replace(/[^0-9]/g, '');
    console.log("use_pass - startUseDt -> ", startUseDt);
    console.log("use_pass - endUseDT -> ", endUseDt);

    return axiosClient.put(`/pass/pchs?kind=use-pass`, {
        pchsSEQ: pchsSeq,
        startUseDate: startUseDt
    }, options);
};

export const refund_pass = ({ pchsSeq }) => {
    return axiosClient.put(`/pass/pchs?kind=refund-pass`, {
        pchsSEQ: pchsSeq
    }, options);
};

export const partial_refund_pass = ({ pchsSeq, price }) => {
    return axiosClient.put(`/pass/pchs?kind=p-refund-pass`, {
        pchsSEQ: pchsSeq,
        cancelPrice: price
    }, options);
};