import { getSendToMQBytes } from "./classTalk/EncodeDecode";
import { enTokenCMD, enTabStatus, ctRcvKind } from "./classTalk/CodeEnum";
import { enRbmqError, enWebPage } from "./ConstCommand";

import ConstCode from "./ConstCode";
import ConstData from "./ConstData";

import {
    getDefaultDirectKey,
    getHandsUpDirectKey,
    getHandsUpSendKey,
    getHandsUpBindKey,
    getOtherDirectKey,
    getLocalServerKey
} from "./rbmq/rbmqKey";

import {
    rawDataWithRoutingKey
} from "./rbmq/rcvMsgFromRbmq";

import {
    androidHandsUpInitDone,
    getIsAndroidDevice,
    initAvailableApp,
    sendMicStatus
} from "./HandsUpClient";

import {
    initClientInfo,
    initClientInfoForGroupSmartTV,
    initClientInfoForSmartTV,
    initLiveInfo,
    sendJoinConsume,
    unsubscribe
} from "./MediaSoupClient";

import {
    WebClient,
    Message
} from "./WebSocketClient";

/* import {
    WebClient,
    Message
} from "./WebSocketClient copy"; */

import {
    rbmqServerConnected,
    rbmqServerDisconnected,
    rbmqServerError
} from "../modules/user";

import {
    changeHandsUpStatusInfo, requestJoinMixedClassLive
} from "../modules/live";

import {
    connectedOnScreenApp,
    connectedOnScreenAppArr,
    disconnectedOnScreenApp,
    disconnectedOnScreenAppArr
} from "../modules/hiclasstv";

let rbmqInfo = null;

let rbmqClient = null;
let rbmqClientID = null;

let rcvUserSeq;
let myUserSeq = -1;
let myGroupSeq = -1;
let handsUpRoutingIdx = -1;
let groupRoutingIdx = -1;
let routingIdx = -1;
let myRoutingKey = "";
let myRoutingKeyArr = [];
let myPenCamServiceKey = null;
let myPenCamServiceKeyArr = [];
let isMediaSoupVersion = false;      // 20250402 ... false 임시 for real commit 용 ... by hjkim
// let isMediaSoupVersion = true;      // 20250320 ... mediasoup 지원 버전 체크 변수 추가 ... by hjkim
let isGuestMember = false;
let isNeedSendUnsubscribe = true;
let isNeedSendSubscribe = false;
let isAlivePenCamService = false;
let isRunForSmartPen = false;
let isClickConnAppBtn = false;
let isSmartTVMode = false;
let smartTVTempKey = null;
let smartTVInfo = {
    seq: -1,
    liveSeq: -1,
    teacherSeq: -1,
    bindKey: null,
    bindKeyArr: [],
    teacherKey: null,
    userSeq: -1,
    userNickname: null,
    list_member: [],
    isSend: false,
};

let currentPageType = enWebPage.Home;

let myHandsUpSendKey = null;
let myHandsUpBindKeyArr = [];
let myTeacherDirectKey = null;

let mySmallGroupSendKey = null;
let mySmallGroupBindKeyArr = [];
let mySmallGroupImageSendKey = null;
let mySmallGroupImageBindKey = null;

let myTempBindKey = null;
let myLocalServerBindKey = null;
let localServerSendData = null;

let routingKey = "soup.server";

let consumeInfo = {
    imgSrc: null,
    startButton: null,
    smartTVImageProcess: null
};

let pingInterval = null;

export const setRbmqInfo = async (userSeq, rbmq_info, userLoginPageType) => {
    initAvailableApp();
    isAlivePenCamService = false;
    isGuestMember = false;
    myUserSeq = userSeq;
    myPenCamServiceKey = getDefaultDirectKey(userSeq, 3);
    myRoutingKey = getDefaultDirectKey(userSeq, 0);
    initClientInfo(userSeq, myRoutingKey);
    rbmqInfo = rbmq_info;

    currentPageType = userLoginPageType;

    try {
        await connectRbmq();
    } catch (err) {
        console.log("setRbmqInfo-connectRbmq() error => ", err);
    }
}

export const setRbmqInfoForGuest = async (userSeq, liveSeq, rbmq_info) => {
    initAvailableApp();
    isAlivePenCamService = false;
    isGuestMember = true;
    myUserSeq = userSeq;
    myPenCamServiceKey = null;
    myRoutingKey = getOtherDirectKey(userSeq, "G", liveSeq);
    initClientInfo(userSeq, myRoutingKey);
    rbmqInfo = rbmq_info;

    currentPageType = enWebPage.Home;

    try {
        await connectRbmq();
    } catch (err) {
        console.log("setRbmqInfoForGuest-connectRbmq() error => ", err);
    }
}

export const setRbmqInfoForMixedClass = async (/* userSeq,  */tempBindKey, rbmq_info, mixedClass_info) => {
    initAvailableApp();
    isAlivePenCamService = false;
    isGuestMember = false;
    // myUserSeq = userSeq;
    myPenCamServiceKey = null;
    myTempBindKey = tempBindKey;
    myLocalServerBindKey = getLocalServerKey(mixedClass_info.localServerSeq);
    localServerSendData = mixedClass_info;
    rbmqInfo = rbmq_info;

    currentPageType = enWebPage.MixedClassLogin;

    try {
        await connectRbmqForMixedClass();
    } catch (err) {
        console.log("setRbmqInfoForMixedClass-connectRbmqForMixedClass() error => ", err);
    }
}

export const setRbmqInfoForSmartTV = async (userSeq, userNickname, rbmq_info, tempKey, live_info) => {
    initAvailableApp();
    isSmartTVMode = true;
    isAlivePenCamService = false;
    isGuestMember = false;
    smartTVTempKey = tempKey;
    smartTVInfo.userSeq = userSeq;
    smartTVInfo.userNickname = userNickname;
    smartTVInfo.liveSeq = live_info.liveSeq;
    smartTVInfo.teacherSeq = live_info.teacher_info.userSeq;
    smartTVInfo.list_member = [
        { userSeq, userNickname }
    ];
    smartTVInfo.isSend = false;
    myUserSeq = userSeq;
    myPenCamServiceKey = getDefaultDirectKey(userSeq, 3);
    myRoutingKey = getDefaultDirectKey(userSeq, 4);
    initClientInfoForSmartTV(userSeq, myRoutingKey);
    rbmqInfo = rbmq_info;

    currentPageType = enWebPage.SmartTV;

    try {
        await connectRbmqForSmartTV();
    } catch (err) {
        console.log("setRbmqInfoForSmartTV-connectRbmq() error => ", err);
    }
}

export const setRbmqInfoForGroupSmartTV = async (group_info, rbmq_info, tempKey, live_info) => {
    initAvailableApp();
    isSmartTVMode = true;
    isAlivePenCamService = false;
    isGuestMember = false;
    smartTVTempKey = tempKey;
    smartTVInfo.userSeq = group_info.groupSeq;
    smartTVInfo.userNickname = group_info.groupName;
    smartTVInfo.liveSeq = live_info.liveSeq;
    smartTVInfo.teacherSeq = live_info.teacher_info.userSeq;
    smartTVInfo.list_member = group_info.list_member ? group_info.list_member : [];
    smartTVInfo.isSend = false;
    myUserSeq = group_info.groupSeq;
    myGroupSeq = group_info.groupSeq;
    routingIdx = -1;
    rbmqInfo = rbmq_info;

    currentPageType = enWebPage.GroupSmartTV;

    // console.log(group_info);

    if (group_info.list_member) {
        for (let i = 0; i < group_info.list_member.length; i++) {
            let myPenCamServiceKey = getDefaultDirectKey(group_info.list_member[i].userSeq, 3);
            let myRoutingKey = getDefaultDirectKey(group_info.list_member[i].userSeq, 4);

            // console.log(`i[${i}], myRoutingKey[${myRoutingKey}], myPenCamServiceKey[${myPenCamServiceKey}]`);

            myPenCamServiceKeyArr.push(myPenCamServiceKey);
            myRoutingKeyArr.push(myRoutingKey);
        }
    }

    // console.log("myRoutingKeyArr => ", myRoutingKeyArr);
    // myPenCamServiceKey = getDefaultDirectKey(group_info.groupSeq, 3);
    // myRoutingKey = getDefaultDirectKey(group_info.groupSeq, 4);
    initClientInfoForGroupSmartTV(group_info.groupSeq, group_info.list_member, myRoutingKeyArr);

    try {
        await connectRbmqForGroupSmartTV();
    } catch (err) {
        console.log("setRbmqInfoForSmartTV-connectRbmq() error => ", err);
    }
}

export const initSmartTVInfo = (live_info) => {
    // console.log("initSmartTVInfo - ", live_info);
    if (live_info) {
        smartTVInfo.liveSeq = live_info.liveSeq;
        smartTVInfo.teacherSeq = live_info.teacher_info.userSeq;
    }
}

export const subscribeLoginForMixedClass = async (userSeq, liveSeq) => {
    // let store = window.hiclasstv.store;
    // const { user } = store.getState();
    // const { isRbmqServerConnected } = user;

    if (rbmqClient) {
        try {
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribeTempKeyForMixedClass,
                onFailure: onFailureUnsubscribe
            };

            console.log(`subscribeLoginForMixedClass - myTempBindKey => `, myTempBindKey);
            if (myTempBindKey && myTempBindKey !== "") {
                rbmqClient.unsubscribe(myTempBindKey, unsubsribeOptions);
            } else {
                console.log("subscribeLoginForMixedClass() - myTempBindKey => ", myTempBindKey);
                window.alert("subscribeLoginForMixedClass() - myTempBindKey => " + myTempBindKey);
            }
        } catch (err) {
            console.log("------subscribeLoginForMixedClass unsubscribe------ error => ", err);
        } finally {
            initAvailableApp();
            isAlivePenCamService = false;
            myUserSeq = userSeq;
            // myPenCamServiceKey = getDefaultDirectKey(userSeq, 3);
            myPenCamServiceKey = getOtherDirectKey(userSeq, 3, liveSeq); // cloud 의 기존 user 와 겹치기 않기 위해 수정 by hjkim 20230404
            myRoutingKey = getOtherDirectKey(userSeq, "M", liveSeq);
            initClientInfo(userSeq, myRoutingKey);

            /* let rbmqSubscribeOptions = {
                qos: 1,
                onSuccess: onSuccessSubscribe,
                onFailure: onFailureSubscribe
            };

            console.log(`subscribeLoginForMixedClass - myRoutingKey => `, myRoutingKey);
            if (myRoutingKey && myRoutingKey !== "") {
                rbmqClient.subscribe(myRoutingKey, rbmqSubscribeOptions);
            } else {
                console.log("subscribeLoginForMixedClass() - myRoutingKey => ", myRoutingKey);
                window.alert("subscribeLoginForMixedClass() - myRoutingKey => " + myRoutingKey);
            } */
        }
    }
}

export const connectSmallGroup = (sendKey, bindKeyArr) => {
    console.log(`connectSmallGroup - sendKey[${sendKey}], bindKeyArr[${bindKeyArr}]`);
    try {
        groupRoutingIdx = -1;
        setMySmallGroupInfo(sendKey, bindKeyArr);
        joinSmallGroupRoom(bindKeyArr);
    } catch (err) {
        console.log("connectSmallGroup-joinSmallGroup() error => ", err);
    } finally {
        // console.log("connectSmallGroup-joinSmallGroup() finish");
    }
}

export const disconnectSmallGroup = () => {
    if (mySmallGroupBindKeyArr !== null && mySmallGroupBindKeyArr && mySmallGroupBindKeyArr.length > 0) {
        try {
            leaveSmallGroupRoom(mySmallGroupBindKeyArr);
        } catch (err) {
            console.log("disconnectSmallGroup-leaveSmallGroupRoom() error => ", err);
        } finally {
            setMySmallGroupInfo(null, []);
        }
    }
}

export const connectLive = (rcvSeq, liveSeq, userGateWay) => {
    console.log("connectLive - rcvSeq, liveSeq, userGateWay => ", rcvSeq, liveSeq, userGateWay);
    rcvUserSeq = rcvSeq;
    myTeacherDirectKey = getHandsUpDirectKey(rcvSeq, liveSeq, myUserSeq, 0);

    if (userGateWay !== undefined && userGateWay !== null && userGateWay !== "") {
        routingKey = userGateWay;
    } else {
        routingKey = "soup.server";
    }

    handsUpRoutingIdx = -1;

    let sendKey = getHandsUpSendKey(rcvUserSeq, liveSeq, myUserSeq);
    let bindKeyArr = getHandsUpBindKey(rcvUserSeq, myUserSeq);

    initLiveInfo(rcvSeq, liveSeq);
    setMyLiveInfo(sendKey, bindKeyArr);

    /* if (getIsAlivePenCamService()) {
        xmitCmd_AndroidMessage("ct_LiveOn", enTokenCMD.NULL);
    } */

    try {
        isNeedSendSubscribe = true;
        joinRoom(bindKeyArr, liveSeq);
    } catch (err) {
        console.log("connectLive-joinRoom() error => ", err);
    } finally {
        // console.log("connectLive-joinRoom() finish");
    }
}

export const disconnectLive = () => {
    /* if (getIsAlivePenCamService()) {
        xmitCmd_AndroidMessage("ct_LiveOff", enTokenCMD.NULL);
    } */

    if (myHandsUpBindKeyArr !== null && myHandsUpBindKeyArr.length > 0) {
        try {
            leaveRoom(myHandsUpBindKeyArr);
        } catch (err) {
            console.log("disconnectLive-leaveRoom() error => ", err);
        } finally {
            setMyLiveInfo(null, []);
        }
    }
}

export const disconnectLiveForQRLogin = () => {
    if (myHandsUpBindKeyArr !== null && myHandsUpBindKeyArr.length > 0) {
        try {
            isNeedSendUnsubscribe = false;
            leaveRoom(myHandsUpBindKeyArr);
        } catch (err) {
            console.log("disconnectLive-leaveRoom() error => ", err);
        } finally {
            setMyLiveInfo(null, []);
        }
    }
}

export const disconnectLiveForMixedLogin = () => {
    if (myHandsUpBindKeyArr !== null && myHandsUpBindKeyArr.length > 0) {
        try {
            isNeedSendUnsubscribe = false;
            leaveRoom(myHandsUpBindKeyArr);
        } catch (err) {
            console.log("disconnectLive-leaveRoom() error => ", err);
        } finally {
            setMyLiveInfo(null, []);
        }
    }
}

export const connectLiveForSmartTV = (bindKeyArr, startSeqOfSmartTV, userGateWay) => {
    console.log(`connectLiveForSmartTV => `, bindKeyArr, startSeqOfSmartTV, userGateWay);
    try {
        smartTVInfo.seq = startSeqOfSmartTV;
        smartTVInfo.bindKeyArr = bindKeyArr;
        smartTVInfo.bindKey = bindKeyArr[1];

        if (userGateWay !== undefined && userGateWay !== null && userGateWay !== "") {
            routingKey = userGateWay;
        } else {
            routingKey = "soup.server";
        }

        initClientInfo(smartTVInfo.seq, smartTVInfo.bindKeyArr[1]);
        initLiveInfo(smartTVInfo.teacherSeq, smartTVInfo.liveSeq);

        if (rbmqClient) {
            let unsubsribeOptions = {
                timeout: 3,
                // invocationContext: { nextKey: bindKeyArr[1] },
                onSuccess: onSuccessUnsubscribeTempKeyForSmartTV,
                onFailure: onFailureUnsubscribeTempKeyForSmartTV
            };

            if (smartTVTempKey && smartTVTempKey !== "") {
                rbmqClient.unsubscribe(smartTVTempKey, unsubsribeOptions);
            } else {
                console.log("connectLiveForSmartTV() - smartTVTempKey => ", smartTVTempKey);
                window.alert("connectLiveForSmartTV() - smartTVTempKey => " + smartTVTempKey);
            }
        }
    } catch (err) {
        console.log("connectLiveForSmartTV error => ", err);
    }
}

export const disconnectLiveForSmartTV = () => {
    if (smartTVInfo.bindKeyArr && smartTVInfo.bindKeyArr.length > 0) {
        try {
            // unsubscribe(false, true, true);

            if (rbmqClient) {
                let unsubsribeOptions = {
                    timeout: 3,
                    invocationContext: { nextKey: smartTVInfo.bindKeyArr[1] },
                    onSuccess: onSuccessUnsubscribeLiveForSmartTV,
                    onFailure: onFailureUnsubscribeLiveForSmartTV
                };

                if (smartTVInfo.bindKeyArr[0] && smartTVInfo.bindKeyArr[0] !== "") {
                    rbmqClient.unsubscribe(smartTVInfo.bindKeyArr[0], unsubsribeOptions);
                } else {
                    console.log("disconnectLiveForSmartTV() - smartTVInfo.bindKeyArr[0] => ", smartTVInfo.bindKeyArr[0]);
                    window.alert("disconnectLiveForSmartTV() - smartTVInfo.bindKeyArr[0] => " + smartTVInfo.bindKeyArr[0]);
                }
            }
        } catch (err) {
            console.log("disconnectLiveForSmartTV error => ", err);
        } finally {
            smartTVInfo.bindKey = null;
            smartTVInfo.bindKeyArr = [];
            smartTVInfo.list_member = [];
        }
    }
}

export const rcvClose = () => {
    // myTeacherDirectKey = null;
    // exitConsume("live", rcvUserSeq);
}

const setMyLiveInfo = (sendKey, bindKeyArr) => {
    myHandsUpSendKey = sendKey;
    myHandsUpBindKeyArr = bindKeyArr;
}

export const initMyLiveInfo = (liveInfo) => {
    if (liveInfo && liveInfo.teacher_info) {
        let rcvSeq = liveInfo.teacher_info.userSeq;
        let liveSeq = liveInfo.liveSeq;
        let userGateWay = liveInfo.teacher_info.userGateWay;

        rcvUserSeq = rcvSeq;
        myTeacherDirectKey = getHandsUpDirectKey(rcvSeq, liveSeq, myUserSeq, 0);

        let sendKey = getHandsUpSendKey(rcvUserSeq, liveSeq, myUserSeq);
        let bindKeyArr = getHandsUpBindKey(rcvUserSeq, myUserSeq);

        myHandsUpSendKey = sendKey;
        myHandsUpBindKeyArr = bindKeyArr;

        if (userGateWay !== undefined && userGateWay !== null && userGateWay !== "") {
            routingKey = userGateWay;
        } else {
            routingKey = "soup.server";
        }
    }
}

const setMySmallGroupInfo = (sendKey, bindKeyArr) => {
    mySmallGroupSendKey = sendKey;
    mySmallGroupBindKeyArr = bindKeyArr;

    if (sendKey !== null && bindKeyArr && bindKeyArr.length > 0) {
        // mySmallGroupImageSendKey = sendKey + ".i";
        // mySmallGroupImageBindKey = bindKey + ".i";
        // mySmallGroupImageSendKey = sendKey.replace("G.", "I.");
        // mySmallGroupImageBindKey = bindKey.replace("G.", "I.");
        mySmallGroupImageSendKey = sendKey;
        mySmallGroupImageBindKey = bindKeyArr;
    } else {
        mySmallGroupImageSendKey = null;
        mySmallGroupImageBindKey = [];
    }
}

export const initImageSrc = (imgSrc) => {
    console.log("initImageSrc ------ ", imgSrc.width, imgSrc.height);
    consumeInfo.imgSrc = imgSrc;
}

export const setImageSrc = (img) => {
    console.log("setImageSrc - ", img.width, img.height);
    if (consumeInfo.imgSrc !== undefined && consumeInfo.imgSrc !== null) {
        consumeInfo.imgSrc(img);
    } else {
        console.log("setImageSrc() - consumeInfo.imgSrc => ", consumeInfo.imgSrc);
    }
}

export const initSmartTVImageProc = (imageProc) => {
    consumeInfo.smartTVImageProcess = imageProc;
}

export const updateSmartTVImage = (imageData) => {
    if (consumeInfo.smartTVImageProcess !== undefined && consumeInfo.smartTVImageProcess !== null) {
        consumeInfo.smartTVImageProcess("update", imageData);
    } else {
        console.log("updateSmartTVImage() - consumeInfo.updateSmartTVImage => ", consumeInfo.updateSmartTVImage);
    }
}

export const getTeacherSeq = () => {
    return rcvUserSeq;
}

export const getMyUserSeq = () => {
    return myUserSeq;
}

export const getMyRoutingKey = () => {
    return myRoutingKey;
}

export const getMyHandsUpSendKey = () => {
    return myHandsUpSendKey;
}

export const getMySmallGroupSendKey = () => {
    return mySmallGroupSendKey;
}

export const getMySmallGroupImageSendKey = () => {
    return mySmallGroupImageSendKey;
}

export const getIsAlivePenCamService = (userSeq) => {
    let store = window.hiclasstv.store;
    const { hiclasstv } = store.getState();

    if (currentPageType === enWebPage.GroupSmartTV) {
        const { list_isConnectedOnScreenApp } = hiclasstv;

        const connectedOnScreenApp_info = list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
        // console.log("getIsAlivePenCamService (for group) - ", connectedOnScreenApp_info);
        if (connectedOnScreenApp_info) {
            return connectedOnScreenApp_info.isConnectedOnScreenApp;
        } else {
            return false;
        }
    } else {
        const { isConnectedOnScreenApp } = hiclasstv;
        // console.log("getIsAlivePenCamService (for default) - ", isConnectedOnScreenApp);

        return isConnectedOnScreenApp;
        // return isAlivePenCamService;
    }
}

export const setIsAlivePenCamService = (isAlive, userSeq) => {
    isAlivePenCamService = isAlive;
    if (window.hiclasstv) {
        let store = window.hiclasstv.store;

        if (currentPageType === enWebPage.GroupSmartTV) {
            if (isAlive) {
                store.dispatch(connectedOnScreenAppArr({ userSeq }));
            } else {
                store.dispatch(disconnectedOnScreenAppArr({ userSeq }));
            }
        } else {
            if (isAlive) {
                store.dispatch(connectedOnScreenApp());
                store.dispatch(connectedOnScreenAppArr({ userSeq }));
            } else {
                store.dispatch(disconnectedOnScreenApp());
                store.dispatch(disconnectedOnScreenAppArr({ userSeq }));
            }
        }

        /* if (isAlive) {
            store.dispatch(connectedOnScreenAppArr({ userSeq }));
        } else {
            store.dispatch(disconnectedOnScreenAppArr({ userSeq }));
        } */
    }
}

export const getIsAlivePenCamServiceForGroupSmartTV = (userSeq) => {
    let store = window.hiclasstv.store;
    const { hiclasstv } = store.getState();
    const { list_isConnectedOnScreenApp } = hiclasstv;

    const connectedOnScreenApp_info = list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
    console.log("getIsAlivePenCamServiceForGroup - ", connectedOnScreenApp_info);
    if (connectedOnScreenApp_info) {
        return connectedOnScreenApp_info.isConnectedOnScreenApp;
    } else {
        return false;
    }
}

export const setIsAlivePenCamServiceForGroupSmartTV = (userSeq, isAlive) => {
    console.log("setIsAlivePenCamServiceForGroupSmartTV - ", userSeq, isAlive);
    isAlivePenCamService = isAlive;
    if (window.hiclasstv) {
        let store = window.hiclasstv.store;
        if (isAlive) {
            store.dispatch(connectedOnScreenAppArr({ userSeq }));
        } else {
            store.dispatch(disconnectedOnScreenAppArr({ userSeq }));
        }
    }
}

export const getCurrentPageType = () => {
    return currentPageType;
}

export const getIndexForGroupSmartTV = (userSeq) => {
    let store = window.hiclasstv.store;
    const { hiclasstv } = store.getState();
    const { list_isConnectedOnScreenApp } = hiclasstv;

    const connectedOnScreenApp_info = list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
    // console.log("getIndexForGroupSmartTV - ", connectedOnScreenApp_info);
    if (connectedOnScreenApp_info) {
        let idx = list_isConnectedOnScreenApp.indexOf(connectedOnScreenApp_info);
        return idx;
    } else {
        return -1;
    }
}

export const getConnectedOnScreenAppInfo = (userSeq) => {
    let store = window.hiclasstv.store;
    const { hiclasstv } = store.getState();
    const { list_isConnectedOnScreenApp } = hiclasstv;

    const connectedOnScreenApp_info = list_isConnectedOnScreenApp.find(info => info.userSeq === userSeq);
    // console.log("getConnectedOnScreenAppInfo - ", connectedOnScreenApp_info);
    if (connectedOnScreenApp_info) {
        let idx = list_isConnectedOnScreenApp.indexOf(connectedOnScreenApp_info);
        return { idx, connectedOnScreenApp_info };
    } else {
        return { idx: -1, connectedOnScreenApp_info: null };
    }
}

export const getConnectedOnScreenAppInfoWithIndex = (idx) => {
    let store = window.hiclasstv.store;
    const { hiclasstv } = store.getState();
    const { list_isConnectedOnScreenApp } = hiclasstv;

    if (currentPageType === enWebPage.GroupSmartTV) {
        const connectedOnScreenApp_info = list_isConnectedOnScreenApp.find((info, index) => index === idx);
        console.log("getConnectedOnScreenAppInfoWithIndex - ", connectedOnScreenApp_info);
        if (connectedOnScreenApp_info) {
            //let idx = list_isConnectedOnScreenApp.indexOf(connectedOnScreenApp_info);
            return { idx, connectedOnScreenApp_info };
        } else {
            return { idx: -1, connectedOnScreenApp_info: null };
        }
    } else {
        const { isConnectedOnScreenApp } = hiclasstv;

        return {
            idx: -1,
            connectedOnScreenApp_info: {
                userSeq: smartTVInfo.userSeq,
                userNickname: smartTVInfo.userNickname,
                isConnectedOnScreenApp
            }
        };
    }
}

export const getIsHandsUpStarted = () => {
    let store = window.hiclasstv.store;
    const { live } = store.getState();
    const { performLiveInfo } = live;

    if (performLiveInfo.liveSeq >= 0) {
        if (performLiveInfo.handsUpYN === "Y") {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const getIsRunForSmartPen = () => {
    return isRunForSmartPen;
}

export const setIsRunForSmartPen = (isForSmartPen) => {
    isRunForSmartPen = isForSmartPen;
}

export const getIsClickConnAppBtn = () => {
    return isClickConnAppBtn;
}

export const setIsClickConnAppBtn = (isForConnAppBtn) => {
    isClickConnAppBtn = isForConnAppBtn;
}

export const getIsSmartTVMode = () => {
    return isSmartTVMode;
}

/**
 * subscribeOptions
 * @param {object} invocationContext 
 * - onSuccess or onFailure에 전달할 param
 */
const joinRoom = (bindKeyArr, liveSeq) => {
    console.log("joinRoom bindKeyArr, liveSeq => ", bindKeyArr, liveSeq);
    if (rbmqClient) {
        let nextKey = null;
        console.log("myHandsUpBindKeyArr = > ", myHandsUpBindKeyArr);

        nextKey = myHandsUpBindKeyArr[1];
        if (myHandsUpBindKeyArr.length > 2) {
            handsUpRoutingIdx = 2;
        } else {
            handsUpRoutingIdx = myHandsUpBindKeyArr.length;
        }

        console.log(`handsUpRoutingIdx[${handsUpRoutingIdx}], nextKey[${nextKey}]`);

        let subscribeOptions = {
            qos: 1,
            // invocationContext: { nextKey },
            onSuccess: onSuccessSubscribeLive,
            onFailure: onFailureSubscribeLive
        };

        if (!ConstData.IS_LOCAL_VERSION) {
            subscribeOptions.invocationContext = { nextKey };

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.subscribe(bindKeyArr[0], subscribeOptions);
            } else {
                console.log("joinRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("joinRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        } else {
            // subscribeOptions.onSuccess = onSuccessSubscribeLiveWithWebSocket;

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.subscribeArr(bindKeyArr, subscribeOptions);
            } else {
                console.log("joinRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("joinRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        }
    }
}

/**
 * unsubsribeOptions
 * @param {object} invocationContext 
 * - onSuccess or onFailure에 전달할 param
 */
const leaveRoom = (bindKeyArr) => {
    if (rbmqClient) {
        let nextKey = null;
        console.log("bindKeyArr = > ", bindKeyArr);

        nextKey = bindKeyArr[1];
        if (bindKeyArr.length > 2) {
            handsUpRoutingIdx = 2;
        } else {
            handsUpRoutingIdx = bindKeyArr.length;
        }

        console.log(`handsUpRoutingIdx[${handsUpRoutingIdx}], nextKey[${nextKey}]`);

        let unsubsribeOptions = {
            timeout: 3,
            // invocationContext: { nextKey: bindKeyArr[1] },
            onSuccess: onSuccessUnsubscribeLive,
            onFailure: onFailureUnsubscribeLive
        };

        if (!ConstData.IS_LOCAL_VERSION) {
            unsubsribeOptions.invocationContext = { nextKey };

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.unsubscribe(bindKeyArr[0], unsubsribeOptions);
            } else {
                console.log("leaveRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("leaveRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        } else {
            // unsubsribeOptions.onSuccess = onSuccessUnSubscribeLiveWithWebSocket;

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.unsubscribeArr(bindKeyArr, unsubsribeOptions);
            } else {
                console.log("leaveRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("leaveRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        }
    }
}

const joinSmallGroupRoom = (bindKeyArr) => {
    if (rbmqClient) {
        let nextKey = null;
        console.log("bindKeyArr = > ", bindKeyArr);

        nextKey = bindKeyArr[1];
        if (bindKeyArr.length > 2) {
            groupRoutingIdx = 2;
        } else {
            groupRoutingIdx = bindKeyArr.length;
        }

        console.log(`groupRoutingIdx[${groupRoutingIdx}], nextKey[${nextKey}]`);

        let subscribeOptions = {
            qos: 1,
            // invocationContext: { nextKey: bindKey.replace("G.", "I.") },
            // invocationContext: { nextKey: bindKey },
            onSuccess: onSuccessSubscribeSmallGroup,
            onFailure: onFailureSubscribeSmallGroup
        };

        if (!ConstData.IS_LOCAL_VERSION) {
            subscribeOptions.invocationContext = { nextKey };

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.subscribe(bindKeyArr[0], subscribeOptions);
            } else {
                console.log("joinSmallGroupRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("joinSmallGroupRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        } else {
            // subscribeOptions.onSuccess = onSuccessSubscribeLiveWithWebSocket;

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.subscribeArr(bindKeyArr, subscribeOptions);
            } else {
                console.log("joinSmallGroupRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("joinSmallGroupRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        }
    }
}

const leaveSmallGroupRoom = (bindKeyArr) => {
    if (rbmqClient) {
        let nextKey = null;
        console.log("bindKeyArr = > ", bindKeyArr);

        nextKey = bindKeyArr[1];
        if (bindKeyArr.length > 2) {
            groupRoutingIdx = 2;
        } else {
            groupRoutingIdx = bindKeyArr.length;
        }

        console.log(`groupRoutingIdx[${groupRoutingIdx}], nextKey[${nextKey}]`);
    
        let unsubsribeOptions = {
            timeout: 3,
            // invocationContext: { nextKey: bindKey.replace("G.", "I.") },
            // invocationContext: { nextKey: bindKey },
            onSuccess: onSuccessUnsubscribeSmallGroup,
            onFailure: onFailureUnsubscribeSmallGroup
        };

        if (!ConstData.IS_LOCAL_VERSION) {
            unsubsribeOptions.invocationContext = { nextKey };

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.unsubscribe(bindKeyArr[0], unsubsribeOptions);
            } else {
                console.log("leaveSmallGroupRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("leaveSmallGroupRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        } else {
            // subscribeOptions.onSuccess = onSuccessSubscribeLiveWithWebSocket;

            if (bindKeyArr[0] && bindKeyArr[0] !== "") {
                rbmqClient.unsubscribeArr(bindKeyArr, unsubsribeOptions);
            } else {
                console.log("leaveSmallGroupRoom() - bindKeyArr[0] => ", bindKeyArr[0]);
                window.alert("leaveSmallGroupRoom() - bindKeyArr[0] => " + bindKeyArr[0]);
            }
        }
    }
}

export const getRcvKind = (routingKey, myHandsUpSendKey) => {
    let selRcvKind = ctRcvKind.Team;
    let sKeyArr = null;
    const rKeyArr = routingKey.split(".");
    // let strIdx = routingKey.indexOf("L.");

    if (rKeyArr[0] === "L" || rKeyArr[0] === "LM") {    // for class hands up
        if (myHandsUpSendKey !== undefined && myHandsUpSendKey !== null) {
            sKeyArr = myHandsUpSendKey.split(".");

            if (rKeyArr[1] === sKeyArr[1]) {
                if (rKeyArr[3] === "0") {
                    selRcvKind = ctRcvKind.Teacher;
                } else {
                    selRcvKind = ctRcvKind.Student;
                }
            }
        }
    } else if (rKeyArr[0] === "G" || rKeyArr[0] === "GM") {    // small group
        selRcvKind = ctRcvKind.Student;                         // group 추가할지 고민..
    }

    /* if (strIdx >= 0) {
        if (myHandsUpSendKey !== undefined && myHandsUpSendKey !== null) {
            sKeyArr = myHandsUpSendKey.split(".");

            if (rKeyArr[1] === sKeyArr[1]) {
                if (rKeyArr[3] === "0") {
                    selRcvKind = ctRcvKind.Teacher;
                } else {
                    selRcvKind = ctRcvKind.Student;
                }
            }
        }
    } else {
        if (rKeyArr[0] === "G") {
            selRcvKind = ctRcvKind.Student;
        }
    } */

    return selRcvKind;
}

export const getProfileImgUrl = (selRcvKind, routingKey) => {
    let store = window.hiclasstv.store;
    const { live } = store.getState();
    const { performLiveInfo } = live;
    let profileImgUrl = "1";    // profile image '1' 이 기본 값이기 때문에 변경 ... by hjkim 20240215
    const rKeyArr = routingKey.split(".");

    if (selRcvKind === ctRcvKind.Teacher) {
        if (performLiveInfo.teacher_info !== undefined && performLiveInfo.teacher_info !== null) {
            //console.log("Teacher - profileImgUrl => ", performLiveInfo.teacher_info.profileImgUrl);
            profileImgUrl = performLiveInfo.teacher_info.profileImgUrl;
        }
    } else if (selRcvKind === ctRcvKind.Student) {
        if (performLiveInfo.list_member !== undefined && performLiveInfo.list_member !== null) {
            if (rKeyArr[0] === "G" || rKeyArr[0] === "GM") { // 스몰그룹 시, 전송자 프로필 이미지 get
                const member_info = performLiveInfo.list_member.find(info => Number(info.userSeq) === Number(rKeyArr[rKeyArr.length - 2]));
                if (member_info !== undefined && member_info !== null) {
                    //console.log(rKeyArr[1] + ")Student - profileImgUrl => ", member_info.profileImgUrl);
                    profileImgUrl = member_info.profileImgUrl;
                }
            } else {
                // eslint-disable-next-line eqeqeq
                const member_info = performLiveInfo.list_member.find(info => info.userSeq == rKeyArr[rKeyArr.length - 1]);
                if (member_info !== undefined && member_info !== null) {
                    //console.log(rKeyArr[rKeyArr.length - 1] + ")Student - profileImgUrl => ", member_info.profileImgUrl);
                    profileImgUrl = member_info.profileImgUrl;
                }
            }
        }
    }

    return profileImgUrl;
}

export const getRcvLiveSeq = (routingKey) => {
    if (routingKey === undefined || routingKey === null) return -1;

    const rKeyArr = routingKey.split(".");
    let strIdx = routingKey.indexOf("L.");

    /* if (strIdx >= 0) {
        if (rKeyArr.length > 3) {
            let liveSeq = rKeyArr[2];
            return Number(liveSeq);
        } else {
            return -1;
        }
    } */

    if (rKeyArr[0] === "L" || rKeyArr[0] === "LM") {
        if (rKeyArr.length > 3) {
            let liveSeq = rKeyArr[2];
            return Number(liveSeq);
        } else {
            return -1;
        }
    }

    return -1;
}

/** rbmq */
const connectRbmq = async () => {
    if (rbmqInfo !== undefined && rbmqInfo !== null) {
        if (ConstData.IS_LOCAL_VERSION) {
            rbmqClient = new WebClient(rbmqInfo.wsIp);
        } else {
            rbmqClientID = "myclientid_" + parseInt(Math.random() * 100, 10);
            rbmqClient = new window.Paho.MQTT.Client(rbmqInfo.wsIp, rbmqInfo.wsPort, rbmqInfo.wsPath, rbmqClientID);
        }

        rbmqClient.onConnectionLost = onConnectionLost;
        rbmqClient.onMessageArrived = onMessageArrived;

        // let willMessage = new window.Paho.MQTT.Message("");
        // willMessage.destinationName = myRoutingKey;

        let rbmqOptions = {
            timeout: 5,
            // willMessage: willMessage,
            keepAliveInterval: 30,                // 이 값 때문에 socket close 되는 걸 수도.... 일단 주석 처리 by hjkim 20211102
            // 이 시간(초) 동안 활동이 없으면 서버는 이 클라이언트의 연결을 끊습니다. 설정되지 않은 경우 기본값인 60초로 간주됩니다.
            userName: rbmqInfo.vHost + ":" + rbmqInfo.userID,
            password: rbmqInfo.userPW,
            useSSL: true,
            cleanSession: true,
            onSuccess: onSuccessConnect,
            onFailure: onFailureConnect,
        };

        if (ConstData.IS_LOCAL_VERSION) {
            rbmqOptions.onConnectionLost = onConnectionLost;
        }

        rbmqClient.connect(rbmqOptions);
    } else {
        console.log("connectRbmq - rbmqInfo => ", rbmqInfo);
    }
}

const connectRbmqForMixedClass = async () => {
    if (rbmqInfo !== undefined && rbmqInfo !== null) {
        if (ConstData.IS_LOCAL_VERSION) {
            rbmqClient = new WebClient(rbmqInfo.wsIp);
        } else {
            rbmqClientID = "myclientid_" + parseInt(Math.random() * 100, 10);
            rbmqClient = new window.Paho.MQTT.Client(rbmqInfo.wsIp, rbmqInfo.wsPort, rbmqInfo.wsPath, rbmqClientID);
        }

        rbmqClient.onConnectionLost = onConnectionLost;
        rbmqClient.onMessageArrived = onMessageArrived;

        let rbmqOptions = {
            timeout: 5,
            // willMessage: willMessage,
            keepAliveInterval: 30,                // 이 값 때문에 socket close 되는 걸 수도.... 일단 주석 처리 by hjkim 20211102
            // 이 시간(초) 동안 활동이 없으면 서버는 이 클라이언트의 연결을 끊습니다. 설정되지 않은 경우 기본값인 60초로 간주됩니다.
            userName: rbmqInfo.vHost + ":" + rbmqInfo.userID,
            password: rbmqInfo.userPW,
            useSSL: true,
            cleanSession: true,
            onSuccess: onSuccessConnectForMixedClass,
            onFailure: onFailureConnect,
        };

        if (ConstData.IS_LOCAL_VERSION) {
            rbmqOptions.onConnectionLost = onConnectionLost;
        }

        rbmqClient.connect(rbmqOptions);
    } else {
        console.log("connectRbmqForMixedClass - rbmqInfo => ", rbmqInfo);
    }
}

const connectRbmqForSmartTV = async () => {
    if (rbmqInfo !== undefined && rbmqInfo !== null) {
        if (ConstData.IS_LOCAL_VERSION) {
            rbmqClient = new WebClient(rbmqInfo.wsIp);
        } else {
            rbmqClientID = "myclientid_" + parseInt(Math.random() * 100, 10);
            rbmqClient = new window.Paho.MQTT.Client(rbmqInfo.wsIp, rbmqInfo.wsPort, rbmqInfo.wsPath, rbmqClientID);
        }

        rbmqClient.onConnectionLost = onConnectionLost;
        rbmqClient.onMessageArrived = onMessageArrived;

        // let willMessage = new window.Paho.MQTT.Message("");
        // willMessage.destinationName = myRoutingKey;

        let rbmqOptions = {
            timeout: 5,
            // willMessage: willMessage,
            keepAliveInterval: 30,                // 이 값 때문에 socket close 되는 걸 수도.... 일단 주석 처리 by hjkim 20211102
            // 이 시간(초) 동안 활동이 없으면 서버는 이 클라이언트의 연결을 끊습니다. 설정되지 않은 경우 기본값인 60초로 간주됩니다.
            userName: rbmqInfo.vHost + ":" + rbmqInfo.userID,
            password: rbmqInfo.userPW,
            useSSL: true,
            cleanSession: true,
            onSuccess: onSuccessConnectForSmartTV,
            onFailure: onFailureConnect,
        };

        if (ConstData.IS_LOCAL_VERSION) {
            rbmqOptions.onConnectionLost = onConnectionLost;
        }

        rbmqClient.connect(rbmqOptions);
    } else {
        console.log("connectRbmqForSmartTV - rbmqInfo => ", rbmqInfo);
    }
}

const connectRbmqForGroupSmartTV = async () => {
    if (rbmqInfo !== undefined && rbmqInfo !== null) {
        if (ConstData.IS_LOCAL_VERSION) {
            rbmqClient = new WebClient(rbmqInfo.wsIp);
        } else {
            rbmqClientID = "myclientid_" + parseInt(Math.random() * 100, 10);
            rbmqClient = new window.Paho.MQTT.Client(rbmqInfo.wsIp, rbmqInfo.wsPort, rbmqInfo.wsPath, rbmqClientID);
        }

        rbmqClient.onConnectionLost = onConnectionLost;
        rbmqClient.onMessageArrived = onMessageArrived;

        // let willMessage = new window.Paho.MQTT.Message("");
        // willMessage.destinationName = myRoutingKey;

        let rbmqOptions = {
            timeout: 5,
            // willMessage: willMessage,
            keepAliveInterval: 30,                // 이 값 때문에 socket close 되는 걸 수도.... 일단 주석 처리 by hjkim 20211102
            // 이 시간(초) 동안 활동이 없으면 서버는 이 클라이언트의 연결을 끊습니다. 설정되지 않은 경우 기본값인 60초로 간주됩니다.
            userName: rbmqInfo.vHost + ":" + rbmqInfo.userID,
            password: rbmqInfo.userPW,
            useSSL: true,
            cleanSession: true,
            onSuccess: onSuccessConnectForGroupSmartTV,
            onFailure: onFailureConnect,
        };

        if (ConstData.IS_LOCAL_VERSION) {
            rbmqOptions.onSuccess = onSuccessConnectForGroupSmartTVWithWebSocket;
            rbmqOptions.onConnectionLost = onConnectionLost;
        }

        rbmqClient.connect(rbmqOptions);
    } else {
        console.log("connectRbmqForGroupSmartTV - rbmqInfo => ", rbmqInfo);
    }
}

const reconnectRbmq = () => {
    if (rbmqInfo) {
        if (ConstData.IS_LOCAL_VERSION) {
            rbmqClient = new WebClient(rbmqInfo.wsIp);
        } else {
            rbmqClientID = "myclientid_" + parseInt(Math.random() * 100, 10);
            rbmqClient = new window.Paho.MQTT.Client(rbmqInfo.wsIp, rbmqInfo.wsPort, rbmqInfo.wsPath, rbmqClientID);
        }

        rbmqClient.onConnectionLost = onConnectionLost;
        rbmqClient.onMessageArrived = onMessageArrived;

        let rbmqOptions = {
            timeout: 5,
            // willMessage: willMessage,
            keepAliveInterval: 30,                // 이 값 때문에 socket close 되는 걸 수도.... 일단 주석 처리 by hjkim 20211102
            // 이 시간(초) 동안 활동이 없으면 서버는 이 클라이언트의 연결을 끊습니다. 설정되지 않은 경우 기본값인 60초로 간주됩니다.
            userName: rbmqInfo.vHost + ":" + rbmqInfo.userID,
            password: rbmqInfo.userPW,
            useSSL: true,
            cleanSession: true,
            onSuccess: onSuccessConnect,
            onFailure: onFailureConnect,
        };

        if (ConstData.IS_LOCAL_VERSION) {
            rbmqOptions.onConnectionLost = onConnectionLost;
        }

        rbmqClient.connect(rbmqOptions);
    }
}

export const disconnectRbmq = (value) => {
    console.log("disconnectRbmq - value => ", value);
    if (rbmqClient) {
        // console.log(`disconnectRbmq rbmqClient.isConnected[${rbmqClient.isConnected}]`);
        try {
            // console.log("myRoutingKey => ", myRoutingKey);
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribe,
                onFailure: onFailureUnsubscribe
            };

            if (myRoutingKey && myRoutingKey !== "") {
                rbmqClient.unsubscribe(myRoutingKey, unsubsribeOptions);
            } else {
                console.log("disconnectRbmq() - myRoutingKey => ", myRoutingKey);
                window.alert("disconnectRbmq() - myRoutingKey => " + myRoutingKey);
            }
        } catch (err) {
            console.log("------disconnectRbmq------ error => ", err);
        }
    }
}

export const disconnectRbmqForMixed = () => {
    if (rbmqClient) {
        // console.log(`disconnectRbmq rbmqClient.isConnected[${rbmqClient.isConnected}]`);
        try {
            // console.log("myRoutingKey => ", myRoutingKey);
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribe,
                onFailure: onFailureUnsubscribe
            };

            if (myRoutingKey && myRoutingKey !== "") {
                rbmqClient.unsubscribe(myRoutingKey, unsubsribeOptions);
            } else {
                console.log("disconnectRbmqForMixed() - myRoutingKey => ", myRoutingKey);
                window.alert("disconnectRbmqForMixed() - myRoutingKey => " + myRoutingKey);
            }
        } catch (err) {
            console.log("------disconnectRbmq------ error => ", err);
        }
    }
}

export const disconnectRbmqForSmartTV = () => {
    if (rbmqClient) {
        // console.log(`disconnectRbmqForSmartTV rbmqClient.isConnected[${rbmqClient.isConnected}], smartTVInfo.bindKeyArr => `, smartTVInfo.bindKeyArr, rbmqClient.isConnected);
        try {
            if (smartTVInfo.bindKeyArr && smartTVInfo.bindKeyArr.length > 0) {
                let unsubsribeOptions = {
                    timeout: 3,
                    invocationContext: { nextKey: smartTVInfo.bindKeyArr[1] },
                    onSuccess: onSuccessUnsubscribeLiveForSmartTV,
                    onFailure: onFailureUnsubscribeLiveForSmartTV
                };

                if (smartTVInfo.bindKeyArr[0] && smartTVInfo.bindKeyArr[0] !== "") {
                    rbmqClient.unsubscribe(smartTVInfo.bindKeyArr[0], unsubsribeOptions);
                } else {
                    console.log("disconnectRbmqForSmartTV() - smartTVInfo.bindKeyArr[0] => ", smartTVInfo.bindKeyArr[0]);
                    window.alert("disconnectRbmqForSmartTV() - smartTVInfo.bindKeyArr[0] => " + smartTVInfo.bindKeyArr[0]);
                }
            } else {
                let unsubsribeOptions = {
                    timeout: 3,
                    onSuccess: onSuccessUnsubscribe,
                    onFailure: onFailureUnsubscribe
                };

                if (myRoutingKey && myRoutingKey !== "") {
                    rbmqClient.unsubscribe(myRoutingKey, unsubsribeOptions);
                } else if (myRoutingKeyArr && myRoutingKeyArr.length > 0) {
                    let nextKey = null;

                    if (myRoutingKeyArr.length > 1) {
                        nextKey = myRoutingKeyArr[1];
                        if (myRoutingKeyArr.length > 2) {
                            routingIdx = 2;
                        } else {
                            // routingIdx = 4;
                            routingIdx = myRoutingKeyArr.length;
                        }
                    } else {
                        nextKey = smartTVTempKey;
                        // routingIdx = 4;
                        routingIdx = myRoutingKeyArr.length;
                    }

                    console.log(`routingIdx[${routingIdx}], nextKey[${nextKey}]`);

                    unsubsribeOptions.invocationContext = { nextKey };

                    if (myRoutingKeyArr[0] && myRoutingKeyArr[0] !== "") {
                        rbmqClient.unsubscribe(myRoutingKeyArr[0], unsubsribeOptions);
                    } else {
                        console.log("disconnectRbmqForSmartTV() - myRoutingKeyArr[0] => ", myRoutingKeyArr[0]);
                        window.alert("disconnectRbmqForSmartTV() - myRoutingKeyArr[0] => " + myRoutingKeyArr[0]);
                    }
                }
            }
        } catch (err) {
            console.log("------disconnectRbmqForSmartTV------ error => ", err);
        } finally {
            smartTVInfo.bindKey = null;
            smartTVInfo.bindKeyArr = [];
        }
    }
}

export const disconnectTempRbmq = () => {
    if (rbmqClient) {
        // console.log(`disconnectTempRbmq rbmqClient.isConnected[${rbmqClient.isConnected}]`);
        try {
            // console.log("myRoutingKey => ", myRoutingKey);
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribe,
                onFailure: onFailureUnsubscribe
            };

            if (myTempBindKey && myTempBindKey !== "") {
                rbmqClient.unsubscribe(myTempBindKey, unsubsribeOptions);
            } else {
                console.log("disconnectTempRbmq() - myTempBindKey => ", myTempBindKey);
                window.alert("disconnectTempRbmq() - myTempBindKey => " + myTempBindKey);
            }
        } catch (err) {
            console.log("------disconnectRbmq------ error => ", err);
        }
    }
}
/********************************/

/** rbmq callback 처리 */
const onSuccessConnect = (msg) => {
    if (rbmqClient) {
        if (pingInterval === null) {
            pingInterval = setInterval(() => { sendPingToRbmq(); }, 25000);
        }

        let rbmqSubscribeOptions = {
            qos: 1,
            onSuccess: onSuccessSubscribe,
            onFailure: onFailureSubscribe
        };

        if (myRoutingKey && myRoutingKey !== "") {
            rbmqClient.subscribe(myRoutingKey, rbmqSubscribeOptions);
        } else {
            console.log("onSuccessConnect() - myRoutingKey => ", myRoutingKey);
            window.alert("onSuccessConnect() - myRoutingKey => " + myRoutingKey);
        }

        let store = window.hiclasstv.store;
        store.dispatch(rbmqServerConnected());
    }
}

const onFailureConnect = (res) => {
    if (rbmqClient) {
        rbmqClient = null;
        rbmqClientID = null;
    }

    let store = window.hiclasstv.store;
    store.dispatch(rbmqServerError({ message: "fail_connect" }));

    writeRbmqErrorMessage("onFailureConnect", res);
}

const onConnectionLost = (res) => {
    if (pingInterval) {
        clearInterval(pingInterval);
        pingInterval = null;
    }

    if (rbmqClient) {
        rbmqClient = null;
        rbmqClientID = null;
    }

    writeRbmqErrorMessage("onConnectionLost", res);
}

const onSuccessSubscribe = (msg) => {
    console.log("------onSuccessSubscribe------", msg);
    // let store = window.hiclasstv.store;
    // store.dispatch(rbmqServerConnected());
}

const onFailureSubscribe = (res) => {
    writeRbmqErrorMessage("onFailureSubscribe", res);
}

const onSuccessUnsubscribe = (msg) => {
    console.log("onSuccessUnsubscribe => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;
        if (nextKey && nextKey !== "") {
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribe,
                onFailure: onFailureUnsubscribe
            };

            console.log(`(2) beforeRoutingIdx[${routingIdx}], beforeNextKey[${nextKey}]`);
            let newNextKey = myRoutingKeyArr[routingIdx++];
            console.log(`(2) newRoutingIdx[${routingIdx}], newNextKey[${newNextKey}]`);

            if (newNextKey && newNextKey !== "") {
                unsubsribeOptions.invocationContext = { nextKey: newNextKey };
            } else {
                console.log(`(2) nextKey[${nextKey}], smartTVTempKey[${smartTVTempKey}]`);
                if (nextKey === smartTVTempKey) {
                    console.log("(2) nextKey === smartTVTempKey ... newNextKey is undefined!");
                    // rbmqSubscribeOptions.invocationContext = { nextKey: smartTVTempKey };
                } else {
                    console.log("(2) nextKey !== smartTVTempKey ... newNextKey is smartTVTempKey!");
                    unsubsribeOptions.invocationContext = { nextKey: smartTVTempKey };
                }
            }

            console.log("unsubsribeOptions => ", unsubsribeOptions);

            if (nextKey && nextKey !== "") {
                rbmqClient.unsubscribe(nextKey, unsubsribeOptions);
            } else {
                console.log("onSuccessUnsubscribe(1) - nextKey => ", nextKey);
                window.alert("onSuccessUnsubscribe(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessUnsubscribe(2) - nextKey => ", nextKey);
            window.alert("onSuccessUnsubscribe(2) - nextKey => " + nextKey);
        }
    } else {
        if (rbmqClient) {
            if (pingInterval) {
                // console.log("onSuccessUnsubscribe-clearInterval");
                clearInterval(pingInterval);
                pingInterval = null;
            }
    
            try {
                rbmqClient.disconnect();
            } catch (err) {
                console.log("------onSuccessUnsubscribe-disconnect------ error => ", err);
            } finally {
                rbmqClient = null;
                rbmqClientID = null;
                rbmqInfo = null;
                myRoutingKey = null;
                myTempBindKey = null;
                myRoutingKeyArr = [];
                smartTVTempKey = null;
                routingIdx = -1;
                isGuestMember = false;
            }
        }
    }
}

const onFailureUnsubscribe = (res) => {
    if (rbmqClient) {
        try {
            rbmqClient.disconnect();
        } catch (err) {
            console.log("------onFailureUnsubscribe-disconnect------ error => ", err);
        } finally {
            rbmqClient = null;
            rbmqClientID = null;
            writeRbmqErrorMessage("onFailureUnsubscribe", res);
        }
    }
}
/********************************/

/** rbmq live callback 처리 */
const onSuccessSubscribeLive = (msg) => {
    console.log("onSuccessSubscribeLive - msg => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;
        if (nextKey && nextKey !== "") {
            let subscribeOptions = {
                qos: 1,
                onSuccess: onSuccessSubscribeLive,
                onFailure: onFailureSubscribeLive
            };

            console.log(`(1) before handsUpRoutingIdx[${handsUpRoutingIdx}], beforeNextKey[${nextKey}]`);
            let newNextKey = myHandsUpBindKeyArr[handsUpRoutingIdx++];
            console.log(`(1) new handsUpRoutingIdx[${handsUpRoutingIdx}], newNextKey[${newNextKey}]`);

            if (newNextKey && newNextKey !== "") {
                subscribeOptions.invocationContext = { nextKey: newNextKey };
            } else {
                console.log("(1) newNextKey is undefined!");
            }

            console.log("subscribeOptions => ", subscribeOptions);

            if (nextKey && nextKey !== "") {
                rbmqClient.subscribe(nextKey, subscribeOptions);
            } else {
                console.log("onSuccessSubscribeLive(1) - nextKey => ", nextKey);
                window.alert("onSuccessSubscribeLive(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessSubscribeLive(2) - nextKey => ", nextKey);
            window.alert("onSuccessSubscribeLive(2) - nextKey => " + nextKey);
        }
    } else {
        if (isNeedSendSubscribe) {
            console.log("isNeedSendSubscribe is true!");
            console.log("sendJoinConsume call");
            sendJoinConsume("live", rcvUserSeq);
            isNeedSendSubscribe = false;
            console.log("isNeedSendSubscribe = false");
        } else {
            console.log("isNeedSendSubscribe is false!");
        }
    }
}

/* const onSuccessSubscribeLiveWithWebSocket = (msg) => {
    console.log("onSuccessSubscribeLive - ", msg);
    if (isNeedSendSubscribe) {
        console.log("isNeedSendSubscribe is true!");
        console.log("sendJoinConsume call");
        sendJoinConsume("live", rcvUserSeq);
        isNeedSendSubscribe = false;
        console.log("isNeedSendSubscribe = false");
    } else {
        console.log("isNeedSendSubscribe is false!");
    }
} */

const onFailureSubscribeLive = (res) => {
    writeRbmqErrorMessage("onFailureSubscribeLive", res);
}

const onSuccessUnsubscribeLive = (msg) => {
    console.log("------onSuccessUnsubscribeLive------ msg => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;
        if (nextKey && nextKey !== "") {
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribeLive,
                onFailure: onFailureUnsubscribeLive
            }

            console.log(`(1) before handsUpRoutingIdx[${handsUpRoutingIdx}], beforeNextKey[${nextKey}]`);
            let newNextKey = myHandsUpBindKeyArr[handsUpRoutingIdx++];
            console.log(`(1) new handsUpRoutingIdx[${handsUpRoutingIdx}], newNextKey[${newNextKey}]`);

            if (newNextKey && newNextKey !== "") {
                unsubsribeOptions.invocationContext = { nextKey: newNextKey };
            } else {
                console.log("(1) newNextKey is undefined!");
            }

            console.log("unsubsribeOptions => ", unsubsribeOptions);

            if (nextKey && nextKey !== "") {
                rbmqClient.unsubscribe(nextKey, unsubsribeOptions);
            } else {
                console.log("onSuccessUnsubscribeLive(1) - nextKey => ", nextKey);
                window.alert("onSuccessUnsubscribeLive(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessUnsubscribeLive(2) - nextKey => ", nextKey);
            window.alert("onSuccessUnsubscribeLive(2) - nextKey => " + nextKey);
        }
    } else {
        if (isNeedSendUnsubscribe) {
            unsubscribe(false, true);
        } else {
            isNeedSendUnsubscribe = true;
        }
    }
}

const onFailureUnsubscribeLive = (res) => {
    writeRbmqErrorMessage("onFailureUnsubscribeLive", res);
}
/********************************/

/** rbmq small group callback 처리 */
const onSuccessSubscribeSmallGroup = (msg) => {
    console.log("------onSuccessSubscribeSmallGroup------ msg => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;
        if (nextKey && nextKey !== "") {
            let subscribeOptions = {
                qos: 1,
                onSuccess: onSuccessSubscribeSmallGroup,
                onFailure: onFailureSubscribeSmallGroup
            };

            console.log(`(1) before groupRoutingIdx[${groupRoutingIdx}], beforeNextKey[${nextKey}]`);
            let newNextKey = mySmallGroupBindKeyArr[groupRoutingIdx++];
            console.log(`(1) new groupRoutingIdx[${groupRoutingIdx}], newNextKey[${newNextKey}]`);

            if (newNextKey && newNextKey !== "") {
                subscribeOptions.invocationContext = { nextKey: newNextKey };
            } else {
                console.log("(1) newNextKey is undefined!");
            }

            console.log("subscribeOptions => ", subscribeOptions);

            if (nextKey && nextKey !== "") {
                rbmqClient.subscribe(nextKey, subscribeOptions);
            } else {
                console.log("onSuccessSubscribeSmallGroup(1) - nextKey => ", nextKey);
                window.alert("onSuccessSubscribeSmallGroup(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessSubscribeSmallGroup(2) - nextKey => ", nextKey);
            window.alert("onSuccessSubscribeSmallGroup(2) - nextKey => " + nextKey);
        }
    }
}

const onFailureSubscribeSmallGroup = (res) => {
    // console.log("------onFailureSubscribeSmallGroup------ res => ", res);
    writeRbmqErrorMessage("onFailureSubscribeSmallGroup", res);
}

const onSuccessUnsubscribeSmallGroup = (msg) => {
    console.log("------onSuccessUnsubscribeSmallGroup------ msg => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;
        if (nextKey && nextKey !== "") {
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribeSmallGroup,
                onFailure: onFailureUnsubscribeSmallGroup
            }

            console.log(`(1) before groupRoutingIdx[${groupRoutingIdx}], beforeNextKey[${nextKey}]`);
            let newNextKey = mySmallGroupBindKeyArr[groupRoutingIdx++];
            console.log(`(1) new groupRoutingIdx[${groupRoutingIdx}], newNextKey[${newNextKey}]`);

            if (newNextKey && newNextKey !== "") {
                unsubsribeOptions.invocationContext = { nextKey: newNextKey };
            } else {
                console.log("(1) newNextKey is undefined!");
            }

            console.log("unsubsribeOptions => ", unsubsribeOptions);

            if (nextKey && nextKey !== "") {
                rbmqClient.unsubscribe(nextKey, unsubsribeOptions);
            } else {
                console.log("onSuccessUnsubscribeSmallGroup(1) - nextKey => ", nextKey);
                window.alert("onSuccessUnsubscribeSmallGroup(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessUnsubscribeSmallGroup(2) - nextKey => ", nextKey);
            window.alert("onSuccessUnsubscribeSmallGroup(2) - nextKey => " + nextKey);
        }
    }
}

const onFailureUnsubscribeSmallGroup = (res) => {
    writeRbmqErrorMessage("onFailureUnsubscribeSmallGroup", res);
}
/********************************/

/** rbmq mixed class callback 처리 */
const onSuccessConnectForMixedClass = (msg) => {
    if (rbmqClient) {
        if (pingInterval === null) {
            pingInterval = setInterval(() => { sendPingToRbmq(); }, 25000);
        }

        let rbmqSubscribeOptions = {
            qos: 1,
            onSuccess: onSuccessSubscribeTempKeyForMixedClass,
            onFailure: onFailureSubscribe
        };

        if (myTempBindKey && myTempBindKey !== "") {
            rbmqClient.subscribe(myTempBindKey, rbmqSubscribeOptions);
        } else {
            console.log("onSuccessConnectForMixedClass() - myTempBindKey => ", myTempBindKey);
            window.alert("onSuccessConnectForMixedClass() - myTempBindKey => " + myTempBindKey);
        }

        // let store = window.hiclasstv.store;
        // store.dispatch(rbmqServerConnected());
    }
}

const onSuccessSubscribeTempKeyForMixedClass = (res) => {
    let store = window.hiclasstv.store;
    store.dispatch(rbmqServerConnected());

    if (myLocalServerBindKey && myLocalServerBindKey !== "" && localServerSendData && localServerSendData !== "") {
        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind: "local", action: "live-member-list", information: localServerSendData })
        }, "event_exchange");

        sendDataToRbmq(myLocalServerBindKey, sendData.forWeb);
    }
}

const onSuccessUnsubscribeTempKeyForMixedClass = (msg) => {
    try {
        let rbmqSubscribeOptions = {
            qos: 1,
            onSuccess: onSuccessSubscribeForMixedClass,
            onFailure: onFailureSubscribe
        };

        console.log(`onSuccessUnsubscribeTempKeyForMixedClass - myRoutingKey => `, myRoutingKey);
        if (myRoutingKey && myRoutingKey !== "") {
            rbmqClient.subscribe(myRoutingKey, rbmqSubscribeOptions);
        } else {
            console.log("onSuccessUnsubscribeTempKeyForMixedClass() - myRoutingKey => ", myRoutingKey);
            window.alert("onSuccessUnsubscribeTempKeyForMixedClass() - myRoutingKey => " + myRoutingKey);
        }
    } catch (err) {
        console.log("------onSuccessUnsubscribeTempKeyForMixedClass unsubscribe------ error => ", err);
    } finally {
        myTempBindKey = null;
    }
}

const onSuccessSubscribeForMixedClass = (msg) => {
    console.log("onSuccessSubscribeForMixedClass => ", msg);
    let store = window.hiclasstv.store;
    const { user, live } = store.getState();
    const { userNickname, userSeq } = user;
    const { mixedClassInfo } = live;

    /* requestJoinMixedClassLive({
        liveSeq: this.props.mixedClassInfo.liveSeq,
        liveName: this.props.mixedClassInfo.liveName,
        userSeq: this.props.userSeq,
        userNickname: this.props.userNickname,
        mixedClassSeq: this.props.mixedClassInfo.mixedClassSeq
    }); */

    store.dispatch(requestJoinMixedClassLive({
        liveSeq: mixedClassInfo.liveSeq,
        liveName: mixedClassInfo.liveName,
        userSeq,
        userNickname,
        mixedClassSeq: mixedClassInfo.mixedClassSeq
    }));
}

const onSuccessUnsubscribeForMixedClass = (msg) => {
    console.log("onSuccessUnsubscribeTempKeyForSmartTV => ", msg);
}
/********************************/

/** rbmq smart tv callback 처리 */
const onSuccessConnectForSmartTV = (msg) => {
    console.log("onSuccessConnectForSmartTV => ", msg);
    if (rbmqClient) {
        if (pingInterval === null) {
            pingInterval = setInterval(() => { sendPingToRbmq(); }, 25000);
        }

        let rbmqSubscribeOptions = {
            qos: 1,
            invocationContext: { nextKey: smartTVTempKey },
            onSuccess: onSuccessSubscribeForSmartTV,
            onFailure: onFailureSubscribe
        };

        if (myRoutingKey && myRoutingKey !== "") {
            rbmqClient.subscribe(myRoutingKey, rbmqSubscribeOptions);
        } else {
            console.log("onSuccessConnectForSmartTV() - myRoutingKey => ", myRoutingKey);
            window.alert("onSuccessConnectForSmartTV() - myRoutingKey => " + myRoutingKey);
        }

        let store = window.hiclasstv.store;
        store.dispatch(rbmqServerConnected());
    }
}

const onSuccessSubscribeForSmartTV = (msg) => {
    console.log("onSuccessSubscribeForSmartTV => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;
        if (nextKey && nextKey !== "") {
            let rbmqSubscribeOptions = {
                qos: 1,
                onSuccess: onSuccessSubscribeForSmartTV,
                onFailure: onFailureSubscribe
            };

            if (nextKey && nextKey !== "") {
                rbmqClient.subscribe(nextKey, rbmqSubscribeOptions);
            } else {
                console.log("onSuccessSubscribeForSmartTV(1) - nextKey => ", nextKey);
                window.alert("onSuccessSubscribeForSmartTV(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessSubscribeForSmartTV(2) - nextKey => ", nextKey);
            window.alert("onSuccessSubscribeForSmartTV(2) - nextKey => " + nextKey);
        }
    } else {
        console.log("onSuccessSubscribeForSmartTV - isSend => ", smartTVInfo.isSend);
        if (smartTVInfo.isSend === false) {
            smartTVInfo.isSend = true;
            /* let teacherSeq = smartTVInfo.teacherSeq;
            let userSeq = smartTVInfo.userSeq;
            let liveSeq = smartTVInfo.liveSeq;
            let userNickname = smartTVInfo.userNickname;
            console.log("onSuccessSubscribeForSmartTV - sendGetSmartTVBindInfo called");
            sendGetSmartTVBindInfo(teacherSeq, liveSeq, userSeq, userNickname, smartTVTempKey); */
            console.log("onSuccessSubscribeForSmartTV - sendGetSmartTVBindInfo called");
            sendGetSmartTVBindInfo();
        }
    }
}

const onFailureUnsubscribeTempKeyForSmartTV = (res) => {
    writeRbmqErrorMessage("onFailureUnsubscribeTempKeyForSmartTV => ", res);
}

const onSuccessUnsubscribeTempKeyForSmartTV = (msg) => {
    console.log("onSuccessUnsubscribeTempKeyForSmartTV => ", msg);
    try {
        if (rbmqClient) {
            let subscribeOptions = {
                qos: 1,
                invocationContext: { nextKey: smartTVInfo.bindKeyArr[1] },
                onSuccess: onSuccessSubscribeLiveForSmartTV,
                onFailure: onFailureSubscribeLiveForSmartTV
            };

            if (smartTVInfo.bindKeyArr[0] && smartTVInfo.bindKeyArr[0] !== "") {
                rbmqClient.subscribe(smartTVInfo.bindKeyArr[0], subscribeOptions);
            } else {
                console.log("onSuccessUnsubscribeTempKeyForSmartTV() - smartTVInfo.bindKeyArr[0] => ", smartTVInfo.bindKeyArr[0]);
                window.alert("onSuccessUnsubscribeTempKeyForSmartTV() - smartTVInfo.bindKeyArr[0] => " + smartTVInfo.bindKeyArr[0]);
            }
        }
    } catch (err) {
        console.log("onSuccessUnsubscribeTempKeyForSmartTV error => ", err);
    }
}

const onFailureSubscribeLiveForSmartTV = (res) => {
    writeRbmqErrorMessage("onFailureSubscribeLiveForSmartTV => ", res);
}

const onSuccessSubscribeLiveForSmartTV = (msg) => {
    console.log("onSuccessSubscribeLiveForSmartTV => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;

        if (nextKey && nextKey !== "") {
            let subscribeOptions = {
                qos: 1,
                onSuccess: onSuccessSubscribeLiveForSmartTV,
                onFailure: onFailureSubscribeLiveForSmartTV
            };

            if (nextKey && nextKey !== "") {
                rbmqClient.subscribe(nextKey, subscribeOptions);
            } else {
                console.log("onSuccessSubscribeLiveForSmartTV(1) - nextKey => ", nextKey);
                window.alert("onSuccessSubscribeLiveForSmartTV(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessSubscribeLiveForSmartTV(2) - nextKey => ", nextKey);
            window.alert("onSuccessSubscribeLiveForSmartTV(2) - nextKey => " + nextKey);
        }
    } else {
        sendJoinConsume("live", smartTVInfo.teacherSeq);
    }
}

const onFailureUnsubscribeLiveForSmartTV = (res) => {
    writeRbmqErrorMessage("onFailureUnsubscribeLiveForSmartTV => ", res);
}

const onSuccessUnsubscribeLiveForSmartTV = (msg) => {
    console.log("onSuccessUnsubscribeLiveForSmartTV => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;

        if (nextKey && nextKey !== "") {
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribeLiveForSmartTV,
                onFailure: onFailureUnsubscribeLiveForSmartTV
            }

            if (nextKey && nextKey !== "") {
                rbmqClient.unsubscribe(nextKey, unsubsribeOptions);
            } else {
                console.log("onSuccessUnsubscribeLiveForSmartTV(1) - nextKey => ", nextKey);
                window.alert("onSuccessUnsubscribeLiveForSmartTV(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessUnsubscribeLiveForSmartTV(2) - nextKey => ", nextKey);
            window.alert("onSuccessUnsubscribeLiveForSmartTV(2) - nextKey => " + nextKey);
        }
    } else {
        let unsubsribeOptions = {
            timeout: 3,
            onSuccess: onSuccessUnsubscribe,
            onFailure: onFailureUnsubscribe
        }

        if (myRoutingKey && myRoutingKey !== "") {
            rbmqClient.unsubscribe(myRoutingKey, unsubsribeOptions);
        } else if (myRoutingKeyArr && myRoutingKeyArr.length > 0) {
            let nextKey = null;

            if (myRoutingKeyArr.length > 1) {
                nextKey = myRoutingKeyArr[1];
                if (myRoutingKeyArr.length > 2) {
                    routingIdx = 2;
                } else {
                    // routingIdx = 4;
                    routingIdx = myRoutingKeyArr.length;
                }
            } else {
                nextKey = smartTVTempKey;
                // routingIdx = 4;
                routingIdx = myRoutingKeyArr.length;
            }

            console.log(`routingIdx[${routingIdx}], nextKey[${nextKey}]`);

            unsubsribeOptions.invocationContext = { nextKey };

            if (myRoutingKeyArr[0] && myRoutingKeyArr[0] !== "") {
                rbmqClient.unsubscribe(myRoutingKeyArr[0], unsubsribeOptions);
            } else {
                console.log("onSuccessUnsubscribeLiveForSmartTV() - myRoutingKeyArr[0] => ", myRoutingKeyArr[0]);
                window.alert("onSuccessUnsubscribeLiveForSmartTV() - myRoutingKeyArr[0] => " + myRoutingKeyArr[0]);
            }
        } else {
            console.log("onSuccessUnsubscribeLiveForSmartTV() - myRoutingKey => ", myRoutingKey);
            window.alert("onSuccessUnsubscribeLiveForSmartTV() - myRoutingKey => " + myRoutingKey);
        }
    }
}
/********************************/

/** rbmq group smart tv callback 처리 */
const onSuccessConnectForGroupSmartTV = (msg) => {
    console.log("onSuccessConnectForGroupSmartTV => ", msg);
    if (rbmqClient) {
        if (pingInterval === null) {
            pingInterval = setInterval(() => { sendPingToRbmq(); }, 25000);
        }

        let nextKey = null;

        console.log("myRoutingKeyArr => ", myRoutingKeyArr);

        if (myRoutingKeyArr.length > 1) {
            nextKey = myRoutingKeyArr[1];
            if (myRoutingKeyArr.length > 2) {
                routingIdx = 2;
            } else {
                // routingIdx = 4;
                routingIdx = myRoutingKeyArr.length;
            }
        } else {
            nextKey = smartTVTempKey;
            // routingIdx = 4;
            routingIdx = myRoutingKeyArr.length;
        }

        /* console.log("nextKey => ", nextKey);
        console.log("routingIdx => ", routingIdx); */

        console.log(`routingIdx[${routingIdx}], nextKey[${nextKey}]`);

        let rbmqSubscribeOptions = {
            qos: 1,
            invocationContext: { nextKey /* nextKey: smartTVTempKey */ },
            onSuccess: onSuccessSubscribeForGroupSmartTV,
            onFailure: onFailureSubscribe
        };

        // rbmqClient.subscribe(myRoutingKey, rbmqSubscribeOptions);
        if (myRoutingKeyArr[0] && myRoutingKeyArr[0] !== "") {
            rbmqClient.subscribe(myRoutingKeyArr[0], rbmqSubscribeOptions);
        } else {
            console.log("onSuccessConnectForGroupSmartTV() - myRoutingKeyArr[0] => ", myRoutingKeyArr[0]);
            window.alert("onSuccessConnectForGroupSmartTV() - myRoutingKeyArr[0] => " + myRoutingKeyArr[0]);
        }

        let store = window.hiclasstv.store;
        store.dispatch(rbmqServerConnected());
    }
}

const onSuccessConnectForGroupSmartTVWithWebSocket = (msg) => {
    console.log("onSuccessConnectForGroupSmartTVWithWebSocket => ", msg);
    if (rbmqClient) {
        if (pingInterval === null) {
            pingInterval = setInterval(() => { sendPingToRbmq(); }, 25000);
        }

        let rbmqSubscribeOptions = {
            qos: 1,
            onSuccess: onSuccessSubscribeForGroupSmartTV,
            onFailure: onFailureSubscribe
        };

        let bindKeyArr = myRoutingKeyArr;
        bindKeyArr.push(smartTVTempKey);
        console.log("bindKeyArr => ", bindKeyArr);

        if (bindKeyArr[0] && bindKeyArr[0] !== "") {
            rbmqClient.subscribeArr(bindKeyArr, rbmqSubscribeOptions);
        } else {
            console.log("onSuccessConnectForGroupSmartTVWithWebSocket() - bindKeyArr[0] => ", bindKeyArr[0]);
            window.alert("onSuccessConnectForGroupSmartTVWithWebSocket() - bindKeyArr[0] => " + bindKeyArr[0]);
        }

        let store = window.hiclasstv.store;
        store.dispatch(rbmqServerConnected());
    }
}

const onSuccessSubscribeForGroupSmartTV = (msg) => {
    console.log("onSuccessSubscribeForGroupSmartTV => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;
        if (nextKey && nextKey !== "") {
            let rbmqSubscribeOptions = {
                qos: 1,
                onSuccess: onSuccessSubscribeForGroupSmartTV,
                onFailure: onFailureSubscribe
            };

            console.log(`(1) beforeRoutingIdx[${routingIdx}], beforeNextKey[${nextKey}]`);
            let newNextKey = myRoutingKeyArr[routingIdx++];
            console.log(`(1) newRoutingIdx[${routingIdx}], newNextKey[${newNextKey}]`);

            if (newNextKey && newNextKey !== "") {
                rbmqSubscribeOptions.invocationContext = { nextKey: newNextKey };
            } else {
                console.log(`(1) nextKey[${nextKey}], smartTVTempKey[${smartTVTempKey}]`);
                if (nextKey === smartTVTempKey) {
                    console.log("(1) nextKey === smartTVTempKey ... newNextKey is undefined!");
                    // rbmqSubscribeOptions.invocationContext = { nextKey: smartTVTempKey };
                } else {
                    console.log("(1) nextKey !== smartTVTempKey ... newNextKey is smartTVTempKey!");
                    rbmqSubscribeOptions.invocationContext = { nextKey: smartTVTempKey };
                }
            }

            console.log("rbmqSubscribeOptions => ", rbmqSubscribeOptions);

            if (nextKey && nextKey !== "") {
                rbmqClient.subscribe(nextKey, rbmqSubscribeOptions);
            } else {
                console.log("onSuccessSubscribeForGroupSmartTV(1) - nextKey => ", nextKey);
                window.alert("onSuccessSubscribeForGroupSmartTV(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessSubscribeForGroupSmartTV(2) - nextKey => ", nextKey);
            window.alert("onSuccessSubscribeForGroupSmartTV(2) - nextKey => " + nextKey);
        }
    } else {
        console.log("onSuccessSubscribeForGroupSmartTV - isSend => ", smartTVInfo.isSend);
        if (smartTVInfo.isSend === false) {
            smartTVInfo.isSend = true;
            /* let teacherSeq = smartTVInfo.teacherSeq;
            let userSeq = smartTVInfo.userSeq;
            let liveSeq = smartTVInfo.liveSeq;
            let userNickname = smartTVInfo.userNickname;
            console.log("onSuccessSubscribeForGroupSmartTV - sendGetSmartTVBindInfo called");
            sendGetSmartTVBindInfo(teacherSeq, liveSeq, userSeq, userNickname, smartTVTempKey); */
            console.log("onSuccessSubscribeForSmartTV - sendGetSmartTVBindInfo called");
            sendGetSmartTVBindInfo();
        }
    }
}

const onFailureUnsubscribeTempKeyForGroupSmartTV = (res) => {
    writeRbmqErrorMessage("onFailureUnsubscribeTempKeyForGroupSmartTV => ", res);
}

const onSuccessUnsubscribeTempKeyForGroupSmartTV = (msg) => {
    console.log("onSuccessUnsubscribeTempKeyForGroupSmartTV => ", msg);
    try {
        if (rbmqClient) {
            let subscribeOptions = {
                qos: 1,
                invocationContext: { nextKey: smartTVInfo.bindKeyArr[1] },
                onSuccess: onSuccessSubscribeLiveForGroupSmartTV,
                onFailure: onFailureSubscribeLiveForGroupSmartTV
            };

            if (smartTVInfo.bindKeyArr[0] && smartTVInfo.bindKeyArr[0] !== "") {
                rbmqClient.subscribe(smartTVInfo.bindKeyArr[0], subscribeOptions);
            } else {
                console.log("onSuccessUnsubscribeTempKeyForGroupSmartTV() - smartTVInfo.bindKeyArr[0] => ", smartTVInfo.bindKeyArr[0]);
                window.alert("onSuccessUnsubscribeTempKeyForGroupSmartTV() - smartTVInfo.bindKeyArr[0] => " + smartTVInfo.bindKeyArr[0]);
            }
        }
    } catch (err) {
        console.log("onSuccessUnsubscribeTempKeyForGroupSmartTV error => ", err);
    }
}

const onFailureSubscribeLiveForGroupSmartTV = (res) => {
    writeRbmqErrorMessage("onFailureSubscribeLiveForGroupSmartTV => ", res);
}

const onSuccessSubscribeLiveForGroupSmartTV = (msg) => {
    console.log("onSuccessSubscribeLiveForGroupSmartTV => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;

        if (nextKey && nextKey !== "") {
            let subscribeOptions = {
                qos: 1,
                onSuccess: onSuccessSubscribeLiveForGroupSmartTV,
                onFailure: onFailureSubscribeLiveForGroupSmartTV
            };

            if (nextKey && nextKey !== "") {
                rbmqClient.subscribe(nextKey, subscribeOptions);
            } else {
                console.log("onSuccessSubscribeLiveForGroupSmartTV(1) - nextKey => ", nextKey);
                window.alert("onSuccessSubscribeLiveForGroupSmartTV(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessSubscribeLiveForGroupSmartTV(2) - nextKey => ", nextKey);
            window.alert("onSuccessSubscribeLiveForGroupSmartTV(2) - nextKey => " + nextKey);
        }
    } else {
        sendJoinConsume("live", smartTVInfo.teacherSeq);
    }
}

const onFailureUnsubscribeLiveForGroupSmartTV = (res) => {
    writeRbmqErrorMessage("onFailureUnsubscribeLiveForGroupSmartTV => ", res);
}

const onSuccessUnsubscribeLiveForGroupSmartTV = (msg) => {
    console.log("onSuccessUnsubscribeLiveForGroupSmartTV => ", msg);
    if (msg.invocationContext && msg.invocationContext.nextKey) {
        const { nextKey } = msg.invocationContext;

        if (nextKey && nextKey !== "") {
            let unsubsribeOptions = {
                timeout: 3,
                onSuccess: onSuccessUnsubscribeLiveForGroupSmartTV,
                onFailure: onFailureUnsubscribeLiveForGroupSmartTV
            }

            if (nextKey && nextKey !== "") {
                rbmqClient.unsubscribe(nextKey, unsubsribeOptions);
            } else {
                console.log("onSuccessUnsubscribeLiveForGroupSmartTV(1) - nextKey => ", nextKey);
                window.alert("onSuccessUnsubscribeLiveForGroupSmartTV(1) - nextKey => " + nextKey);
            }
        } else {
            console.log("onSuccessUnsubscribeLiveForGroupSmartTV(2) - nextKey => ", nextKey);
            window.alert("onSuccessUnsubscribeLiveForGroupSmartTV(2) - nextKey => " + nextKey);
        }
    } else {
        let unsubsribeOptions = {
            timeout: 3,
            onSuccess: onSuccessUnsubscribe,
            onFailure: onFailureUnsubscribe
        }

        if (myRoutingKey && myRoutingKey !== "") {
            rbmqClient.unsubscribe(myRoutingKey, unsubsribeOptions);
        } else {
            console.log("onSuccessUnsubscribeLiveForGroupSmartTV() - myRoutingKey => ", myRoutingKey);
            window.alert("onSuccessUnsubscribeLiveForGroupSmartTV() - myRoutingKey => " + myRoutingKey);
        }
    }
}
/********************************/

/** rbmq data receive 처리 */
const onMessageArrived = (msg) => {
    try {
        // console.log(msg);
        let regEx = new RegExp("/", "gi");
        let routingKey = msg.destinationName.replace(regEx, ".");
        if (myHandsUpSendKey === routingKey || mySmallGroupSendKey === routingKey || mySmallGroupImageSendKey === routingKey) return;
        // console.log("msg.destinationName => ", msg.destinationName);

        rawDataWithRoutingKey(msg.payloadBytes, routingKey);
    } catch (err) {
        console.log("onMessageArrived err => ", err);
    }
}

const writeRbmqErrorMessage = (type, msg) => {
    let store = window.hiclasstv.store;
    let str = `인터넷 연결에 문제가 발생하였습니다. 확인을 누를 경우 로그인 화면으로 이동합니다. [Code.RB${msg.errorCode}]`;
    let location = "";

    // console.log("pathname - ", window.location.pathname);
    // console.log("pathname.liveSeq - ", window.location.pathname.liveSeq);
    // console.log(window.location);

    if (currentPageType !== enWebPage.Home) {
        let pathname = window.location.pathname;
        let pathnameArr = pathname.split("/");
        let seq = "";

        if (pathnameArr.length > 0) {
            seq = pathnameArr[pathnameArr.length - 1];
        }

        if (Number(seq) > 0) {
            if (currentPageType === enWebPage.SmartTV) {
                location = `/smarttv_login/${seq}`;
            } else if (currentPageType === enWebPage.GroupSmartTV) {
                location = `/group_smarttv_login/${seq}`;
            } else if (currentPageType === enWebPage.LiveQRLogin) {
                location = `/main-qrLogin/${seq}`;
            } else if (currentPageType === enWebPage.MixedClassLogin) {
                const { live } = store.getState();
                const { mixedClassInfo } = live;
                const mixedClassSeq = mixedClassInfo.mixedClassSeq;
                location = `/mixed_login/${mixedClassSeq}`;
            }
        }
    } else {
        location = "/main";
    }

    switch (msg.errorCode) {
        case enRbmqError.OK:    // 정상적인 종료 case
            store.dispatch(rbmqServerDisconnected());
            location = "";
            break;

        case enRbmqError.CONNECT_TIMEOUT:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] CONNECT_TIMEOUT Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.SUBSCRIBE_TIMEOUT:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] SUBSCRIBE_TIMEOUT Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.UNSUBSCRIBE_TIMEOUT:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] UNSUBSCRIBE_TIMEOUT Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.PING_TIMEOUT:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] PING_TIMEOUT Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.INTERNAL_ERROR:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] INTERNAL_ERROR Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.CONNACK_RETURNCODE:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] CONNACK_RETURNCODE Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.SOCKET_ERROR:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] SOCKET_ERROR Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.SOCKET_CLOSE:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] SOCKET_CLOSE Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.MALFORMED_UTF:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] MALFORMED_UTF Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.UNSUPPORTED:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] UNSUPPORTED Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.INVALID_STATE:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] INVALID_STATE Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.INVALID_TYPE:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] INVALID_TYPE Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.INVALID_ARGUMENT:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] INVALID_ARGUMENT Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.UNSUPPORTED_OPERATION:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] UNSUPPORTED_OPERATION Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.INVALID_STORED_DATA:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] INVALID_STORED_DATA Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.INVALID_MQTT_MESSAGE_TYPE:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] INVALID_MQTT_MESSAGE_TYPE Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.MALFORMED_UNICODE:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] MALFORMED_UNICODE Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.TRANSPORT_CLOSE:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] TRANSPORT_CLOSE Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        case enRbmqError.TRANSPORT_ERROR:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] TRANSPORT_ERROR Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;

        default:
            store.dispatch(rbmqServerError({ message: msg.errorMessage }));
            console.log(str, `[${type}] default Exception => [${msg.errorMessage}]`);
            alert(str);
            // window.location = "/main";
            break;
    }

    if (location !== "") {
        console.log("location is exist. move to location.");
        window.location = location;
    }
}
/********************************/

/** rbmq ping send */
const sendPingToRbmq = () => {
    let message = null;
    if (ConstData.IS_LOCAL_VERSION) {
        message = new Message(myRoutingKey, Buffer.from(""));
    } else {
        if (rbmqClient !== undefined && rbmqClient !== null) {
            if (myRoutingKey !== undefined && myRoutingKey !== null && myRoutingKey !== "") {
                message = new window.Paho.MQTT.Message("");
                message.destinationName = myRoutingKey;
            }
        }
    }

    if (rbmqClient !== undefined && rbmqClient !== null) {
        if (message !== null) {
            rbmqClient.send(message);
        }
    }
}
/********************************/

/** rbmq data send 처리 */
const sendDataToRbmq = (routingKey, sendData) => {
    let message = null;
    if (ConstData.IS_LOCAL_VERSION) {
        message = new Message(routingKey, sendData)
    } else {
        if (rbmqClient !== undefined && rbmqClient !== null) {
            message = new window.Paho.MQTT.Message(sendData);
            message.destinationName = routingKey;
        }
    }

    if (rbmqClient !== undefined && rbmqClient !== null) {
        //console.log("sendDataToRbmq - routingKey[", routingKey, "], message => ", message);
        rbmqClient.send(message);
    }
}

export const sendCommandToRbmq = (routingKey, sendObj) => {
    //console.log("sendCommandToRbmq - routingKey[", routingKey, "], sendObj => ", sendObj);
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
        text: JSON.stringify(sendObj)
    }, "event_exchange");

    sendDataToRbmq(routingKey, sendData.forWeb);
}

export const sendObjectToRbmq = (sendObj) => {
    //console.log("sendObjectToRbmq - sendObj => ", sendObj);
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
        text: JSON.stringify(sendObj)
    }, "event_exchange");

    sendDataToRbmq(routingKey, sendData.forWeb);
}

export const sendPageGetArtifactsForAndroid = (action, tokenCmd) => {
    if (getIsAlivePenCamService()) {
        let store = window.hiclasstv.store;
        const { user, live } = store.getState();
        const { userNickname, userSeq } = user;
        const { performLiveInfo } = live;
        let performLiveSeq = performLiveInfo.liveSeq;

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind: "android", action, information: { tokenCmd, userSeq, liveSeq: performLiveSeq } })
        }, userNickname);

        if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
            sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
        }
    }
}

export const sendStartPentalkOnScreenForAndroid = (isForSmartPen, isReconnMode, isP2PMode, isHandsUpInit, configFPSKind) => {
    console.log("sendStartPentalkOnScreenForAndroid called");
    console.log(`isForSmartPen[${isForSmartPen}], isReconnMode[${isReconnMode}], isP2PMode[${isP2PMode}], isHandsUpInit[${isHandsUpInit}], configFPSKind[${configFPSKind}]`);
    if (getIsAlivePenCamService()) {
        if (isForSmartPen) {
            console.log("case 1 - ct_FinishLive");
            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL);
        } else {
            if (isP2PMode) {
                console.log("case 2-1 - ct_StartP2P");
                xmitCmd_AndroidMessage("ct_StartP2P", enTokenCMD.NULL);
            } else {
                console.log("case 2-2 - ct_StartScreenCapture");
                xmitCmd_AndroidMessage("ct_StartScreenCapture", enTokenCMD.NULL);
            }

            if (isHandsUpInit) {
                console.log("case 2-3 - xmitCmd_AndroidTouchScreenLock");
                xmitCmd_AndroidTouchScreenLock();
            }
        }
    } else {
        if (rbmqInfo !== undefined && rbmqInfo !== null) {
            if (isForSmartPen) {
                console.log("case 3-1 - connectPenCamService(isRunForSmartPen is true)");
                isRunForSmartPen = true;
                isClickConnAppBtn = true;
            } else {
                console.log("case 3-2 - connectPenCamService(isRunForSmartPen is false)");
                isRunForSmartPen = false;
                isClickConnAppBtn = false;
            }

            let store = window.hiclasstv.store;
            const { live } = store.getState();
            const { performLiveInfo, isMixedClassMember } = live;
            let performLiveSeq = performLiveInfo.liveSeq;
            let performLiveTeacherSeq = performLiveInfo.teacher_info === undefined || performLiveInfo.teacher_info === null ? 
                                        -1 : performLiveInfo.teacher_info.userSeq;
            let performLiveTeacherRoomSeq = performLiveInfo.teacher_info === undefined || performLiveInfo.teacher_info === null ? 
                                            -1 : performLiveInfo.teacher_info.teamUpSeq === undefined || performLiveInfo.teacher_info.teamUpSeq === null ? 
                                            -1 : performLiveInfo.teacher_info.teamUpSeq;

            const connection_info = {
                userSeq: myUserSeq,
                teacherSeq: performLiveTeacherSeq,
                liveSeq: performLiveSeq,
                chatRoomSeq: performLiveTeacherRoomSeq,
                host: rbmqInfo.wsIp,
                port: rbmqInfo.port,    // 20250321 ...  rbmqPort 바뀐 버전인지 판단하는 값 ... by hjkim
                isP2PMode,
                isMixedClassMember,
                isGuestMember,
                configFPSKind,
                isMediaSoupVersion,
                isSupportedWebRtcAudio: isMediaSoupVersion,     // 20250320 ... mediasoup 지원 버전 여부에 따라 값 바뀌도록 수정 ... by hjkim
                isSupportedAccessibilityService: false,         // 20250402 ... false 임시 for real commit 용 ... by hjkim
                // isSupportedAccessibilityService: true,
                userGateWay: isMediaSoupVersion ? routingKey : null
            };

            if (isMixedClassMember) {
                const { mixedClassInfo } = live;

                connection_info.localTeacherSeq = mixedClassInfo.localTeacherSeq;
                connection_info.localLiveSeq = mixedClassInfo.localLiveSeq;
            }

            // connectPenCamService(myUserSeq, performLiveTeacherSeq, performLiveSeq, performLiveTeacherRoomSeq, rbmqInfo.wsIp, isMixedClassMember, isReconnMode, isP2PMode);
            connectPenCamService(connection_info);
        } else {
            console.log("case 4 - rbmqInfo => ", rbmqInfo);
        }
    }
}

export const sendGetSmartTVBindInfo = () => {
    let teacherSeq = smartTVInfo.teacherSeq;
    let userSeq = smartTVInfo.userSeq;
    let liveSeq = smartTVInfo.liveSeq;
    let userNickname = smartTVInfo.userNickname;

    // console.log("sendGetSmartTVBindInfo - ", teacherSeq, liveSeq, userSeq, userNickname, smartTVTempKey);
    console.log("sendGetSmartTVBindInfo - teacherSeq[", teacherSeq, "], liveSeq[", liveSeq, "], userSeq[",
        userSeq, "], userNickname[", userNickname, "], smartTVTempKey[", smartTVTempKey, "]");

    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
        text: JSON.stringify({
            kind: "handsup",
            action: "getSmartTVBindKey",
            information: {
                userSeq,
                liveSeq,
                tempBindKey: smartTVTempKey,
                list_member: smartTVInfo.list_member
            }
        })
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    if (sendKey) {
        console.log("sendDataToRbmq called - sendKey => ", sendKey);
        sendDataToRbmq(sendKey, sendData.forWeb);
    }
}

/* export const sendGetSmartTVBindInfo = (teacherSeq, liveSeq, userSeq, userNickname, smartTVTempKey) => {
    console.log("sendGetSmartTVBindInfo - ", teacherSeq, liveSeq, userSeq, userNickname, smartTVTempKey);
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
        text: JSON.stringify({
            kind: "handsup",
            action: "getSmartTVBindKey",
            information: {
                userSeq,
                liveSeq,
                tempBindKey: smartTVTempKey,
                list_member: smartTVInfo.list_member
            }
        })
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    if (sendKey) {
        console.log("sendDataToRbmq called - sendKey => ", sendKey);
        sendDataToRbmq(sendKey, sendData.forWeb);
    }
} */

export const sendUpdateHandsUpImage = (liveSeq, userSeq, imgData) => {
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;

    let reader = new FileReader();
    let array;
    reader.readAsArrayBuffer(imgData);
    reader.onload = () => {
        array = reader.result;

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_UpdateHandsUpImage,
            CLASSID: 0,
            PENID: 0,
            INT1: liveSeq,
            INT2: userSeq,
            data: array
        }, userNickname);

        if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
            sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
        }

        if (mySmallGroupImageSendKey && mySmallGroupImageSendKey !== "") {
            sendDataToRbmq(mySmallGroupImageSendKey, sendData.forWeb);
        }
    };
}

const sendPageJpeg = (cmd, userSeq, imgData) => {
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;

    const sendData = getSendToMQBytes({
        tokenCmd: cmd,
        data: imgData,
        CLASSID: 0,
        PENID: userSeq
    }, userNickname);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

const sendScreenImage = (userSeq, imgData) => {
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;

    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.page_PutArtifact,
        data: imgData,
        CLASSID: 0,
        PENID: userSeq
    }, userNickname);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

export const sendRequestMixedClassLogin = (userSeq) => {
    if (myLocalServerBindKey && myLocalServerBindKey !== "" && localServerSendData && localServerSendData !== "") {
        localServerSendData.localStudentUserSeq = userSeq;
        let userAgent = navigator.userAgent.toLowerCase();
        let isAndroid = userAgent.indexOf("android") > -1;
        localServerSendData.isAndroidDevice = isAndroid;
        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ 
                kind: "local", 
                action: "login", 
                information: localServerSendData 
            })
        }, "event_exchange");

        sendDataToRbmq(myLocalServerBindKey, sendData.forWeb);
    }
}

export const sendRequestMixedClassLogout = (userSeq) => {
    if (myLocalServerBindKey && myLocalServerBindKey !== "" && localServerSendData && localServerSendData !== "") {
        localServerSendData.localStudentUserSeq = userSeq;
        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind: "local", action: "logout", information: localServerSendData })
        }, "event_exchange");

        sendDataToRbmq(myLocalServerBindKey, sendData.forWeb);
    }
}

export const xmitCmd_QuizAnswer = (kind, action, actionData) => {
    const { data, userSeq, userNickname, profileImgUrl } = actionData;

    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
        text: JSON.stringify({ kind, action, information: { data, userSeq, iconSeq: profileImgUrl } })
    }, userNickname);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

export const xmitCmd_AndroidSmallGroupInfo = (kind, action, groupSendKey) => {
    //console.log("xmitCmd_AndroidSmallGroupInfo - ", groupSendKey);
    if (getIsAlivePenCamService()) {
        let store = window.hiclasstv.store;
        const { user, live } = store.getState();
        const { userNickname, userSeq } = user;
        const { performLiveInfo } = live;
        let performLiveSeq = performLiveInfo.liveSeq;

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind, action, information: { tokenCmd: enTokenCMD.NULL, userSeq, liveSeq: performLiveSeq, groupSendKey } })
        }, userNickname);

        console.log("xmitCmd_AndroidSmallGroupInfo - myPenCamServiceKey => ", myPenCamServiceKey);

        if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
            sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
        }
    }
}

export const xmitCmd_AndroidStatusMessage = (action, tokenCmd, status) => {
    if (getIsAlivePenCamService()) {
        let store = window.hiclasstv.store;
        const { user, live } = store.getState();
        const { userNickname, userSeq } = user;
        const { performLiveInfo } = live;
        let performLiveSeq = performLiveInfo.liveSeq;

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind: "android", action, information: { tokenCmd, userSeq, liveSeq: performLiveSeq, status } })
        }, userNickname);

        console.log("xmitCmd_AndroidStatusMessage - myPenCamServiceKey => ", myPenCamServiceKey);

        if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
            sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
        }
    }
}

export const xmitCmd_AndroidMessage = (action, tokenCmd, user_info) => {
    if (getIsAlivePenCamService()) {
        let store = window.hiclasstv.store;
        const { user, live } = store.getState();
        const { userNickname, userSeq } = user;
        const { performLiveInfo } = live;
        let performLiveSeq = performLiveInfo.liveSeq;

        console.log("xmitCmd_AndroidMessage - action[", action, "], tokenCmd[", tokenCmd, "], userSeq[", userSeq, "], userNickname[", userNickname, "], liveSeq[", "]");

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ 
                kind: "android", 
                action, 
                information: { 
                    tokenCmd, 
                    userSeq: user_info ? user_info.userSeq : userSeq, 
                    liveSeq: performLiveSeq 
                } 
            })
        }, user_info ? user_info.userNickname : userNickname);

        console.log("xmitCmd_AndroidMessage - myPenCamServiceKey => ", myPenCamServiceKey);

        if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
            sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
        }
    }
}

export const xmitCmd_AndroidJsonMessage = (action, jsonData) => {
    if (getIsAlivePenCamService()) {
        let store = window.hiclasstv.store;
        const { user, live } = store.getState();
        const { userNickname, userSeq } = user;
        const { performLiveInfo } = live;
        let performLiveSeq = performLiveInfo.liveSeq;

        let information = {
            ...jsonData,
            userSeq,
            liveSeq: performLiveSeq
        };

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind: "android", action, information })
        }, userNickname);

        console.log("xmitCmd_AndroidMessage - myPenCamServiceKey => ", myPenCamServiceKey);

        if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
            sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
        }
    }
}

export const xmitCmd_AndroidMessageForSmartTV = (kind, action, tokenCmd, userSeq, userNickname, liveSeq) => {
    console.log("xmitCmd_AndroidMessageForSmartTV - ", kind, action, tokenCmd, userSeq, userNickname, liveSeq);
    if (userSeq > -1) {
        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind, action, information: { tokenCmd, userSeq, liveSeq, smartTVTempKey } })
        }, userNickname);

        console.log("xmitCmd_AndroidMessageForSmartTV - myPenCamServiceKey => ", myPenCamServiceKey);
    
        if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
            sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
        }
    
        if (action === "ct_SmartTVLogout") {
            setIsAlivePenCamService(false);
        }
    } else {
        console.log(`xmitCmd_AndroidMessageForSmartTV - userSeq is ${userSeq}. don't send message`);
    }
}

export const xmitCmd_AndroidMessageForGroupSmartTV = (kind, action, tokenCmd, list_member, liveSeq) => {
    console.log("xmitCmd_AndroidMessageForGroupSmartTV - ", kind, action, tokenCmd, list_member, liveSeq);

    for (let i = 0; i < list_member.length; i++) {
        let user_info = list_member[i];
        // let userSeq = list_member[i].userSeq;
        if (user_info.userSeq > -1) {
            const sendData = getSendToMQBytes({
                tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
                text: JSON.stringify({ kind, action, information: { tokenCmd, userSeq: user_info.userSeq, liveSeq, smartTVTempKey } })
            }, user_info.userNickname);
    
            if (myPenCamServiceKeyArr && myPenCamServiceKeyArr.length > i) {
                let myPenCamServiceKey = myPenCamServiceKeyArr[i];
                console.log(`i[${i}] - myPenCamServiceKey[${myPenCamServiceKey}]`);
                sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
    
                if (action === "ct_SmartTVLogout") {
                    setIsAlivePenCamService(false);
                }
            }
        } else {
            console.log(`xmitCmd_AndroidMessageForGroupSmartTV - userSeq is ${user_info.userSeq}. don't send message`);
        }
    }
}

export const xmitCmd_MessageForSmartTV = (kind, action, tokenCmd, userSeq, userNickname, liveSeq, mode, localStreamingMode) => {
    console.log("xmitCmd_MessageForSmartTV - ", kind, action, tokenCmd, userSeq, userNickname, liveSeq, mode, localStreamingMode);
    if (userSeq > -1) {
        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
            text: JSON.stringify({ kind, action, information: { tokenCmd, userSeq, liveSeq, mode, localStreamingMode, smartTVTempKey } })
        }, userNickname);
    
        let sendKey;
    
        if (kind === "android") {
            sendKey = getDefaultDirectKey(userSeq, 3);
        } else {
            sendKey = getDefaultDirectKey(userSeq, 0);
        }

        console.log("xmitCmd_MessageForSmartTV - sendKey => ", sendKey);
    
        sendDataToRbmq(sendKey, sendData.forWeb);
    
        /* if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
            sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
        } else {
            let myPenCamServiceKey = myPenCamServiceKeyArr[idx];
            if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
                sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
            }
        } */
    } else {
        console.log(`xmitCmd_MessageForSmartTV - userSeq is ${userSeq}. don't send message`);
    }
}

export const xmitCmd_AndroidInitDone = () => {
    if (getIsHandsUpStarted()) { // handsup이 시작했을 경우에만 앱 연동시 화면 캡쳐를 시작하도록 한다... by hjkim 20221025
        xmitCmd_AndroidMessage("ct_LiveOn", enTokenCMD.NULL); // test용 임시
        xmitCmd_AndroidMessage("ct_StartHandsUp", enTokenCMD.NULL); // test용 임시
        sendMicStatus();
        androidHandsUpInitDone();

        xmitCmd_AndroidTouchScreenLock(); // test용 임시
    } else {
        console.log("xmitCmd_AndroidInitDone - handsup is not started ... ");
    }
}

export const xmitCmd_AndroidTouchScreenLock = () => {
    if (getIsAlivePenCamService()) {
        let store = window.hiclasstv.store;
        const { live } = store.getState();
        const { isTouchScreenLocked } = live;
        if (isTouchScreenLocked) {
            if (getIsAndroidDevice()) {
                const { user } = store.getState();
                const { userNickname, userSeq } = user;
                const { performLiveInfo } = live;
                let performLiveSeq = performLiveInfo.liveSeq;
    
                const sendData = getSendToMQBytes({
                    tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
                    text: JSON.stringify({ kind: "android", action: "ct_TouchScreenLock", information: { tokenCmd: enTokenCMD.NULL, userSeq, liveSeq: performLiveSeq } })
                }, userNickname);
    
                if (myPenCamServiceKey !== undefined && myPenCamServiceKey !== null) {
                    sendDataToRbmq(myPenCamServiceKey, sendData.forWeb);
                }
            } else { // ios case 는 없다고 가정 for PC
                let touchLockGuard = document.getElementById("touchLockGuard");
                if (touchLockGuard) {
                    touchLockGuard.style.display = "flex";
                    touchLockGuard.style.visibility = "visible";
                    touchLockGuard.style.zIndex = 51;
                }
            }
        }
    }
}

export const getScreenImgStream = (cmd, stream) => {
    let videoEl = document.createElement("video");
    videoEl.width = ConstData.SCREEN_RATE.WIDTH;
    videoEl.height = ConstData.SCREEN_RATE.HEIGHT;
    videoEl.controls = true;
    videoEl.autoplay = true;
    videoEl.className = "fill_cover";
    videoEl.srcObject = stream;

    setTimeout((videoEl) => {
        let imgCanvasEl = document.createElement("canvas");
        imgCanvasEl.width = ConstData.SCREEN_RATE.WIDTH;
        imgCanvasEl.height = ConstData.SCREEN_RATE.HEIGHT;
        let imgContextEl = imgCanvasEl.getContext("2d");

        imgContextEl.drawImage(videoEl, 0, 0, imgCanvasEl.width, imgCanvasEl.height);

        imgCanvasEl.toBlob((blob) => {
            convertArrayBuffer(cmd, blob);
        }, "image/jpeg"/*, 1.0*/);
    }, 500, videoEl);
}

export const convertArrayBuffer = (cmd, blob) => {
    let reader = new FileReader();
    let array;
    reader.readAsArrayBuffer(blob);
    reader.onload = () => {
        array = reader.result;

        if (cmd === enTokenCMD.page_PutArtifact) {
            sendScreenImage(myUserSeq, array);
        } else {
            sendPageJpeg(cmd, myUserSeq, array);
        }
    };
}

export const sendHandsUpOn = (teacherSeq, liveSeq, userSeq, senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1: ConstCode.HANDS_UP_ON
    }, senderName);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendHandsUpOff = (teacherSeq, liveSeq, userSeq, senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1: ConstCode.HANDS_UP_OFF
    }, senderName);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendHandsUpInitDone = (liveSeq, userSeq) => {
    let teacherSeq = rcvUserSeq;
    let store = window.hiclasstv.store;
    store.dispatch(changeHandsUpStatusInfo({ kind: "connect" }));

    const { user, live } = store.getState();
    const { userNickname } = user;
    const { isMixedClassMember } = live;
    let BYTE1 = isMixedClassMember ? ConstCode.HANDS_UP_INIT_DONE_MIXED : ConstCode.HANDS_UP_INIT_DONE;
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendP2PSoundOn = (liveSeq, userSeq) => {
    let teacherSeq = rcvUserSeq;
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1: ConstCode.MIC_ON
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendP2PSoundOff = (liveSeq, userSeq) => {
    let teacherSeq = rcvUserSeq;
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1: ConstCode.MIC_OFF
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendP2PSoundReadyLocal = (liveSeq, userSeq) => {
    let teacherSeq = rcvUserSeq;
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1: ConstCode.LOCAL_MIC_READY
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendMicVolumeLevel = (liveSeq, userSeq, soundLevel) => {
    let teacherSeq = rcvUserSeq;
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;

    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1: soundLevel,
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendUserLoginStatus = (userSeq, liveSeq, userStatus) => {
    console.log("sendUserLoginStatus - ", "liveSeq => ", liveSeq, ", userSeq => ", userSeq, ", userStatus => ", userStatus);
    console.log("teacherSeq => ", rcvUserSeq);

    if (rcvUserSeq === undefined || rcvUserSeq === null || rcvUserSeq < 0) return;

    let teacherSeq = rcvUserSeq;
    let store = window.hiclasstv.store;
    const { user } = store.getState();
    const { userNickname } = user;
    console.log("userNickname => ", userNickname);
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_UserLoginInfo,
        CLASSID: 0,
        PENID: userSeq,
        INT1: liveSeq,
        INT2: userSeq,
        BYTE1: userStatus
    }, userNickname);

    let sendKey = getDefaultDirectKey(teacherSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

/* export const sendNotifyMixedClassMember = (liveSeq, userSeq) => {
    let teacherSeq = rcvUserSeq;
    let store = window.hiclasstv.store;
    const { live } = store.getState();
    const { isMixedClassMember } = live;
    if (isMixedClassMember) {
        const { user } = store.getState();
        const { userNickname } = user;

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.ct_UserLoginInfo,
            CLASSID: 0,
            PENID: userSeq,
            INT1: liveSeq,
            INT2: userSeq,
            BYTE1: ConstCode.NOTIFY_MIXED_CLASS_MEMBER
        }, userNickname);
    
        let sendKey = getDefaultDirectKey(teacherSeq, 1);
        sendDataToRbmq(sendKey, sendData.forWeb);
    }
} */

export const sendChatMsg = (mode, textValue, emoMIDno, senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.chat_Text,
        emoGIDno: 0,   /* 0번 고정 */
        emoMIDno: emoMIDno,
        text: textValue
    }, senderName);

    if (mode === "whisper") {
        if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
            sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
        }
    } else {
        if (mySmallGroupSendKey && mySmallGroupSendKey !== "") {
            sendDataToRbmq(mySmallGroupSendKey, sendData.forWeb);
        } else {
            if (myHandsUpSendKey !== undefined && myHandsUpSendKey !== null) {
                sendDataToRbmq(myHandsUpSendKey, sendData.forWeb);
            }
        }
    }
}

export const sendChatInkMemo = (mode, imgData, userSeq, senderName) => {
    let reader = new FileReader();
    let array;
    reader.readAsArrayBuffer(imgData);
    reader.onload = () => {
        array = reader.result;

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.chat_Memo,
            CLASSID: 0,
            PENID: userSeq,
            data: array
        }, senderName);

        if (mode === "whisper") {
            if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
                sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
            }
        } else {            
            if (mySmallGroupSendKey && mySmallGroupSendKey !== "") {
                sendDataToRbmq(mySmallGroupSendKey, sendData.forWeb);
            } else {
                if (myHandsUpSendKey !== undefined && myHandsUpSendKey !== null) {
                    sendDataToRbmq(myHandsUpSendKey, sendData.forWeb);
                }
            }
        }
    };
}

export const sendChatVoiceMemo = (mode, voiceData, userSeq, senderName) => {
    let reader = new FileReader();
    let array;
    reader.readAsArrayBuffer(voiceData);
    reader.onload = () => {
        array = reader.result;

        const sendData = getSendToMQBytes({
            tokenCmd: enTokenCMD.chat_Voice,
            CLASSID: 0,
            PENID: userSeq,
            data: array
        }, senderName);

        if (mode === "whisper") {
            if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
                sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
            }
        } else {      
            if (mySmallGroupSendKey && mySmallGroupSendKey !== "") {
                sendDataToRbmq(mySmallGroupSendKey, sendData.forWeb);
            } else {
                if (myHandsUpSendKey !== undefined && myHandsUpSendKey !== null) {
                    sendDataToRbmq(myHandsUpSendKey, sendData.forWeb);
                }
            }
        }
    };
}

export const sendChangeInkMode = (mode, senderName, userSeq) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_Mode,
        byte1: mode,
        CLASSID: 0,
        PENID: userSeq
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

export const sendChangeInkColor = (color, senderName, userSeq) => {
    /** javascript와 c#의 rgba값 차이로 인해 값을 꼭 변환해서 보내야함 by hjkim 200704 */
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_Color,
        byte1: color.R,
        byte2: color.G,
        byte3: color.B,
        CLASSID: 0,
        PENID: userSeq
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

export const sendChangeInkTipSize = (width, senderName, userSeq) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_Tip,
        double1: width / 2,
        double2: width / 2,
        CLASSID: 0,
        PENID: userSeq
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

export const sendClearInkStroke = (senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_Clear,
        CLASSID: 0,
        PENID: 0
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }

    if (mySmallGroupSendKey && mySmallGroupSendKey !== "") {
        sendDataToRbmq(mySmallGroupSendKey, sendData.forWeb);
    }
}

export const sendEraseStroke = (spoint, epoint, senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_Erase1,
        CLASSID: 0,
        PENID: 0,
        SPT: spoint,
        EPT: epoint
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }

    if (mySmallGroupSendKey && mySmallGroupSendKey !== "") {
        sendDataToRbmq(mySmallGroupSendKey, sendData.forWeb);
    }
}

export const sendInkStroke = (stroke, senderName, userSeq) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_SingleStroke,
        stroke: stroke,
        mode: stroke.mode,
        CLASSID: 0,
        PENID: userSeq
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }

    if (mySmallGroupSendKey && mySmallGroupSendKey !== "") {
        sendDataToRbmq(mySmallGroupSendKey, sendData.forWeb);
    }
}

export const sendInkStrokeDown = (point, senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_SDn,
        CLASSID: 0,
        PENID: 0,
        SPT: point,
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

export const sendInkStrokeMove = (point, senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_SM,
        CLASSID: 0,
        PENID: 0,
        SPT: point,
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}

export const sendInkStrokeUp = (point, senderName) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.Ink_SUp,
        CLASSID: 0,
        PENID: 0,
        SPT: point,
    }, senderName);

    if (myTeacherDirectKey !== undefined && myTeacherDirectKey !== null) {
        sendDataToRbmq(myTeacherDirectKey, sendData.forWeb);
    }
}
/********************************/

/** p2p 정보 보내기용 */
export const sendCandidate = (candidate, liveSeq, userSeq) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
        text: JSON.stringify({ kind: "handsup", action: "candidate", information: { candidate, liveSeq, userSeq } })
    }, "handsup_signaling");

    let sendKey = getDefaultDirectKey(rcvUserSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}

export const sendDescription = (description, liveSeq, userSeq, isp2pCreate) => {
    const sendData = getSendToMQBytes({
        tokenCmd: enTokenCMD.ct_SendToWebJSonInfo,
        text: JSON.stringify({ kind: "handsup", action: "description", information: { sdp: description, liveSeq, userSeq, isp2pCreate } })
    }, "handsup_signaling");

    let sendKey = getDefaultDirectKey(rcvUserSeq, 1);
    sendDataToRbmq(sendKey, sendData.forWeb);
}
/********************************/

/** android app connect */
// const connectPenCamService = (userSeq, teacherSeq, liveSeq, chatRoomSeq, host, isMixedClassMember, isReconnMode, isP2PMode) => {
// const connectPenCamService = ({ userSeq, teacherSeq, localTeacherSeq, liveSeq, localLiveSeq, chatRoomSeq, host, isP2PMode, isMixedClassMember, configFPSKind, isSupportedAccessibilityService }) => {
const connectPenCamService = (info) => {
    /**
     * intent:
     *      HOST/URI-path // Optional host
     *      #Intent;
     *          package=\[string\];
     *          action=\[string\];
     *          category=\[string\];
     *          component=\[string\];
     *          scheme=\[string\];
     *      end;
     * 
     * //pentalkcapture.service-web/websocket
     * intent:
     *      #Intent;
     *          package=com.easy.pentalkcapture;
     *          scheme=easy-web;
     *      end;
     */

    console.log("connectPenCamService - ", info);

    if (!getIsAlivePenCamService()) {
        // console.log("connectPenCamService-case1");
        let url = `intent://pentalkcapture.service?`;
        url += `userSeq=${info.userSeq}`;
        url += `&teacherSeq=${info.teacherSeq}&liveSeq=${info.liveSeq}`;
        url += `&chatRoomSeq=${info.chatRoomSeq}`;
        url += `&rbmqHost=${info.host}&rbmqPort=${info.port}`; // 20250321 ... rbmqPort(5673) 바뀐 버전인지 판단하는 값 ... by hjkim
        url += `&isP2PMode=${info.isP2PMode}`;
        url += `&isMixedClassMember=${info.isMixedClassMember}`;
        if (info.isMixedClassMember) {
            url += `&localTeacherSeq=${info.localTeacherSeq}&localLiveSeq=${info.localLiveSeq}`;
        }
        url += `&isGuestMember=${info.isGuestMember}`; // 20241128 ... 앱에서 게스트 멤버인지 아닌지를 판단하는 값 ... by hjkim (왜 전달하는 부분이 빠졌지..?ㅁ? 다시 추가..)
        url += `&configFPSKind=${info.configFPSKind}`;
        url += `&isMediaSoupVersion=${info.isMediaSoupVersion}`; // 20250320 ... mediasoup 지원 버전인지 체크하는 값 ... by hjkim
        url += `&isSupportedWebRtcAudio=${info.isSupportedWebRtcAudio}`; // 20241128 ... 앱에서 WebRtc 지원해야하는 버전인지 판단하는 값. 마이크 권한 확인 다이얼로그를 띄우기 위해 전달 ... by hjkim
        url += `&isSupportedAccessibilityService=${info.isSupportedAccessibilityService}`; // 20241118 ... 접근성 서비스 지원해야하는 버전인지 판단하는 값 ... by hjkim
        // url += `&userGateWay=${info.userGateWay}`;  // 20250320 ... mediasoup 서버 게이트 웨이 값 ... by hjkim
        url += `#Intent;scheme=easy;action=android.intent.action.VIEW;`;
        url += `category=android.intent.category.BROWSABLE;`;
        url += `package=com.easy.pentalkcapture;end`;
        /* let link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.click(); */
        window.location.href = url;
    } else {
        // console.log("connectPenCamService-case2");
        // alert("Already Connected PenCamService");
        // 이미 Pentalk OnScreen 앱과 연결되어 있습니다.
        // It is already connected to the Pentalk OnScreen app.
        console.log("Already Connected to the Pentalk OnScreen app.");
        let str = "이미 Pentalk OnScreen 앱과 연결되어 있습니다.";
        alert(str);
    }
}
/********************************/

/** etc */
export const alertErrMsg = (err) => {
    alert(err);
}