/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { FaAndroid, FaAssistiveListeningSystems, FaRegLightbulb, FaFileArrowDown } from "react-icons/fa";
import { FaFileCircleCheck, FaFileCircleXmark } from "react-icons/fa6";
import { HiFolderArrowDown } from "react-icons/hi2";
import { BsPeople } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import html2canvas from "html2canvas";

import ConstData from "../../lib/ConstData";
import { ctActionType, ctWebActionType } from "../../lib/ConstCommand";
import { enTokenCMD } from "../../lib/classTalk/CodeEnum";
import { sendStartPentalkOnScreenForAndroid, xmitCmd_AndroidMessage } from "../../lib/RabbitmqClient";

const isAndroidDevice = navigator.userAgent.toLowerCase().indexOf("android") > -1 ? true : false;

const FunctionList = ({ activeLive, isWhisper, isChatting, isFileDownloadingNow, isPanelWindowOpened, isTeamUpOpened, isHandsupOpened, isQuizWindowOpened, isActiveLive, handsUpYN, myHandsUpYN, connectStatus, selectedLanguageCode, isConnectedOnScreenApp, handleSelectedClassTalkFunc, onHandleClassTalkFunc, togglePanelWindow }) => {
    const { t } = useTranslation();

    const [functionTitles, setFunctionTitles] = useState([]);
    const [conColor, setConColor] = useState("grey");
    const [isConnectedApp, setIsConnectedApp] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    /* const imageProcess = (status) => {
        if (status === "connected") {
            setIsConnectedApp(true);
            setIsDisabled(false);
        } else if (status === "disconnected") {
            setIsConnectedApp(false);
            setIsDisabled(false);
        }
    } */

    useEffect(() => {
        if (isAndroidDevice) {
            // initFuncProc(imageProcess);
            if (isConnectedOnScreenApp) setIsConnectedApp(true);
        }

        if (isWhisper) {
            setFunctionTitles(["INK_MEMO", "VOICE_MEMO", "FILE_SHARE"]);
        } else {
            if (isChatting) {
                if (ConstData.IS_LOCAL_VERSION) {
                    // 귓속말 ver ==== 
                    setFunctionTitles(["PANEL", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN", "QUIZ", "FILE_DOWN", "FILE_CLEAR"]);
                    // setFunctionTitles(["INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN", "FILE_DOWN", "FILE_CLEAR"]);
                } else {
                    // 귓속말 ver ==== 
                    setFunctionTitles(["PANEL", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN", "QUIZ", /* "FILE_DOWN", "FILE_CLEAR",  */]);
                    // setFunctionTitles(["INK_MEMO", "VOICE_MEMO", "FILE_SHARE", /*"PUSH_SCREEN,"*/ "PULL_SCREEN" */]);
                }
            } else {
                if (isAndroidDevice) {
                    if (ConstData.IS_LOCAL_VERSION) {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", "MEDIA_SOUP_CON", "SAVE_CANVAS", "HANDS_UP", "ANDROID_CON"]);
                    } else {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", "MEDIA_SOUP_CON", "SAVE_CANVAS", "HANDS_UP", "ANDROID_CON"]);
                    }
                } else {
                    if (ConstData.IS_LOCAL_VERSION) {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", "MEDIA_SOUP_CON", "SAVE_CANVAS", "HANDS_UP"]);
                    } else {
                        setFunctionTitles(["WHISPER", "INK_MEMO", "VOICE_MEMO", "FILE_SHARE", "MEDIA_SOUP_CON", "SAVE_CANVAS", "HANDS_UP"]);
                    }
                }
            }
        }
    }, []);

    useEffect(() => {
        if (connectStatus === "disable") {
            setConColor("grey");
        } else if (connectStatus === "on") {
            setConColor("rgb(108, 185, 54)");
        } else if (connectStatus === "off") {
            setConColor("red");
        }
    }, [connectStatus]);

    useEffect(() => {
        setIsConnectedApp(isConnectedOnScreenApp);
        setIsDisabled(false);
    }, [isConnectedOnScreenApp]);

    const handlePreventClick = (e) => {
        e.stopPropagation();
    }

    const handleChangeFile = (e) => {
        if (e.target.files !== undefined && e.target.files !== null) {
            if (e.target.files[0] !== undefined && e.target.files[0] !== null) {
                if (e.target.files[0].name !== undefined && e.target.files[0].name !== null) {
                    let filename = e.target.files[0].name;
                    onHandleClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: isChatting ? ctActionType.FileShare : ctWebActionType.FileShare, data: e.target.files[0], filename });
                } else {
                    console.log("handleChangeFile 1 : cancel was pressed");
                }
            } else {
                console.log("handleChangeFile 2 : cancel was pressed");
            }
        } else {
            console.log("handleChangeFile 3 : cancel was pressed");
        }
    }

    const getIconImgUrl = (name) => {
        return ConstData.ICON_IMG_DIR + name + ".png";
    }

    const connectBtProc = () => {
        // console.log("------connectBtProc------2", connectStatus);

        if (connectStatus === "off") {
            handleSelectedClassTalkFunc({ selectedFunc: "LIVE_RECON" });
        } else if (connectStatus === "on") {
            // console.log("connectBtProc-222");
            // unsubscribe(false, false);
        }
    }

    const saveInkCanvas = () => {
        let canvasArea = document.getElementById("canvas-area");
        html2canvas(canvasArea)
        .then((canvasEl) => {
            // Create a link element
            const link = document.createElement("a");
            link.download = "captured-image.jpg"; // Filename for the downloaded image
            link.href = canvasEl.toDataURL("image/jpg"); // Convert canvas to Data URL
            link.click(); // Programmatically trigger the download
        })
    }

    const androidConnectBtProc = () => {
        setIsDisabled(true);

        // 기존 코드---------여기부터 call android app for smart pen connect & call disconnect android app
        /* if (isConnectedApp) {
            sendStartPentalkOnScreenForAndroid(true, false, false, false);
        } else {
            sendStartPentalkOnScreenForAndroid(handsUpYN !== "Y" ? true : false, false, false, false);
        } */
        // 기존 코드---------여기까지 ... by hjkim 20221020

        // 기존 코드---------여기부터 call android app for smart pen connect & call disconnect android app --- 20250108
        // 수정 코드 ... by hjkim 20221020 (not support smart pen connect)
        /* if (isConnectedApp) {
            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL);
        } else {
            sendStartPentalkOnScreenForAndroid(handsUpYN !== "Y" ? true : false, false, false, false);
        } */
        // 기존 코드---------여기까지 ... by hjkim 20250108

        // 수정 코드 20250108 ... for connect android app & disconnect android app ... by hjkim 
        if (isConnectedApp) {
            xmitCmd_AndroidMessage("ct_FinishLive", enTokenCMD.NULL);
        } else {
            sendStartPentalkOnScreenForAndroid(true, false, false, false);
        }
    }

    return (
        <div className="d-flex justifty-content-between flex-wrap w-100 px-2 py-1" style={{ backgroundColor: 'rgba(246, 248, 250)', minHeight: 'max-content' }} onClick={handlePreventClick}>
            {
                isChatting ?
                    functionTitles !== undefined && functionTitles !== null && functionTitles.map((title, index) => (
                        <div className="d-flex my-2 align-items-center justify-content-center area-25" key={index}>
                            {
                                title === "PANEL" ?
                                    <div className="d-flex flex-column align-items-center">
                                        {
                                            isActiveLive ?
                                                <button className={"hiclasstv-classtalk-menu" + (isPanelWindowOpened ? " select" : "")} style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={togglePanelWindow}>
                                                    <BsPeople className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                                </button> :
                                                <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(215, 215, 215)' }} disabled>
                                                    <BsPeople className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                                </button>
                                        }
                                        <span className="hiclasstv-classtalk-text mt-1">{t("대상자_패널", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "INK_MEMO" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(28, 162, 209)' }} onClick={() => onHandleClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.InkMemo })}>
                                            <img src={getIconImgUrl("inkMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt={t("잉크메모", { ns: "classTalk" })} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("잉크메모", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "VOICE_MEMO" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(28, 162, 209)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.VoiceMemo })}>
                                            <img src={getIconImgUrl("voiceMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt={t("음성메모", { ns: "classTalk" })} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("음성메모", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "FILE_SHARE" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" type="file" style={{ backgroundColor: 'rgb(52, 182, 157)' }} onClick={handleChangeFile}>
                                            <label className="button m-0 p-0" htmlFor="ex_file">
                                                <img src={getIconImgUrl("fileShare")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt={t("파일공유", { ns: "classTalk" })} />
                                            </label>
                                            <input className="p-0" type="file" id="ex_file" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }} onChange={handleChangeFile} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("파일공유", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "PULL_SCREEN" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.PullScreen })}>
                                            <img src={getIconImgUrl("pullScreen")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt={t("당겨오기", { ns: "classTalk" })} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("당겨오기", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "QUIZ" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className={"hiclasstv-classtalk-menu" + (isQuizWindowOpened ? " select" : "")} style={{ backgroundColor: 'rgb(62, 188, 210)' }} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.Quiz })}>
                                            <FaRegLightbulb className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("퀴즈", { ns: "classTalk" })}</span>
                                    </div> :
                                ConstData.IS_LOCAL_VERSION && activeLive && activeLive.liveSeq > -1 && title === "FILE_DOWN" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(52, 182, 157)' }} disabled={isFileDownloadingNow ? true : false} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctActionType.FileDownload })}>
                                            <FaFileCircleCheck className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("공유파일다운", { ns: "classTalk" })}</span>
                                    </div> :
                                ConstData.IS_LOCAL_VERSION && activeLive && activeLive.liveSeq > -1 && title === "FILE_CLEAR" ?
                                    <div className="d-flex flex-column align-items-center">
                                        <button className="hiclasstv-classtalk-menu" style={{ backgroundColor: 'rgb(52, 182, 157)' }} disabled={isFileDownloadingNow ? true : false} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "TOGGLE_FILE_DIR_REMOVE"/* selectedFunc: "FUNC_SELECT", func: ctActionType.FileClear */ })}>
                                            <FaFileCircleXmark className="hiclasstv-classtalk-icon" style={{ padding: '.25rem' }} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("공유파일삭제", { ns: "classTalk" })}</span>
                                    </div> :
                                <></>
                            }
                        </div>
                    )) :
                    functionTitles !== undefined && functionTitles !== null && functionTitles.map((title, index) => (
                        <div className="d-flex my-2 align-items-center justify-content-center" style={{ width: '20%' }} key={index}>
                            {
                                title === "WHISPER" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={t("귓속말", { ns: "classTalk" })}>
                                        <button className="hiclasstv-classtalk-menu hiclasstv-grey" /* style={{ backgroundColor: 'rgb(215, 215, 215)' }} */ onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.Whisper })}>
                                            <FaAssistiveListeningSystems className="hiclasstv-classtalk-icon" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text">{t("귓속말", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "INK_MEMO" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={t("잉크메모", { ns: "classTalk" })}>
                                        <button className="hiclasstv-classtalk-menu hiclasstv-cornflowerblue" /* style={{ backgroundColor: 'rgb(28, 162, 209)' }} */ onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.InkMemo })}>
                                            <img src={getIconImgUrl("inkMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("잉크메모", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "VOICE_MEMO" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={t("음성메모", { ns: "classTalk" })}>
                                        <button className="hiclasstv-classtalk-menu hiclasstv-dodgerblue" /* style={{ backgroundColor: 'rgb(28, 162, 209)' }} */ onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "FUNC_SELECT", func: ctWebActionType.VoiceMemo })}>
                                            <img src={getIconImgUrl("voiceMemo")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("음성메모", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "FILE_SHARE" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={t("파일공유", { ns: "classTalk" })}>
                                        <button className="hiclasstv-classtalk-menu hiclasstv-mediumseagreen" type="file" /* style={{ backgroundColor: 'rgb(52, 182, 157)' }} */ onClick={handleChangeFile}>
                                            <label className="button m-0 p-0" htmlFor="ex_file">
                                                <img src={getIconImgUrl("fileShare")} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                            </label>
                                            <input className="p-0" type="file" id="ex_file" style={{ position: 'absolute', width: '1px', height: '1px', margin: '-1px', overflow: 'hidden', border: '0', clip: 'rect(0,0,0,0)' }} onChange={handleChangeFile} />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("파일공유", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "MEDIA_SOUP_CON" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" 
                                        title={connectStatus === "disable" || connectStatus === "off" ? t("화면_연결", { ns: "classTalk" }) : t("연결_끊기", { ns: "classTalk" })}
                                    >
                                        <button className={"hiclasstv-classtalk-menu" + (connectStatus === "disable" ? " hiclasstv-grey" : connectStatus === "off" ? " hiclasstv-red" : " hiclasstv-yellowgreen")} /* style={{ backgroundColor: `${conColor}` }} */ disabled={connectStatus === "disable"} onClick={connectBtProc}>
                                            {
                                                /* connectStatus === "disable" ?
                                                    <img src={ConstData.ICON_IMG_DIR + "disconnectDisable.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" data-toggle="tooltip" title={t("화면_연결", { ns: "classTalk" })} alt={t("화면_연결", { ns: "classTalk" })} /> :
                                                    connectStatus === "off" ?
                                                        <img src={ConstData.ICON_IMG_DIR + "connectDisable.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" data-toggle="tooltip" title={t("화면_연결", { ns: "classTalk" })} alt={t("화면_연결", { ns: "classTalk" })} /> :
                                                        <img src={ConstData.ICON_IMG_DIR + "connectDisable.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" data-toggle="tooltip" title={t("연결_끊기", { ns: "classTalk" })} alt={t("연결_끊기", { ns: "classTalk" })} /> */
                                            }
                                            <img className="hiclasstv-classtalk-icon" aria-hidden="true" /* data-toggle="tooltip" */
                                                src={connectStatus === "disable" ? ConstData.ICON_IMG_DIR + "disconnectDisable.png" : ConstData.ICON_IMG_DIR + "connectDisable.png"}
                                                alt={connectStatus === "disable" || connectStatus === "off" ? t("화면_연결", { ns: "classTalk" }) : t("연결_끊기", { ns: "classTalk" })}
                                            />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("화면연결", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "SAVE_CANVAS" ? 
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={t("화면_저장", { ns: "classTalk" })}>
                                        <button className={"hiclasstv-classtalk-menu" + (handsUpYN === "N" ? " hiclasstv-grey" : " hiclasstv-blue")} disabled={handsUpYN === "N"} onClick={saveInkCanvas}>
                                            <HiFolderArrowDown className="hiclasstv-classtalk-icon" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("화면_저장", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "HANDS_UP" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={t("저요저요", { ns: "classTalk" })}>
                                        <button className={"hiclasstv-classtalk-menu" + (handsUpYN === "N" ? " hiclasstv-grey" : myHandsUpYN === "Y" ? " menu-color-auto" : " hiclasstv-blue")} disabled={handsUpYN === "N"} onClick={() => handleSelectedClassTalkFunc({ selectedFunc: "HANDS_UP" })}>
                                            <img src={ConstData.ICON_IMG_DIR + "handsup_me.png"} aria-hidden="true" className="hiclasstv-classtalk-icon" alt="" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{t("저요저요", { ns: "classTalk" })}</span>
                                    </div> :
                                title === "ANDROID_CON" ?
                                    <div className="d-flex flex-column align-items-center" data-toggle="tooltip" title={isConnectedApp ? t("앱연동해제", { ns: "classTalk" }) : t("앱연동", { ns: "classTalk" })}>
                                        <button className={"hiclasstv-classtalk-menu" + (isConnectedApp ? " hiclasstv-blue" : " red")} disabled={isDisabled} onClick={androidConnectBtProc}>
                                            <FaAndroid className="hiclasstv-classtalk-icon" />
                                        </button>
                                        <span className="hiclasstv-classtalk-text mt-1">{isConnectedApp ? t("앱연동해제", { ns: "classTalk" }) : t("앱연동", { ns: "classTalk" })}</span>
                                    </div> :
                                    <></>
                            }
                        </div>
                    ))
            }
        </div>
        /* <div className="d-flex justify-content-between flex-wrap w-100 m-0" style={{ height: 'max-content', padding: '10px 25px', lineHeight: '1rem', backgroundColor: 'rgba(246, 248, 250)' }} onClick={handlePreventClick}>
            {getImageList()}
        </div> */
    );
}

export default FunctionList;